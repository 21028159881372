import "./ActualPages.css";
import { Link } from "react-router-dom";
import React from "react";



function PageNotFound(props) {

  return (
    <div className="blog">
        <Link className="link" to="/" >
                <p className="backButton"> back to home </p>
        </Link>
      <p className="headerText"> page not found! </p>
    </div>
  );
}

export default PageNotFound;

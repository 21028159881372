import "./CareerVisualizerElement.css";
import careerList from '../constants/careerList.js';
import React from "react";


function CareerVisualizerElement(props) {

    const careerElement = careerList[props.activeCareerElement]
    function changeActiveElement (increment) {
        if (increment === -1 && props.activeCareerElement !== 0) {
            props.setActiveCareerElement(props.activeCareerElement-1)
        } else if (increment === 1 && props.activeCareerElement < careerList.length-1) {
            props.setActiveCareerElement(props.activeCareerElement+1)
        }
    }

  return (
    <div className="careerVisualizationElement">
        <div className="careerVizHeader">
            <p className="careerVisTitle"> {careerElement.name} </p>
            <div className="careerVisRow">
                <p className="careerVisTitle careerVisRowText" onClick={() => changeActiveElement(-1)}> {'<'} </p>
                <p className="careerVisTitle careerVisRowText" onClick={() => props.setActiveCareerElement(null)}> x </p>
                <p className="careerVisTitle careerVisRowText" onClick={() => changeActiveElement(1)}> {'>'} </p>
            </div>
        </div>
        <p className="careerDurationText"> {careerElement.duration} </p>
        <p className="careerDurationText"> {careerElement.paragraph1} </p>
        <p className="careerDurationText"> {careerElement.paragraph2} </p>
        <p className="careerDurationText"> {careerElement.paragraph3} </p>
        <div className="careerTagsRow">
            {careerElement.tags.map((tag, index) => (
                <div className={"careerTagBox careerTagBox" + tag}> </div>
            ))}
        </div>
    </div>
  );
}

export default CareerVisualizerElement;

import React from "react";
import './Projects.css'
import ProjectSlider from '../assets/components/ProjectSlider.js'
import Biography from '../assets/components/Biography.js'
import projectList from '../assets/projectList.js'
import highlightsByte from '../assets/images/highlights/highlightsByte.png'
import highlightsOof from '../assets/images/highlights/highlightsOof.png'
import highlightsLeft from '../assets/images/highlights/highlightsLeft.png'
import highlightsRight from '../assets/images/highlights/highlightsRight.png'
import { Link } from "react-router-dom";

const Projects = (props) => {
  return (
    <div>
      <div className={props.isMobile ? "myWorkMobileDiv" : "myWorkDiv"}>
        <h1 className={props.isMobile ? "myWorkMobile" : "myWork"}> MY WORK </h1>
        <img className={props.isMobile ? "highlightsImageMobile firstImageMobile" : "highlightsImage firstImage"} src={highlightsLeft} alt="highlights with a colon and the start of square brakcet"/>
        <a className="highlightLink" href="https://www.byte-smarterkitchen.com/" target="_blank" rel="noreferrer">
        <img className={props.isMobile ? "highlightsImageMobile" : "highlightsImageTaller"} src={highlightsByte} alt="dark grey beveled text reading byte, clicking will take you to the byte website "/>
        </a>
        <a className="highlightLink" href="https://www.oneoffourteen.com/landing" target="_blank" rel="noreferrer">
        <img className={props.isMobile ? "highlightsImageMobile oofImageMobile" : "highlightsImageTaller oofImage"} src={highlightsOof} alt="white text with elegent letters reading eleve, clicking will take you to the eleve home page"/>
        </a>
        <img className={props.isMobile ? "highlightsImageMobile" : "highlightsImage"} src={highlightsRight} alt="the end of a square brakcet"/>
      </div>
      <Biography />
      <div className="buttonRow">
        <Link className="link" to="/blog" >
          <p className="projectButton"> MY BLOG </p> 
        </Link>
        <Link className="link" to="/career" >
          <p className="projectButton">CAREER VISUALIZATON </p> 
        </Link>
      </div>
      <div className="buttonRow">
      <p className="projectHeaderText">SOME OF MY PROJECTS</p> 
      </div>
      <ProjectSlider isMobile={props.isMobile} projectList = {projectList}/>
    </div>
    )
}

export default Projects;
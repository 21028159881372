const careerList = [
  {
    shortName: "cornell",
    index: 0,
    name: "Cornell University, Class of 2018",
    duration: "august 2014-may 2018",
    paragraph1: "My experience at Cornell laid the foundation for my future work, teaching me collaboration, creative problem solving, and perseverance. I studied in the College of Engineering, eventually choosing Operations Research and Information Engineering as my major. Initially, I thought I would focus soley on computer science, but I wanted to explore entrepreneurship, optimization, and data science as well. The more flexible ORIE degree allowed me to explore my full range of interests, so it was the perfect major for me.",
    paragraph2: "For the first time in my life, I truly struggled with coursework, after breezing through high school with easy A+'s. Sitting down to study for four or five hours at a time felt nearly impossible - partly due to my then-undiagnosed adhd. Eventually, I discovered unconventional study methods that worked for me, many of which I still use today and have incorporated into my future work, particularly one of fourteen.",
    paragraph3: "",
    tags: ["eng", "entrep"],
    links: []
  },
  {
    shortName: "goldman",
    index: 1,
    name: "Technology Analyst at GS",
    duration: "may 2018-february 2020",
    paragraph1: "After interning at Goldman Sachs over the summer of 2017, I knew I wanted to return full-time. It was my dream first job - working alongside intelligent colleagues in a beautiful nyc office, tackling complex problems.",
    paragraph2: "During my year and a half at the firm, I had the opportunity to meet incredible people, including some of the smartest developers I've ever worked with. My role was in the asset management space, where I worked developing applications and algorithms for the portfolio managers on our floor.",
    paragraph3: "While I enjoyed my work at GS, I was never particularly drawn to asset management or investing. I loved solving challenging problems under pressure, but I wanted to focus on topics that I felt genuinely passionate about. In late 2019, I came up with the idea for Dated, and in February 2020 I took the leap to pursue it full-time.",
    tags: ["eng"],
    links: []
  },
  {
    shortName: "graphicDesign",
    index: 2,
    name: "Began Studying Graphic Design",
    duration: "may 2018-current",
    paragraph1: "Since undergrad, I've known that my ultimate goal was to start my own company, maybe even multiple companies. I'm a big believer in bootstrapping, and I recognized that graphic design represents a major expense for new businesses, from logo creation to marketing materials to website design and more.",
    paragraph2: "In addition to saving thousands of dollars in the early stages of new companies, I knew that handling my own graphic design would give me full creative control and ensure that my vision was accurately presented to consumers. With this in mind, I dedicated a signficant amount of my free time to learning graphic design through free online resources, primarily Youtube.",
    paragraph3: "Everything clicked for me when I got my first iPad and started working in Procreate and Adobe Illustrator - my skills and creativity skyrocketed. I connected with digital illustration in a way I never had with traditional mediums. Even now, I'm constantly learning new techniques and programs, and I feel like I improve as a graphic artist every single day.",
    tags: ["design"],
    links: ["my graphic design growth"]
  },
  {
    shortName: "appDevelopment",
    index: 3,
    name: "Began Studying App Development",
    duration: "may 2018-current",
    paragraph1: "Similarly to graphic design, I knew app development would be a crucial skill for my entrepreneurial dreams. I am fascinated by the relationship we have with our cell phones, and I personally believe we can improve this relationship through better, more intentional app usage. One of my core goals is to create and develop apps that help work towards a healthier and more collaborative future, so I needed to learn app development.",
    paragraph2: "About 5% of my work at Goldman was in Javascript using React architecture, so I had a bit of baseline React knowledge which translated well into learning React Native, which I learned through online resources and simple side projects.",
    paragraph3: "My first app - Dated, was created using Expo and React Native. Currently, I develop my apps exclusively with React, using CapacitorJS to package for ios/android.",
    tags: ["eng"],
    links: []
  },
  {
    shortName: "cerise",
    index: 4,
    name: "Founded Cerise Technology",
    duration: "march 2020-current",
    paragraph1: "When I started working on Dated full time, my first priority was to build and roll out our mobile app, as this is how users would interact with the product. Investors want to see a live minimum viable product with active users before writing checks, so I had a few options to build a functioning mvp that people would actually want to use. ",
    paragraph2: "I could have outsource the app development, spending maybe 10-15k of my own funds to work with an external development company. I also could have spent a month or two writing the app myself. But I chose a unique 3rd option, creating my own app development company - Cerise Technology - to essentially still write the app myself, but with a team of developers who could collaborate to build a reusable framework to partner with other app development startups to quickly build mvps.",
    paragraph3: "The foundation of Cerise is technology for a better future. Our current focuses are applications that improve users relationship with screen time and social medias.",
    tags: ["eng", "entrep"],
    links: []
  },
  {
    shortName: "dated",
    index: 5,
    name: "Co-founded Dated",
    duration: "march 2020-january 2021",
    paragraph1: "description coming soon!",
    paragraph2: "",
    paragraph3: "",
    // paragraph1: "My first big start up! blelksadjfsldkf, online dating is a toxic space",
    // paragraph2: "ASFslfdjsalkjfk slkfjlakdf lkfjdsklfj flasjlksdjfsf",
    // paragraph3: "In November, our primary investor lead us down an early acquisition path. Our userbase and algorithms lasdjflkasdjfklsadfjklafjkalfjklsdfj asldkjfljkfsd lakjsd",
    tags: ["eng", "entrep", "design"],
    links: []
  },
  {
    shortName: "productManagement",
    index: 6,
    name: "Began Studying Product Dev",
    duration: "march 2020-current",
    paragraph1: "description coming soon!",
    paragraph2: "",
    paragraph3: "",
    // paragraph1: "When I launched Dated, I started as a solo founder with asdfsadf. This meant that I was lead developer, HR, lead accountant, lasjflksjfklda, malksfjj. But most importantly, I had to shape the vision of the brand. How would users interact with our product, how does our product fit into everyday life, sdfsdaf. Or, the essentials of product management",
    // paragraph2: "Learning slkfajlkdfjalksdsldkjf lsdkjflksfj lasfj lsk jls las",
    // paragraph3: "Starting in 2022, I began to let some of the principals of product mnanagement seep into my daily life. The concept of standups actually makes a lot of sense to do in your personal life. ",
    tags: ["entrep"],
    links: []
  },
  {
    shortName: "bloomberg",
    index: 7,
    name: "Product Manager at Bloomberg",
    duration: "january 2021-july 2021",
    paragraph1: "description coming soon!",
    paragraph2: "",
    paragraph3: "",
    // paragraph1: "After Dated's early acquisition, I was feeling a bit lost in regards to what I wanted to do next. I loved my short entrepreneurship experience, lskdjflkasdfjdflkj and I had a lot to learn, particularly on the product management side, as I felt very confident in my software development skills.",
    // paragraph2: "saffda. Joined x team at Bloomberg to help manage digital data products. ",
    // paragraph3: "Unfortunately, I had to cut my time at Bloomberg short when my health took a large turn for the worse. lskdjflafjlksafjaklfjsklafjlkfjd, and I needed to spend less than 1 hour a day aslkdjflasdjfklsjf, which was completely impractical for a technical product manager.",
    tags: ["eng", "entrep"],
    links: []
  },
  {
    shortName: "goodseed",
    index: 8,
    name: "Co-founded goodseed protein",
    duration: "august 2021-current",
    paragraph1: "description coming soon!",
    paragraph2: "",
    paragraph3: "",
    // paragraph1: "When I stklasjdlfkjalkfjsdalkfjdlkfjalkfjsalkfjlksjfda lkjsajkl dslkjfsdjlk dslakjjfsd",
    // paragraph2: "I decided to get my nutrition certification in ealkjflksja lkasfjslkdf dslakfjsdflk , which was disappointingly easy to get (see link below!). ",
    // paragraph3: "Goodseed started as pure hemp seed protein powder, lasjflskjfds alskdfjsdlkf asldjfsladkf lskajdflsdjf. You can read more on the science behind seed based protein powders on the goodseed website!",
    tags: ["design", "entrep"],
    links: []
  },
  {
    shortName: "emblematic",
    index: 9,
    name: "Co-founded Emblematic",
    duration: "august 2021-current",
    paragraph1: "description coming soon!",
    paragraph2: "",
    paragraph3: "",
    // paragraph1: "ASDflksdjfjlksfjks lksdfjlksdfj klsajf ksldjfksld klsdfalkd saldjj sldfjjk jaskld skladfj slakj. IRL pinterest boards, ",
    // paragraph2: "The idea salkdjfsdfjklsadf laskjdf lksa. I really enjoy doing my own graphic design and shaping my own brands, but not every business owner feels this way. Many dread having to design marketing materials, or don't spend any time on their brand visuals.",
    // paragraph3: "The vision of Emblematic Creative Studios is to ",
    tags: ["design"],
    links: []
  },
  {
    shortName: "noodles",
    index: 10,
    name: "Co-founded Instant Noodle Factory",
    duration: "march 2023-current",
    paragraph1: "description coming soon!",
    paragraph2: "",
    paragraph3: "",
    // paragraph1: "When my partner Tat lskjfklsadfjkldsafjlkdfjklfa",
    // paragraph2: "My favorite elements of the project were designing the restaurant spaces and creating custom elements to make the INF experience unique.",
    // paragraph3: "We decided to close our stores in early 2025, as we ",
    tags: ["eng", "entrep", "design"],
    links: []
  },
];

export default careerList;

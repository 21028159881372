import React from "react";

function PrivacyFooter(props) {

  return (
    <div>
        <p style={{textAlign: 'center', fontFamily: 'CierraText', fontSize: '2vw', width: '32vw', marginBottom: '2vw', marginLeft: '34vw', color: '#efefef', backgroundColor: '#00000070', paddingTop: '2vw', paddingBottom: '1vw', borderRadius: '1vw'}}> © 2025 Cierra Beck</p>
    </div>
  );
}

export default PrivacyFooter;

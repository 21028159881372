import "./CareerVisualizerBubbles.css";
import React, { useState } from "react";
import CareerVisualizerElement from '../../assets/components/CareerVisualizerElement.js'

function CareerVisualizerBubbles() {
  const [activeCareerElement, setActiveCareerElement] = useState(null);

  return (
    <div className="body">
      {activeCareerElement !== null && (
        <CareerVisualizerElement setActiveCareerElement={setActiveCareerElement} activeCareerElement={activeCareerElement}/>
      )}
      {activeCareerElement === null && (
        <div className="careerSVG">
          <svg
            id="a"
            data-name="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1949.494 1785.852"
          >
            <g id="b" data-name="white_outlines">
              <rect
                x="1656.126"
                y="266.462"
                width="261.493"
                height="343.355"
                style={{
                  fill: "none",
                  stroke: "#fff",
                  strokeMiterlimit: "10",
                  strokeWidth: "4px",
                }}
              />
              <rect
                x="362.818"
                y="265.19"
                width="986.136"
                height="343.355"
                style={{
                  fill: "none",
                  stroke: "#fff",
                  strokeMiterlimit: "10",
                  strokeWidth: "4px",
                }}
              />
              <rect
                x="1371.902"
                y="266.496"
                width="260.792"
                height="343.355"
                style={{
                  fill: "none",
                  stroke: "#fff",
                  strokeMiterlimit: "10",
                  strokeWidth: "4px",
                }}
              />
              <rect
                x="1657.756"
                y="634.302"
                width="261.493"
                height="389.986"
                style={{
                  fill: "none",
                  stroke: "#fff",
                  strokeMiterlimit: "10",
                  strokeWidth: "4px",
                }}
              />
              <rect
                x="849.797"
                y="634.136"
                width="785.085"
                height="172.817"
                style={{
                  fill: "none",
                  stroke: "#fff",
                  strokeMiterlimit: "10",
                  strokeWidth: "4px",
                }}
              />
              <rect
                x="361.593"
                y="632.464"
                width="461.948"
                height="1150.272"
                style={{
                  fill: "none",
                  stroke: "#fff",
                  strokeMiterlimit: "10",
                  strokeWidth: "4px",
                }}
              />
              <rect
                x="361.606"
                y="2"
                width="1555.46"
                height="241.07"
                style={{
                  fill: "none",
                  stroke: "#fff",
                  strokeMiterlimit: "10",
                  strokeWidth: "4px",
                }}
              />
              <rect
                x="849.328"
                y="825.166"
                width="785.085"
                height="197.804"
                style={{
                  fill: "none",
                  stroke: "#fff",
                  strokeMiterlimit: "10",
                  strokeWidth: "4px",
                }}
              />
              <rect
                x="848.059"
                y="1047.159"
                width="350.436"
                height="735.54"
                style={{
                  fill: "none",
                  stroke: "#fff",
                  strokeMiterlimit: "10",
                  strokeWidth: "4px",
                }}
              />
              <rect
                x="1241"
                y="1049.885"
                width="677.389"
                height="152.522"
                style={{
                  fill: "none",
                  stroke: "#fff",
                  strokeMiterlimit: "10",
                  strokeWidth: "4px",
                }}
              />
              <rect
                x="1239.989"
                y="1242.969"
                width="681.531"
                height="540.883"
                style={{
                  fill: "none",
                  stroke: "#fff",
                  strokeMiterlimit: "10",
                  strokeWidth: "4px",
                }}
              />
            </g>
            <g id="c" data-name="timeline">
              <g id="d" data-name="dashes">
                <line
                  id="e"
                  data-name="line"
                  x1="1935.103"
                  y1="147.153"
                  x2="330.201"
                  y2="147.153"
                  style={{
                    fill: "none",
                    opacity: ".5",
                    stroke: "#fff",
                    strokeDasharray: "12",
                    strokeMiterlimit: "10",
                    strokeWidth: "5px",
                  }}
                />
                <line
                  id="f"
                  data-name="line"
                  x1="1933.127"
                  y1="366.975"
                  x2="328.225"
                  y2="366.975"
                  style={{
                    fill: "none",
                    opacity: ".5",
                    stroke: "#fff",
                    strokeDasharray: "12",
                    strokeMiterlimit: "10",
                    strokeWidth: "5px",
                  }}
                />
                <line
                  id="g"
                  data-name="line"
                  x1="1942.051"
                  y1="586.425"
                  x2="337.149"
                  y2="586.425"
                  style={{
                    fill: "none",
                    opacity: ".5",
                    stroke: "#fff",
                    strokeDasharray: "12",
                    strokeMiterlimit: "10",
                    strokeWidth: "5px",
                  }}
                />
                <line
                  id="h"
                  data-name="line"
                  x1="1940.076"
                  y1="806.247"
                  x2="335.174"
                  y2="806.247"
                  style={{
                    fill: "none",
                    opacity: ".5",
                    stroke: "#fff",
                    strokeDasharray: "12",
                    strokeMiterlimit: "10",
                    strokeWidth: "5px",
                  }}
                />
                <line
                  id="i"
                  data-name="line"
                  x1="1942.545"
                  y1="1021.542"
                  x2="337.643"
                  y2="1021.542"
                  style={{
                    fill: "none",
                    opacity: ".5",
                    stroke: "#fff",
                    strokeDasharray: "12",
                    strokeMiterlimit: "10",
                    strokeWidth: "5px",
                  }}
                />
                <line
                  id="j"
                  data-name="line"
                  x1="1940.57"
                  y1="1241.364"
                  x2="335.668"
                  y2="1241.364"
                  style={{
                    fill: "none",
                    opacity: ".5",
                    stroke: "#fff",
                    strokeDasharray: "12",
                    strokeMiterlimit: "10",
                    strokeWidth: "5px",
                  }}
                />
                <line
                  id="k"
                  data-name="line"
                  x1="1949.494"
                  y1="1460.815"
                  x2="344.592"
                  y2="1460.815"
                  style={{
                    fill: "none",
                    opacity: ".5",
                    stroke: "#fff",
                    strokeDasharray: "12",
                    strokeMiterlimit: "10",
                    strokeWidth: "5px",
                  }}
                />
                <line
                  id="l"
                  data-name="line"
                  x1="1947.518"
                  y1="1680.637"
                  x2="342.616"
                  y2="1680.637"
                  style={{
                    fill: "none",
                    opacity: ".5",
                    stroke: "#fff",
                    strokeDasharray: "12",
                    strokeMiterlimit: "10",
                    strokeWidth: "5px",
                  }}
                />
              </g>
              <line
                id="m"
                data-name="line"
                x1="166.708"
                y1="1617.673"
                x2="166.708"
                y2="216.053"
                style={{
                  fill: "none",
                  stroke: "#fff",
                  strokeMiterlimit: "10",
                  strokeWidth: "10px",
                }}
              />
              <g id="n" data-name="boxes">
                <rect
                  x="3"
                  y="85.517"
                  width="317.528"
                  height="132.141"
                  style={{
                    stroke: "#efefef",
                    strokeMiterlimit: "10",
                    strokeWidth: "6px",
                  }}
                />
                <rect
                  x="3.683"
                  y="302.434"
                  width="317.528"
                  height="132.141"
                  style={{
                    stroke: "#efefef",
                    strokeMiterlimit: "10",
                    strokeWidth: "6px",
                  }}
                />
                <rect
                  x="5.847"
                  y="523.489"
                  width="317.528"
                  height="132.141"
                  style={{
                    stroke: "#efefef",
                    strokeMiterlimit: "10",
                    strokeWidth: "6px",
                  }}
                />
                <rect
                  x="6.53"
                  y="740.405"
                  width="317.528"
                  height="132.141"
                  style={{
                    stroke: "#efefef",
                    strokeMiterlimit: "10",
                    strokeWidth: "6px",
                  }}
                />
                <rect
                  x="5.314"
                  y="958.813"
                  width="317.528"
                  height="132.141"
                  style={{
                    stroke: "#efefef",
                    strokeMiterlimit: "10",
                    strokeWidth: "6px",
                  }}
                />
                <rect
                  x="5.997"
                  y="1175.729"
                  width="317.528"
                  height="132.141"
                  style={{
                    stroke: "#efefef",
                    strokeMiterlimit: "10",
                    strokeWidth: "6px",
                  }}
                />
                <rect
                  x="8.162"
                  y="1396.784"
                  width="317.528"
                  height="132.141"
                  style={{
                    stroke: "#efefef",
                    strokeMiterlimit: "10",
                    strokeWidth: "6px",
                  }}
                />
                <rect
                  x="8.845"
                  y="1613.701"
                  width="317.528"
                  height="132.141"
                  style={{
                    stroke: "#efefef",
                    strokeMiterlimit: "10",
                    strokeWidth: "6px",
                  }}
                />
              </g>
              <g id="o" data-name="years">
                <g>
                  <path
                    d="M78.292,135.296c-1.857,0-3.713-1.238-3.713-3.61,0-8.458,10.728-17.123,23.415-17.123,15.06,0,23.827,7.633,23.827,18.154,0,21.868-34.349,32.905-42.91,49.512h25.065c13.822,0,17.845-6.189,17.845-6.189l-4.332,13.616h-42.395c0-21.661,39.197-34.658,39.197-57.661,0-9.902-6.705-15.782-14.854-15.782-12.894,0-17.432,8.252-17.432,15.369,0,2.476-1.856,3.713-3.713,3.713Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                  <path
                    d="M126.979,152.109c0-20.733,10.831-37.546,24.137-37.546s24.137,16.813,24.137,37.546-10.831,37.546-24.137,37.546-24.137-16.813-24.137-37.546ZM134.406,152.109c0,19.805,7.53,35.896,16.71,35.896s16.607-16.091,16.607-35.896c0-19.908-7.427-35.896-16.607-35.896s-16.71,15.988-16.71,35.896Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                  <path
                    d="M193.924,179.238c0,6.705,4.745,8.768,6.189,8.768v1.65h-19.702v-1.65c1.444,0,6.086-2.063,6.086-8.768v-52.709c0-6.086-5.364-6.189-6.086-6.189v-.928s10.005-4.848,11.14-4.848c2.373,0,2.373,1.856,2.373,4.848v59.827Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                  <path
                    d="M256.536,167.169c0,12.378-11.45,22.486-25.581,22.486-14.234,0-25.684-10.108-25.684-22.486,0-10.418,8.252-19.186,19.289-21.662-6.911-2.269-11.965-8.045-11.965-14.957,0-8.871,8.252-15.988,18.36-15.988,10.005,0,18.257,7.117,18.257,15.988,0,6.911-4.951,12.688-11.965,14.957,11.037,2.476,19.289,11.243,19.289,21.662ZM249.109,167.169c0-11.45-8.149-20.733-18.154-20.733-10.108,0-18.257,9.284-18.257,20.733,0,11.553,8.149,20.836,18.257,20.836,10.005,0,18.154-9.283,18.154-20.836ZM230.955,144.785c5.983,0,10.831-6.395,10.831-14.338,0-7.839-4.848-14.234-10.831-14.234s-10.831,6.396-10.831,14.234c0,7.943,4.848,14.338,10.831,14.338Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                </g>
                <g>
                  <path
                    d="M78.974,352.213c-1.857,0-3.713-1.238-3.713-3.61,0-8.458,10.728-17.123,23.415-17.123,15.06,0,23.827,7.633,23.827,18.154,0,21.868-34.349,32.905-42.91,49.512h25.065c13.822,0,17.845-6.189,17.845-6.189l-4.332,13.616h-42.395c0-21.661,39.197-34.658,39.197-57.661,0-9.902-6.705-15.782-14.854-15.782-12.894,0-17.432,8.252-17.432,15.369,0,2.476-1.856,3.713-3.713,3.713Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                  <path
                    d="M127.662,369.026c0-20.733,10.831-37.546,24.137-37.546s24.137,16.813,24.137,37.546-10.831,37.546-24.137,37.546-24.137-16.813-24.137-37.546ZM135.089,369.026c0,19.805,7.53,35.896,16.71,35.896s16.607-16.091,16.607-35.896c0-19.908-7.427-35.896-16.607-35.896s-16.71,15.988-16.71,35.896Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                  <path
                    d="M194.607,396.155c0,6.705,4.745,8.768,6.189,8.768v1.65h-19.702v-1.65c1.444,0,6.086-2.063,6.086-8.768v-52.709c0-6.086-5.364-6.189-6.086-6.189v-.928s10.005-4.848,11.14-4.848c2.373,0,2.373,1.856,2.373,4.848v59.827Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                  <path
                    d="M220.91,404.922c.825,0,17.02-7.736,24.859-26.303-4.126,2.682-8.871,4.229-14.131,4.229-14.131,0-25.684-11.553-25.684-25.684,0-14.234,11.553-25.684,25.684-25.684,14.235,0,25.684,11.656,25.684,27.334,0,31.77-32.801,47.758-36.412,47.758v-1.65ZM249.895,357.164c0-13.306-8.148-24.034-18.257-24.034-10.005,0-18.154,10.728-18.154,24.034,0,13.203,8.149,23.931,18.154,23.931,10.109,0,18.257-10.728,18.257-23.931Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                </g>
                <g>
                  <path
                    d="M66.084,573.268c-1.856,0-3.713-1.238-3.713-3.61,0-8.458,10.728-17.123,23.415-17.123,15.06,0,23.828,7.633,23.828,18.154,0,21.868-34.349,32.904-42.91,49.512h25.065c13.822,0,17.845-6.188,17.845-6.188l-4.333,13.615h-42.395c0-21.661,39.197-34.658,39.197-57.66,0-9.902-6.705-15.782-14.854-15.782-12.894,0-17.432,8.252-17.432,15.369,0,2.476-1.857,3.714-3.713,3.714Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                  <path
                    d="M114.772,590.08c0-20.732,10.831-37.546,24.137-37.546s24.137,16.813,24.137,37.546-10.831,37.547-24.137,37.547-24.137-16.813-24.137-37.547ZM122.199,590.08c0,19.805,7.53,35.896,16.71,35.896s16.607-16.092,16.607-35.896c0-19.907-7.427-35.896-16.607-35.896s-16.71,15.988-16.71,35.896Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                  <path
                    d="M171.917,573.268c-1.856,0-3.713-1.238-3.713-3.61,0-8.458,10.728-17.123,23.415-17.123,15.06,0,23.828,7.633,23.828,18.154,0,21.868-34.349,32.904-42.91,49.512h25.065c13.822,0,17.845-6.188,17.845-6.188l-4.333,13.615h-42.395c0-21.661,39.197-34.658,39.197-57.66,0-9.902-6.705-15.782-14.854-15.782-12.894,0-17.432,8.252-17.432,15.369,0,2.476-1.857,3.714-3.713,3.714Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                  <path
                    d="M220.605,590.08c0-20.732,10.831-37.546,24.137-37.546s24.137,16.813,24.137,37.546-10.831,37.547-24.137,37.547-24.137-16.813-24.137-37.547ZM228.032,590.08c0,19.805,7.53,35.896,16.71,35.896s16.607-16.092,16.607-35.896c0-19.907-7.427-35.896-16.607-35.896s-16.71,15.988-16.71,35.896Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                </g>
                <g>
                  <path
                    d="M80.149,790.185c-1.856,0-3.713-1.238-3.713-3.61,0-8.459,10.728-17.123,23.415-17.123,15.06,0,23.828,7.633,23.828,18.154,0,21.867-34.349,32.904-42.91,49.512h25.065c13.822,0,17.845-6.189,17.845-6.189l-4.333,13.616h-42.395c0-21.661,39.197-34.658,39.197-57.66,0-9.902-6.705-15.782-14.854-15.782-12.894,0-17.432,8.252-17.432,15.369,0,2.476-1.857,3.714-3.713,3.714Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                  <path
                    d="M128.836,806.998c0-20.733,10.831-37.547,24.137-37.547s24.137,16.813,24.137,37.547-10.831,37.546-24.137,37.546-24.137-16.813-24.137-37.546ZM136.263,806.998c0,19.805,7.53,35.896,16.71,35.896s16.607-16.091,16.607-35.896c0-19.908-7.427-35.896-16.607-35.896s-16.71,15.988-16.71,35.896Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                  <path
                    d="M185.982,790.185c-1.856,0-3.713-1.238-3.713-3.61,0-8.459,10.728-17.123,23.415-17.123,15.06,0,23.828,7.633,23.828,18.154,0,21.867-34.349,32.904-42.91,49.512h25.065c13.822,0,17.845-6.189,17.845-6.189l-4.333,13.616h-42.395c0-21.661,39.197-34.658,39.197-57.66,0-9.902-6.705-15.782-14.854-15.782-12.894,0-17.432,8.252-17.432,15.369,0,2.476-1.857,3.714-3.713,3.714Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                  <path
                    d="M248.182,834.126c0,6.704,4.745,8.768,6.189,8.768v1.65h-19.702v-1.65c1.444,0,6.086-2.063,6.086-8.768v-52.71c0-6.085-5.364-6.188-6.086-6.188v-.929s10.005-4.848,11.14-4.848c2.373,0,2.373,1.856,2.373,4.848v59.827Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                </g>
                <g>
                  <path
                    d="M62.206,1008.592c-1.857,0-3.713-1.238-3.713-3.61,0-8.458,10.728-17.123,23.415-17.123,15.06,0,23.827,7.633,23.827,18.154,0,21.868-34.349,32.904-42.91,49.512h25.065c13.822,0,17.845-6.188,17.845-6.188l-4.332,13.615h-42.395c0-21.661,39.197-34.658,39.197-57.66,0-9.902-6.705-15.782-14.854-15.782-12.894,0-17.432,8.252-17.432,15.369,0,2.476-1.856,3.714-3.713,3.714Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                  <path
                    d="M110.893,1025.405c0-20.732,10.831-37.546,24.137-37.546s24.137,16.813,24.137,37.546-10.831,37.547-24.137,37.547-24.137-16.813-24.137-37.547ZM118.32,1025.405c0,19.805,7.53,35.896,16.71,35.896s16.607-16.092,16.607-35.896c0-19.907-7.427-35.896-16.607-35.896s-16.71,15.988-16.71,35.896Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                  <path
                    d="M168.039,1008.592c-1.857,0-3.713-1.238-3.713-3.61,0-8.458,10.728-17.123,23.415-17.123,15.06,0,23.827,7.633,23.827,18.154,0,21.868-34.349,32.904-42.91,49.512h25.065c13.822,0,17.845-6.188,17.845-6.188l-4.332,13.615h-42.395c0-21.661,39.197-34.658,39.197-57.66,0-9.902-6.705-15.782-14.854-15.782-12.894,0-17.432,8.252-17.432,15.369,0,2.476-1.856,3.714-3.713,3.714Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                  <path
                    d="M220.44,1008.592c-1.857,0-3.713-1.238-3.713-3.61,0-8.458,10.728-17.123,23.415-17.123,15.06,0,23.827,7.633,23.827,18.154,0,21.868-34.349,32.904-42.91,49.512h25.065c13.822,0,17.845-6.188,17.845-6.188l-4.332,13.615h-42.395c0-21.661,39.197-34.658,39.197-57.66,0-9.902-6.705-15.782-14.854-15.782-12.894,0-17.432,8.252-17.432,15.369,0,2.476-1.856,3.714-3.713,3.714Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                </g>
                <g>
                  <path
                    d="M61.216,1227.181c-1.857,0-3.713-1.238-3.713-3.61,0-8.459,10.728-17.123,23.415-17.123,15.06,0,23.827,7.633,23.827,18.154,0,21.867-34.349,32.904-42.91,49.512h25.065c13.822,0,17.845-6.189,17.845-6.189l-4.332,13.616h-42.395c0-21.661,39.197-34.658,39.197-57.66,0-9.902-6.705-15.782-14.854-15.782-12.894,0-17.432,8.252-17.432,15.369,0,2.476-1.856,3.714-3.713,3.714Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                  <path
                    d="M109.903,1243.994c0-20.733,10.831-37.547,24.137-37.547s24.137,16.813,24.137,37.547-10.831,37.546-24.137,37.546-24.137-16.813-24.137-37.546ZM117.33,1243.994c0,19.805,7.53,35.896,16.71,35.896s16.607-16.091,16.607-35.896c0-19.908-7.427-35.896-16.607-35.896s-16.71,15.988-16.71,35.896Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                  <path
                    d="M167.049,1227.181c-1.857,0-3.713-1.238-3.713-3.61,0-8.459,10.728-17.123,23.415-17.123,15.06,0,23.827,7.633,23.827,18.154,0,21.867-34.349,32.904-42.91,49.512h25.065c13.822,0,17.845-6.189,17.845-6.189l-4.332,13.616h-42.395c0-21.661,39.197-34.658,39.197-57.66,0-9.902-6.705-15.782-14.854-15.782-12.894,0-17.432,8.252-17.432,15.369,0,2.476-1.856,3.714-3.713,3.714Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                  <path
                    d="M243.381,1237.289c13.203.928,23.724,10.418,23.724,22.074,0,12.274-11.553,22.177-25.684,22.177-14.234,0-25.684-8.562-25.684-19.289,0-2.166,1.856-3.301,3.816-3.301,1.754,0,3.61,1.031,3.61,3.301,0,10.521,8.149,17.639,18.257,17.639,10.005,0,18.257-9.077,18.257-20.526s-8.252-20.733-18.257-20.733v-1.444l15.37-23.312h-18.773c-13.822,0-17.845,6.086-17.845,6.086l4.332-13.513h38.166v1.65l-19.289,29.191Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                </g>
                <g>
                  <path
                    d="M58.362,1446.564c-1.857,0-3.713-1.238-3.713-3.61,0-8.459,10.728-17.123,23.415-17.123,15.06,0,23.827,7.633,23.827,18.154,0,21.867-34.349,32.904-42.91,49.512h25.065c13.822,0,17.845-6.189,17.845-6.189l-4.332,13.616h-42.395c0-21.661,39.197-34.658,39.197-57.66,0-9.902-6.705-15.782-14.854-15.782-12.894,0-17.432,8.252-17.432,15.369,0,2.476-1.856,3.714-3.713,3.714Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                  <path
                    d="M107.05,1463.377c0-20.733,10.831-37.547,24.137-37.547s24.137,16.813,24.137,37.547-10.831,37.546-24.137,37.546-24.137-16.813-24.137-37.546ZM114.476,1463.377c0,19.805,7.53,35.896,16.71,35.896s16.607-16.091,16.607-35.896c0-19.908-7.427-35.896-16.607-35.896s-16.71,15.988-16.71,35.896Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                  <path
                    d="M164.195,1446.564c-1.857,0-3.713-1.238-3.713-3.61,0-8.459,10.728-17.123,23.415-17.123,15.06,0,23.827,7.633,23.827,18.154,0,21.867-34.349,32.904-42.91,49.512h25.065c13.822,0,17.845-6.189,17.845-6.189l-4.332,13.616h-42.395c0-21.661,39.197-34.658,39.197-57.66,0-9.902-6.705-15.782-14.854-15.782-12.894,0-17.432,8.252-17.432,15.369,0,2.476-1.856,3.714-3.713,3.714Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                  <path
                    d="M212.883,1482.047v-1.65l44.664-54.566h2.167v48.377c9.799-1.135,12.894-5.776,12.894-5.776l-4.333,13.616h-8.561v8.458c0,6.704,4.642,8.768,6.085,8.768v1.65h-19.598v-1.65c1.444,0,6.086-2.063,6.086-8.768v-8.458h-39.403ZM252.286,1434.908l-32.492,39.609h32.492v-39.609Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                </g>
                <g>
                  <path
                    d="M64.063,1663.48c-1.857,0-3.713-1.238-3.713-3.61,0-8.459,10.728-17.123,23.415-17.123,15.06,0,23.827,7.633,23.827,18.154,0,21.867-34.349,32.904-42.91,49.512h25.065c13.822,0,17.845-6.189,17.845-6.189l-4.332,13.616h-42.395c0-21.661,39.197-34.658,39.197-57.66,0-9.902-6.705-15.782-14.854-15.782-12.894,0-17.432,8.252-17.432,15.369,0,2.476-1.856,3.714-3.713,3.714Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                  <path
                    d="M112.751,1680.293c0-20.733,10.831-37.547,24.137-37.547s24.137,16.813,24.137,37.547-10.831,37.546-24.137,37.546-24.137-16.813-24.137-37.546ZM120.178,1680.293c0,19.805,7.53,35.896,16.71,35.896s16.607-16.091,16.607-35.896c0-19.908-7.427-35.896-16.607-35.896s-16.71,15.988-16.71,35.896Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                  <path
                    d="M169.896,1663.48c-1.857,0-3.713-1.238-3.713-3.61,0-8.459,10.728-17.123,23.415-17.123,15.06,0,23.827,7.633,23.827,18.154,0,21.867-34.349,32.904-42.91,49.512h25.065c13.822,0,17.845-6.189,17.845-6.189l-4.332,13.616h-42.395c0-21.661,39.197-34.658,39.197-57.66,0-9.902-6.705-15.782-14.854-15.782-12.894,0-17.432,8.252-17.432,15.369,0,2.476-1.856,3.714-3.713,3.714Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                  <path
                    d="M244.268,1717.839c-14.131,0-25.684-9.696-25.684-18.979,0-2.372,1.856-3.61,3.713-3.61s3.713,1.238,3.713,3.61c0,8.355,8.252,17.329,18.257,17.329,10.109,0,18.257-10.831,18.257-24.034,0-13.306-7.633-24.033-19.289-24.033-10.005,0-16.504,8.045-16.504,8.045l-1.65-.206,4.539-32.183h22.486c13.822,0,17.845-6.086,17.845-6.086l-4.332,13.616h-35.38l-3.198,22.074c2.373-2.27,8.355-6.911,17.226-6.911,14.235,0,25.684,11.449,25.684,25.684,0,14.132-11.449,25.685-25.684,25.685Z"
                    style={{
                      fill: "#efefef",
                      stroke: "#efefef",
                      strokeMiterlimit: "10",
                      strokeWidth: "2px",
                    }}
                  />
                </g>
              </g>
            </g>
            <g id="p" data-name="black_boxes" style={{ opacity: "1" }}>
              {/* cerise development */}
              <rect
                onClick={() => setActiveCareerElement(4)}
                x="413.087"
                y="690.113"
                width="357.518"
                height="1038.237"
                style={{
                  stroke: "#efefef",
                  strokeMiterlimit: "10",
                  strokeWidth: "4px",
                }}
              />
              {/* goldman */}
              <rect
                onClick={() => setActiveCareerElement(1)}
                x="402.114"
                y="305.699"
                width="908.803"
                height="257.345"
                style={{
                  stroke: "#efefef",
                  strokeMiterlimit: "10",
                  strokeWidth: "4px",
                }}
              />
              {/* cornell */}
              <rect
                onClick={() => setActiveCareerElement(0)}
                x="405.379"
                y="39.255"
                width="1481.077"
                height="164.929"
                style={{
                  stroke: "#efefef",
                  strokeMiterlimit: "10",
                  strokeWidth: "4px",
                }}
              />
              {/* graphic design */}
              <rect
                onClick={() => setActiveCareerElement(2)}
                x="1414.65"
                y="307.993"
                width="169.788"
                height="258.826"
                style={{
                  stroke: "#efefef",
                  strokeMiterlimit: "10",
                  strokeWidth: "4px",
                }}
              />
              {/* dated */}
              <rect
                onClick={() => setActiveCareerElement(5)}
                x="909.047"
                y="669.58"
                width="667.357"
                height="104.075"
                style={{
                  stroke: "#efefef",
                  strokeMiterlimit: "10",
                  strokeWidth: "4px",
                }}
              />
              {/* product management */}
              <rect
                onClick={() => setActiveCareerElement(6)}
                x="1700.266"
                y="677.581"
                width="181.714"
                height="312.638"
                style={{
                  stroke: "#efefef",
                  strokeMiterlimit: "10",
                  strokeWidth: "4px",
                }}
              />
              {/* app development */}
              <rect
                onClick={() => setActiveCareerElement(3)}
                x="1698.636"
                y="309.741"
                width="181.714"
                height="259.097"
                style={{
                  stroke: "#efefef",
                  strokeMiterlimit: "10",
                  strokeWidth: "4px",
                }}
              />
              {/* bloomberg */}
              <rect
                onClick={() => setActiveCareerElement(7)}
                x="910.535"
                y="864.224"
                width="667.357"
                height="128.351"
                style={{
                  stroke: "#efefef",
                  strokeMiterlimit: "10",
                  strokeWidth: "4px",
                }}
              />
              {/* goodseed */}
              <rect
                onClick={() => setActiveCareerElement(8)}
                x="893.197"
                y="1114.475"
                width="262.125"
                height="605.358"
                style={{
                  stroke: "#efefef",
                  strokeMiterlimit: "10",
                  strokeWidth: "4px",
                }}
              />
              {/* noodles */}
              <rect
                onClick={() => setActiveCareerElement(10)}
                x="1293.01"
                y="1294.694"
                width="575.89"
                height="420.789"
                style={{
                  stroke: "#efefef",
                  strokeMiterlimit: "10",
                  strokeWidth: "4px",
                }}
              />
              {/* emblematic */}
              <rect
                onClick={() => setActiveCareerElement(9)}
                x="1295.561"
                y="1086.911"
                width="571.994"
                height="80.74"
                style={{
                  stroke: "#efefef",
                  strokeMiterlimit: "10",
                  strokeWidth: "4px",
                }}
              />
            </g>
            <g id="q" data-name="text">
              <g>
                <g id="r" data-name="thinnertext">
                  <g>
                    <path
                      d="M1471.85,389.097c0,4.009-3.039,7.275-6.758,7.275-2.812,0-3.686-1.423-4.882-1.423-.905,0-1.812,1.423-1.812,1.423v-19.788c0-1.875-1.681-1.908-1.907-1.908v-.323s3.137-1.52,3.492-1.52c.743,0,.743.582.743,1.52v9.183s1.682-1.713,4.365-1.713c3.719,0,6.758,3.266,6.758,7.275ZM1469.522,389.065c0-3.719-1.649-6.726-4.721-6.726-2.457,0-4.074,1.972-4.074,1.972v9.571s1.553,1.973,4.074,1.973c3.039,0,4.721-3.104,4.721-6.79Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1475.825,388.58v.518c0,3.686,2.264,6.531,5.012,6.531,2.78,0,4.43-2.069,4.43-2.069l.42.291c-1.229,1.52-3.395,2.522-5.432,2.522-3.751,0-6.79-3.266-6.79-7.275s3.039-7.275,6.79-7.275c3.557,0,6.499,2.975,6.726,6.757h-11.155ZM1475.857,388.063h8.763c-.323-3.201-2.167-5.723-4.365-5.723-2.231,0-4.074,2.49-4.397,5.723Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1504.858,400.866c0,1.973-1.584,4.495-8.181,4.495-6.11,0-7.922-2.393-7.922-3.848,0-1.002.68-1.455,1.358-1.455s1.358.453,1.358,1.261c0,1.908,1.552,3.524,4.914,3.524,4.979,0,6.015-2.49,6.015-4.139,0-1.681-1.423-3.104-3.233-3.104-2.975,0-4.527,1.261-7.47,1.261-2.23,0-3.653-1.002-3.653-2.651,0-1.52,1.552-2.458,3.233-2.651-1.293-1.229-2.069-2.975-2.069-4.915,0-3.783,2.975-6.822,6.66-6.822,1.585,0,3.072.582,4.204,1.552,0-.064.711-2.586,2.619-2.586.679,0,1.002.485,1.002.97s-.355,1.002-1.002,1.002c-1.682,0-2.103,1.002-2.135,1.035,1.229,1.229,1.973,2.942,1.973,4.85,0,3.75-2.975,6.79-6.661,6.79-1.487,0-2.845-.485-3.944-1.326-1.229,0-2.296.744-2.296,2.102,0,1.067.905,2.134,2.36,2.134,2.878,0,4.01-1.293,7.47-1.293,3.912,0,5.399,2.102,5.399,3.815ZM1491.536,388.677c0,3.427,1.939,6.24,4.332,6.24s4.333-2.813,4.333-6.24c0-3.459-1.939-6.337-4.333-6.337s-4.332,2.878-4.332,6.337Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1518.436,393.107c0,2.102,1.487,2.749,1.907,2.749v.517h-4.235v-1.681c-.841.517-2.975,1.649-5.497,1.649-3.071,0-5.755-1.326-5.755-3.331,0-2.328,2.748-2.975,5.141-3.783,1.973-.615,4.236-1.391,5.95-3.137-.324-2.134-1.326-3.75-4.074-3.75-1.746,0-3.331,1.261-3.331,3.524,0,.776-.614,1.164-1.229,1.164-.582,0-1.196-.388-1.196-1.164,0-1.811,2.49-4.042,5.982-4.042,3.653,0,6.337,2.004,6.337,7.016v4.268ZM1516.108,388.839c0-.711,0-1.423-.064-2.102-1.778,1.649-3.944,2.36-5.885,3.007-2.393.776-2.975,1.94-2.975,3.266,0,1.617,1.648,2.684,3.848,2.684,2.684,0,4.624-1.293,5.076-1.617v-5.238Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1526.193,383.504c.032,0,1.585-1.681,4.365-1.681,2.748,0,5.69,1.649,5.69,5.399v5.885c0,2.102,1.455,2.749,1.908,2.749v.517h-6.144v-.517c.42,0,1.907-.647,1.907-2.749v-6.402c0-2.91-1.293-4.365-3.653-4.365-2.555,0-4.042,1.811-4.074,1.843v8.924c0,2.102,1.455,2.749,1.908,2.749v.517h-6.144v-.517c.453,0,1.907-.647,1.907-2.749v-7.501c0-1.908-1.681-1.94-1.907-1.94v-.291s3.137-1.552,3.492-1.552c.743,0,.743.614.743,1.552v.129Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1443.027,434.65c.453,0,1.907-.647,1.907-2.749v-16.587c0-1.811-1.681-1.843-1.907-1.843v-.323s3.137-1.52,3.492-1.52c.775,0,.775.582.775,1.52v18.753c0,2.102,1.455,2.749,1.908,2.749v.517h-6.176v-.517Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1452.629,427.375v.518c0,3.686,2.264,6.531,5.012,6.531,2.78,0,4.43-2.069,4.43-2.069l.42.291c-1.229,1.52-3.395,2.522-5.432,2.522-3.751,0-6.79-3.266-6.79-7.275s3.039-7.275,6.79-7.275c3.557,0,6.499,2.975,6.726,6.757h-11.155ZM1452.661,426.858h8.763c-.323-3.201-2.167-5.723-4.365-5.723-2.231,0-4.074,2.49-4.397,5.723Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1478.429,431.902c0,2.102,1.487,2.749,1.907,2.749v.517h-4.235v-1.681c-.841.517-2.975,1.649-5.497,1.649-3.071,0-5.755-1.326-5.755-3.331,0-2.328,2.748-2.975,5.141-3.783,1.973-.615,4.236-1.391,5.95-3.137-.324-2.134-1.326-3.75-4.074-3.75-1.746,0-3.331,1.261-3.331,3.524,0,.776-.614,1.164-1.229,1.164-.582,0-1.196-.388-1.196-1.164,0-1.811,2.49-4.042,5.981-4.042,3.654,0,6.338,2.004,6.338,7.016v4.268ZM1476.101,427.634c0-.711,0-1.423-.064-2.102-1.778,1.649-3.945,2.36-5.885,3.007-2.393.776-2.975,1.94-2.975,3.266,0,1.617,1.648,2.684,3.848,2.684,2.684,0,4.624-1.293,5.076-1.617v-5.238Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1486.218,423.366c.259-.355,2.069-2.748,5.4-2.748,2.037,0,4.623,1.067,4.623,3.039,0,.744-.582,1.1-1.131,1.1-.615,0-1.197-.388-1.197-1.1,0-1.908-1.293-2.522-2.586-2.522-3.072,0-4.851,2.684-5.109,3.104v7.663c0,2.102,1.455,2.749,1.908,2.749v.517h-6.176v-.517c.453,0,1.907-.647,1.907-2.749v-7.566c0-1.843-1.681-1.875-1.907-1.875v-.291s3.137-1.552,3.492-1.552c.775,0,.775.614.775,1.552v1.196Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1502.091,422.299c.032,0,1.585-1.681,4.365-1.681,2.748,0,5.69,1.649,5.69,5.399v5.885c0,2.102,1.455,2.749,1.908,2.749v.517h-6.144v-.517c.42,0,1.907-.647,1.907-2.749v-6.402c0-2.91-1.293-4.365-3.653-4.365-2.555,0-4.042,1.811-4.074,1.843v8.924c0,2.102,1.455,2.749,1.908,2.749v.517h-6.144v-.517c.453,0,1.907-.647,1.907-2.749v-7.501c0-1.908-1.681-1.94-1.907-1.94v-.291s3.137-1.552,3.492-1.552c.743,0,.743.614.743,1.552v.129Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1515.669,434.65c.453,0,1.907-.647,1.907-2.749v-7.566c0-1.843-1.681-1.875-1.907-1.875v-.291s3.137-1.552,3.492-1.552c.775,0,.775.614.775,1.552v9.732c0,2.102,1.455,2.749,1.908,2.749v.517h-6.176v-.517ZM1518.741,417.869c-.775,0-1.422-.646-1.422-1.423,0-.808.646-1.455,1.422-1.455.809,0,1.455.647,1.455,1.455,0,.776-.646,1.423-1.455,1.423Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1527.696,422.299c.032,0,1.585-1.681,4.365-1.681,2.748,0,5.69,1.649,5.69,5.399v5.885c0,2.102,1.455,2.749,1.908,2.749v.517h-6.144v-.517c.42,0,1.907-.647,1.907-2.749v-6.402c0-2.91-1.293-4.365-3.653-4.365-2.555,0-4.042,1.811-4.074,1.843v8.924c0,2.102,1.455,2.749,1.908,2.749v.517h-6.144v-.517c.453,0,1.907-.647,1.907-2.749v-7.501c0-1.908-1.681-1.94-1.907-1.94v-.291s3.137-1.552,3.492-1.552c.743,0,.743.614.743,1.552v.129Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1558.087,439.661c0,1.973-1.584,4.495-8.181,4.495-6.11,0-7.922-2.393-7.922-3.848,0-1.002.68-1.455,1.358-1.455s1.358.453,1.358,1.261c0,1.908,1.552,3.524,4.914,3.524,4.979,0,6.015-2.49,6.015-4.139,0-1.681-1.423-3.104-3.233-3.104-2.975,0-4.527,1.261-7.47,1.261-2.23,0-3.653-1.002-3.653-2.651,0-1.52,1.552-2.458,3.233-2.651-1.293-1.229-2.069-2.975-2.069-4.915,0-3.783,2.975-6.822,6.66-6.822,1.585,0,3.072.582,4.204,1.552,0-.064.711-2.586,2.619-2.586.679,0,1.002.485,1.002.97s-.355,1.002-1.002,1.002c-1.682,0-2.103,1.002-2.135,1.035,1.229,1.229,1.973,2.942,1.973,4.85,0,3.75-2.975,6.79-6.661,6.79-1.487,0-2.845-.485-3.944-1.326-1.229,0-2.296.744-2.296,2.102,0,1.067.905,2.134,2.36,2.134,2.878,0,4.01-1.293,7.47-1.293,3.912,0,5.399,2.102,5.399,3.815ZM1544.766,427.472c0,3.427,1.939,6.24,4.332,6.24s4.333-2.813,4.333-6.24c0-3.459-1.939-6.337-4.333-6.337s-4.332,2.878-4.332,6.337Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1466.403,478.457c0,1.973-1.584,4.494-8.181,4.494-6.11,0-7.922-2.393-7.922-3.848,0-1.002.68-1.455,1.358-1.455s1.358.453,1.358,1.262c0,1.907,1.552,3.524,4.914,3.524,4.979,0,6.015-2.49,6.015-4.139,0-1.682-1.423-3.104-3.233-3.104-2.975,0-4.527,1.261-7.47,1.261-2.23,0-3.653-1.002-3.653-2.651,0-1.52,1.552-2.457,3.233-2.651-1.293-1.229-2.069-2.975-2.069-4.914,0-3.783,2.975-6.822,6.66-6.822,1.585,0,3.072.582,4.204,1.552,0-.064.711-2.586,2.619-2.586.679,0,1.002.485,1.002.97s-.355,1.002-1.002,1.002c-1.682,0-2.103,1.002-2.135,1.035,1.229,1.229,1.973,2.942,1.973,4.85,0,3.751-2.975,6.79-6.661,6.79-1.487,0-2.845-.485-3.944-1.326-1.229,0-2.296.744-2.296,2.102,0,1.067.905,2.135,2.36,2.135,2.878,0,4.01-1.294,7.47-1.294,3.912,0,5.399,2.102,5.399,3.815ZM1453.081,466.267c0,3.428,1.939,6.24,4.332,6.24s4.333-2.812,4.333-6.24c0-3.459-1.939-6.337-4.333-6.337s-4.332,2.878-4.332,6.337Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1470.668,462.161c.259-.355,2.069-2.748,5.4-2.748,2.037,0,4.623,1.067,4.623,3.039,0,.744-.582,1.1-1.131,1.1-.615,0-1.197-.388-1.197-1.1,0-1.908-1.293-2.522-2.586-2.522-3.072,0-4.851,2.684-5.109,3.104v7.663c0,2.102,1.455,2.748,1.908,2.748v.518h-6.176v-.518c.453,0,1.907-.646,1.907-2.748v-7.566c0-1.843-1.681-1.875-1.907-1.875v-.291s3.137-1.552,3.492-1.552c.775,0,.775.614.775,1.552v1.196Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1494.82,470.697c0,2.102,1.487,2.748,1.907,2.748v.518h-4.235v-1.682c-.841.518-2.975,1.649-5.497,1.649-3.071,0-5.755-1.326-5.755-3.33,0-2.328,2.748-2.975,5.141-3.783,1.973-.614,4.236-1.391,5.95-3.137-.324-2.134-1.326-3.75-4.074-3.75-1.746,0-3.331,1.261-3.331,3.524,0,.776-.614,1.164-1.229,1.164-.582,0-1.196-.388-1.196-1.164,0-1.811,2.49-4.042,5.981-4.042,3.654,0,6.338,2.004,6.338,7.017v4.268ZM1492.492,466.429c0-.712,0-1.423-.064-2.103-1.778,1.649-3.945,2.36-5.885,3.008-2.393.775-2.975,1.939-2.975,3.266,0,1.616,1.648,2.684,3.848,2.684,2.684,0,4.624-1.294,5.076-1.617v-5.237Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1497.275,460.965s3.137-1.552,3.492-1.552c.743,0,.743.614.743,1.552v.162s1.649-1.713,4.365-1.713c3.751,0,6.79,3.266,6.79,7.274s-3.039,7.275-6.79,7.275c-2.878,0-4.365-1.714-4.365-1.714v7.437c0,2.102,1.487,2.749,1.94,2.749v.517h-6.176v-.517c.453,0,1.907-.647,1.907-2.749v-16.554c0-1.843-1.681-1.875-1.907-1.875v-.291ZM1501.51,471.473s1.585,1.972,4.074,1.972c3.071,0,4.721-3.104,4.721-6.79,0-3.718-1.616-6.725-4.688-6.725-2.489,0-4.106,1.972-4.106,1.972v9.571Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1523.785,473.445c.453,0,1.907-.646,1.907-2.748v-6.402c0-2.91-1.261-4.365-3.621-4.365-2.587,0-4.074,1.811-4.074,1.843v8.924c0,2.102,1.455,2.748,1.908,2.748v.518h-6.176v-.518c.453,0,1.94-.646,1.94-2.748v-16.522c0-1.875-1.714-1.908-1.94-1.908v-.323s3.137-1.52,3.492-1.52c.775,0,.775.582.775,1.52v9.15s1.585-1.681,4.365-1.681c2.748,0,5.69,1.649,5.69,5.399v5.885c0,2.102,1.455,2.748,1.908,2.748v.518h-6.176v-.518Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1531.575,473.445c.453,0,1.907-.646,1.907-2.748v-7.566c0-1.843-1.681-1.875-1.907-1.875v-.291s3.137-1.552,3.492-1.552c.775,0,.775.614.775,1.552v9.732c0,2.102,1.455,2.748,1.908,2.748v.518h-6.176v-.518ZM1534.647,456.664c-.775,0-1.422-.646-1.422-1.423,0-.808.646-1.455,1.422-1.455.809,0,1.455.646,1.455,1.455,0,.776-.646,1.423-1.455,1.423Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1545.736,459.413c3.492,0,5.788,2.231,5.788,4.042,0,.776-.614,1.164-1.196,1.164-.614,0-1.229-.388-1.229-1.164,0-2.263-1.391-3.524-3.137-3.524-2.716,0-4.785,2.813-4.785,6.757,0,3.687,2.264,6.531,5.012,6.531,2.78,0,4.43-2.069,4.43-2.069l.452.291c-1.261,1.521-3.427,2.522-5.464,2.522-3.751,0-6.79-3.266-6.79-7.275,0-4.3,3.071-7.274,6.919-7.274Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1468.52,511.044s-1.487,1.714-4.365,1.714c-3.751,0-6.758-3.266-6.758-7.275s3.007-7.275,6.758-7.275c2.716,0,4.365,1.714,4.365,1.714v-6.952c0-1.875-1.682-1.907-1.908-1.907v-.323s3.137-1.521,3.492-1.521c.743,0,.743.582.743,1.521v18.753c0,2.102,1.455,2.748,1.908,2.748v.518h-4.235v-1.714ZM1468.52,510.269v-9.571s-1.617-1.972-4.106-1.972c-3.072,0-4.688,3.007-4.688,6.725,0,3.687,1.648,6.79,4.721,6.79,2.489,0,4.074-1.972,4.074-1.972Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1476.18,504.966v.517c0,3.687,2.264,6.531,5.012,6.531,2.78,0,4.43-2.069,4.43-2.069l.42.291c-1.229,1.521-3.395,2.522-5.432,2.522-3.751,0-6.79-3.266-6.79-7.275s3.039-7.275,6.79-7.275c3.557,0,6.499,2.975,6.726,6.758h-11.155ZM1476.212,504.449h8.763c-.323-3.201-2.167-5.723-4.365-5.723-2.231,0-4.074,2.49-4.397,5.723Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1501.851,509.234c0,2.587-3.331,3.524-6.338,3.524-3.233,0-6.435-1.714-6.435-3.33,0-.614.582-.938,1.164-.938s1.196.323,1.196.97c0,1.357,1.229,2.748,4.365,2.748,2.457,0,3.589-.905,3.589-2.296,0-2.975-10.443-3.912-10.443-8.051,0-2.781,3.492-3.654,6.111-3.654,2.684,0,6.143,1.714,6.143,3.331,0,.614-.614.905-1.196.905-.614,0-1.196-.291-1.196-.938,0-1.358-1.293-2.781-4.139-2.781-2.004,0-3.266,1.034-3.266,2.425,0,2.877,10.444,3.977,10.444,8.083Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1502.915,512.241c.453,0,1.907-.646,1.907-2.748v-7.566c0-1.843-1.681-1.875-1.907-1.875v-.292s3.137-1.552,3.492-1.552c.775,0,.775.614.775,1.552v9.733c0,2.102,1.455,2.748,1.908,2.748v.518h-6.176v-.518ZM1505.987,495.459c-.775,0-1.422-.646-1.422-1.422,0-.809.646-1.455,1.422-1.455.809,0,1.455.646,1.455,1.455,0,.775-.646,1.422-1.455,1.422Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1527.52,517.252c0,1.973-1.584,4.494-8.181,4.494-6.11,0-7.922-2.393-7.922-3.848,0-1.002.68-1.455,1.358-1.455s1.358.453,1.358,1.262c0,1.907,1.552,3.524,4.914,3.524,4.979,0,6.015-2.49,6.015-4.139,0-1.682-1.423-3.104-3.233-3.104-2.975,0-4.527,1.261-7.47,1.261-2.23,0-3.653-1.002-3.653-2.651,0-1.52,1.552-2.457,3.233-2.651-1.293-1.229-2.069-2.975-2.069-4.914,0-3.783,2.975-6.823,6.66-6.823,1.585,0,3.072.582,4.204,1.552,0-.064.711-2.587,2.619-2.587.679,0,1.002.485,1.002.971s-.355,1.002-1.002,1.002c-1.682,0-2.103,1.002-2.135,1.035,1.229,1.229,1.973,2.942,1.973,4.851,0,3.751-2.975,6.79-6.661,6.79-1.487,0-2.845-.485-3.944-1.326-1.229,0-2.296.744-2.296,2.102,0,1.067.905,2.135,2.36,2.135,2.878,0,4.01-1.294,7.47-1.294,3.912,0,5.399,2.102,5.399,3.815ZM1514.199,505.063c0,3.428,1.939,6.24,4.332,6.24s4.333-2.812,4.333-6.24c0-3.46-1.939-6.337-4.333-6.337s-4.332,2.877-4.332,6.337Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1531.753,499.888c.032,0,1.585-1.681,4.365-1.681,2.748,0,5.69,1.648,5.69,5.4v5.885c0,2.102,1.455,2.748,1.908,2.748v.518h-6.144v-.518c.42,0,1.907-.646,1.907-2.748v-6.402c0-2.91-1.293-4.365-3.653-4.365-2.555,0-4.042,1.811-4.074,1.843v8.924c0,2.102,1.455,2.748,1.908,2.748v.518h-6.144v-.518c.453,0,1.907-.646,1.907-2.748v-7.501c0-1.908-1.681-1.94-1.907-1.94v-.292s3.137-1.552,3.492-1.552c.743,0,.743.614.743,1.552v.129Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                  </g>
                  <g>
                    <path
                      d="M1761.997,402.644c0,4.196-3.182,7.615-7.073,7.615-2.945,0-3.858-1.489-5.111-1.489-.947,0-1.895,1.489-1.895,1.489v-20.712c0-1.963-1.76-1.997-1.997-1.997v-.338s3.283-1.591,3.655-1.591c.778,0,.778.609.778,1.591v9.612s1.76-1.794,4.569-1.794c3.892,0,7.073,3.418,7.073,7.615ZM1759.56,402.61c0-3.892-1.726-7.04-4.94-7.04-2.572,0-4.265,2.064-4.265,2.064v10.018s1.624,2.064,4.265,2.064c3.181,0,4.94-3.249,4.94-7.107Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1766.158,402.103v.542c0,3.858,2.369,6.836,5.246,6.836,2.91,0,4.637-2.166,4.637-2.166l.439.305c-1.286,1.59-3.554,2.64-5.686,2.64-3.926,0-7.107-3.418-7.107-7.615s3.182-7.615,7.107-7.615c3.723,0,6.803,3.114,7.039,7.074h-11.676ZM1766.193,401.561h9.171c-.338-3.351-2.268-5.991-4.568-5.991-2.335,0-4.265,2.606-4.603,5.991Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1796.549,414.963c0,2.064-1.658,4.705-8.562,4.705-6.396,0-8.292-2.504-8.292-4.027,0-1.049.711-1.523,1.422-1.523s1.422.474,1.422,1.32c0,1.997,1.624,3.689,5.144,3.689,5.212,0,6.295-2.606,6.295-4.332,0-1.76-1.489-3.249-3.384-3.249-3.114,0-4.738,1.32-7.818,1.32-2.335,0-3.824-1.049-3.824-2.775,0-1.59,1.625-2.572,3.385-2.775-1.354-1.286-2.166-3.114-2.166-5.145,0-3.959,3.113-7.141,6.972-7.141,1.658,0,3.215.609,4.399,1.625,0-.067.745-2.708,2.741-2.708.711,0,1.05.508,1.05,1.016s-.373,1.049-1.05,1.049c-1.76,0-2.199,1.049-2.233,1.083,1.286,1.286,2.064,3.08,2.064,5.077,0,3.926-3.113,7.107-6.972,7.107-1.557,0-2.979-.508-4.129-1.388-1.286,0-2.403.778-2.403,2.2,0,1.117.948,2.234,2.471,2.234,3.012,0,4.196-1.354,7.818-1.354,4.095,0,5.651,2.2,5.651,3.993ZM1782.606,402.204c0,3.587,2.03,6.532,4.535,6.532s4.535-2.944,4.535-6.532c0-3.622-2.031-6.634-4.535-6.634s-4.535,3.012-4.535,6.634Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1810.764,406.841c0,2.2,1.557,2.877,1.996,2.877v.542h-4.434v-1.76c-.88.542-3.113,1.726-5.753,1.726-3.216,0-6.024-1.388-6.024-3.486,0-2.437,2.877-3.114,5.381-3.96,2.064-.643,4.434-1.455,6.228-3.283-.338-2.234-1.388-3.926-4.265-3.926-1.827,0-3.485,1.32-3.485,3.689,0,.812-.644,1.219-1.286,1.219-.609,0-1.252-.406-1.252-1.219,0-1.895,2.605-4.23,6.261-4.23,3.824,0,6.634,2.099,6.634,7.344v4.467ZM1808.326,402.373c0-.745,0-1.489-.067-2.2-1.861,1.726-4.129,2.471-6.159,3.147-2.505.812-3.114,2.031-3.114,3.418,0,1.692,1.727,2.809,4.027,2.809,2.81,0,4.84-1.354,5.313-1.692v-5.483Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1818.885,396.789c.034,0,1.658-1.76,4.569-1.76,2.876,0,5.956,1.726,5.956,5.652v6.159c0,2.2,1.523,2.877,1.997,2.877v.542h-6.431v-.542c.44,0,1.997-.677,1.997-2.877v-6.701c0-3.046-1.354-4.569-3.824-4.569-2.674,0-4.23,1.896-4.265,1.929v9.341c0,2.2,1.522,2.877,1.997,2.877v.542h-6.431v-.542c.474,0,1.997-.677,1.997-2.877v-7.852c0-1.997-1.76-2.031-1.997-2.031v-.305s3.283-1.625,3.655-1.625c.778,0,.778.643.778,1.625v.136Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1731.826,450.328c.474,0,1.997-.677,1.997-2.877v-17.362c0-1.895-1.76-1.929-1.997-1.929v-.338s3.283-1.59,3.655-1.59c.812,0,.812.609.812,1.59v19.629c0,2.2,1.522,2.877,1.996,2.877v.542h-6.464v-.542Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1741.878,442.714v.542c0,3.858,2.369,6.836,5.246,6.836,2.91,0,4.637-2.166,4.637-2.166l.439.305c-1.286,1.59-3.554,2.64-5.686,2.64-3.926,0-7.107-3.418-7.107-7.615s3.182-7.615,7.107-7.615c3.723,0,6.803,3.114,7.039,7.074h-11.676ZM1741.912,442.172h9.171c-.338-3.351-2.268-5.991-4.568-5.991-2.335,0-4.265,2.606-4.603,5.991Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1768.884,447.451c0,2.2,1.557,2.877,1.996,2.877v.542h-4.434v-1.76c-.88.542-3.113,1.726-5.753,1.726-3.216,0-6.024-1.388-6.024-3.486,0-2.437,2.877-3.113,5.381-3.959,2.064-.643,4.434-1.456,6.228-3.283-.338-2.234-1.388-3.926-4.265-3.926-1.827,0-3.485,1.32-3.485,3.689,0,.812-.644,1.219-1.286,1.219-.609,0-1.252-.406-1.252-1.219,0-1.895,2.605-4.23,6.261-4.23,3.824,0,6.634,2.099,6.634,7.344v4.467ZM1766.447,442.984c0-.745,0-1.489-.067-2.2-1.861,1.726-4.129,2.471-6.159,3.147-2.505.812-3.114,2.031-3.114,3.418,0,1.692,1.727,2.809,4.027,2.809,2.81,0,4.84-1.354,5.313-1.692v-5.483Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1777.039,438.517c.271-.372,2.166-2.877,5.651-2.877,2.133,0,4.84,1.117,4.84,3.182,0,.778-.609,1.15-1.185,1.15-.643,0-1.252-.406-1.252-1.15,0-1.997-1.354-2.64-2.708-2.64-3.215,0-5.076,2.809-5.347,3.249v8.021c0,2.2,1.522,2.877,1.996,2.877v.542h-6.464v-.542c.474,0,1.997-.677,1.997-2.877v-7.919c0-1.929-1.76-1.963-1.997-1.963v-.305s3.283-1.625,3.655-1.625c.812,0,.812.643.812,1.625v1.252Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1793.656,437.4c.034,0,1.658-1.76,4.569-1.76,2.876,0,5.956,1.726,5.956,5.652v6.159c0,2.2,1.523,2.877,1.997,2.877v.542h-6.431v-.542c.44,0,1.997-.677,1.997-2.877v-6.701c0-3.046-1.354-4.569-3.824-4.569-2.674,0-4.23,1.896-4.265,1.929v9.341c0,2.2,1.523,2.877,1.997,2.877v.542h-6.431v-.542c.474,0,1.997-.677,1.997-2.877v-7.852c0-1.997-1.76-2.031-1.997-2.031v-.305s3.283-1.625,3.655-1.625c.778,0,.778.643.778,1.625v.136Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1807.869,450.328c.474,0,1.997-.677,1.997-2.877v-7.919c0-1.929-1.76-1.963-1.997-1.963v-.305s3.283-1.625,3.655-1.625c.812,0,.812.643.812,1.625v10.187c0,2.2,1.522,2.877,1.996,2.877v.542h-6.464v-.542ZM1811.084,432.763c-.812,0-1.489-.677-1.489-1.489,0-.846.678-1.523,1.489-1.523.847,0,1.523.677,1.523,1.523,0,.812-.677,1.489-1.523,1.489Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1820.458,437.4c.034,0,1.658-1.76,4.569-1.76,2.876,0,5.956,1.726,5.956,5.652v6.159c0,2.2,1.523,2.877,1.997,2.877v.542h-6.431v-.542c.44,0,1.997-.677,1.997-2.877v-6.701c0-3.046-1.354-4.569-3.824-4.569-2.674,0-4.23,1.896-4.265,1.929v9.341c0,2.2,1.523,2.877,1.997,2.877v.542h-6.431v-.542c.474,0,1.997-.677,1.997-2.877v-7.852c0-1.997-1.76-2.031-1.997-2.031v-.305s3.283-1.625,3.655-1.625c.778,0,.778.643.778,1.625v.136Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1852.271,455.574c0,2.065-1.658,4.705-8.562,4.705-6.396,0-8.292-2.504-8.292-4.027,0-1.049.711-1.523,1.422-1.523s1.422.474,1.422,1.32c0,1.997,1.624,3.689,5.144,3.689,5.212,0,6.295-2.606,6.295-4.332,0-1.76-1.488-3.249-3.384-3.249-3.114,0-4.738,1.32-7.818,1.32-2.335,0-3.824-1.049-3.824-2.775,0-1.59,1.624-2.572,3.385-2.775-1.354-1.286-2.166-3.114-2.166-5.145,0-3.959,3.113-7.141,6.972-7.141,1.658,0,3.215.609,4.399,1.625,0-.067.745-2.708,2.741-2.708.711,0,1.05.508,1.05,1.016s-.373,1.049-1.05,1.049c-1.76,0-2.199,1.049-2.233,1.083,1.286,1.286,2.064,3.08,2.064,5.077,0,3.926-3.113,7.107-6.972,7.107-1.557,0-2.979-.508-4.129-1.388-1.286,0-2.403.778-2.403,2.2,0,1.117.948,2.234,2.471,2.234,3.012,0,4.196-1.354,7.818-1.354,4.095,0,5.651,2.2,5.651,3.993ZM1838.327,442.815c0,3.587,2.03,6.532,4.535,6.532s4.535-2.944,4.535-6.532c0-3.622-2.031-6.634-4.535-6.634s-4.535,3.012-4.535,6.634Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1753.233,488.063c0,2.199,1.557,2.877,1.996,2.877v.541h-4.434v-1.76c-.88.541-3.113,1.726-5.753,1.726-3.216,0-6.024-1.388-6.024-3.485,0-2.438,2.877-3.114,5.381-3.96,2.064-.644,4.434-1.455,6.228-3.283-.338-2.233-1.388-3.926-4.265-3.926-1.827,0-3.485,1.32-3.485,3.689,0,.812-.644,1.218-1.286,1.218-.609,0-1.252-.406-1.252-1.218,0-1.896,2.605-4.23,6.261-4.23,3.824,0,6.634,2.098,6.634,7.344v4.468ZM1750.795,483.595c0-.744,0-1.489-.067-2.2-1.861,1.727-4.129,2.471-6.159,3.147-2.505.812-3.114,2.031-3.114,3.419,0,1.692,1.727,2.809,4.027,2.809,2.81,0,4.84-1.354,5.313-1.692v-5.482Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1755.804,477.875s3.283-1.624,3.655-1.624c.778,0,.778.643.778,1.624v.169s1.726-1.793,4.569-1.793c3.926,0,7.106,3.418,7.106,7.614s-3.181,7.615-7.106,7.615c-3.013,0-4.569-1.794-4.569-1.794v7.784c0,2.2,1.557,2.877,2.03,2.877v.541h-6.464v-.541c.474,0,1.997-.677,1.997-2.877v-17.328c0-1.929-1.76-1.963-1.997-1.963v-.305ZM1760.238,488.874s1.658,2.065,4.265,2.065c3.215,0,4.94-3.25,4.94-7.107,0-3.893-1.691-7.04-4.907-7.04-2.605,0-4.298,2.064-4.298,2.064v10.018Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1773.03,477.875s3.283-1.624,3.655-1.624c.778,0,.778.643.778,1.624v.169s1.726-1.793,4.569-1.793c3.926,0,7.106,3.418,7.106,7.614s-3.181,7.615-7.106,7.615c-3.013,0-4.569-1.794-4.569-1.794v7.784c0,2.2,1.557,2.877,2.03,2.877v.541h-6.464v-.541c.474,0,1.997-.677,1.997-2.877v-17.328c0-1.929-1.76-1.963-1.997-1.963v-.305ZM1777.463,488.874s1.658,2.065,4.265,2.065c3.215,0,4.94-3.25,4.94-7.107,0-3.893-1.691-7.04-4.907-7.04-2.605,0-4.298,2.064-4.298,2.064v10.018Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1809.241,489.687s-1.557,1.794-4.569,1.794c-3.926,0-7.073-3.418-7.073-7.615s3.147-7.614,7.073-7.614c2.843,0,4.569,1.793,4.569,1.793v-7.276c0-1.963-1.76-1.997-1.997-1.997v-.338s3.283-1.591,3.655-1.591c.778,0,.778.609.778,1.591v19.63c0,2.199,1.523,2.877,1.997,2.877v.541h-4.434v-1.794ZM1809.241,488.874v-10.018s-1.692-2.064-4.299-2.064c-3.215,0-4.907,3.147-4.907,7.04,0,3.857,1.727,7.107,4.941,7.107,2.606,0,4.265-2.065,4.265-2.065Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1817.261,483.324v.541c0,3.858,2.369,6.837,5.246,6.837,2.91,0,4.637-2.166,4.637-2.166l.439.305c-1.286,1.591-3.554,2.64-5.686,2.64-3.926,0-7.107-3.418-7.107-7.615s3.182-7.614,7.107-7.614c3.723,0,6.803,3.113,7.039,7.073h-11.676ZM1817.295,482.783h9.171c-.338-3.351-2.268-5.99-4.568-5.99-2.335,0-4.265,2.606-4.603,5.99Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1845.079,476.792c-1.422,0-2.505,2.877-2.505,2.877l-5.279,11.812h-.575l-5.279-11.812s-1.083-2.877-2.505-2.877v-.541h6.464v.541c-.372,0-1.421.812-1.421,1.997,0,.271.067.575.203.88l3.892,8.731,4.4-9.814c.169-.406.236-.744.236-.981,0-.711-.609-.812-.947-.812v-.541h3.316v.541Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                  </g>
                  <g>
                    <path
                      d="M1763.766,768.708c0,4.216-3.195,7.649-7.105,7.649-2.958,0-3.876-1.496-5.134-1.496-.951,0-1.903,1.496-1.903,1.496v-20.806c0-1.972-1.768-2.006-2.006-2.006v-.34s3.298-1.599,3.672-1.599c.781,0,.781.612.781,1.599v9.654s1.769-1.802,4.59-1.802c3.91,0,7.105,3.434,7.105,7.649ZM1761.318,768.674c0-3.909-1.733-7.071-4.963-7.071-2.584,0-4.284,2.074-4.284,2.074v10.062s1.632,2.074,4.284,2.074c3.195,0,4.963-3.264,4.963-7.14Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1767.949,768.164v.544c0,3.876,2.38,6.867,5.27,6.867,2.924,0,4.658-2.176,4.658-2.176l.441.307c-1.292,1.598-3.569,2.651-5.711,2.651-3.944,0-7.14-3.434-7.14-7.649s3.195-7.649,7.14-7.649c3.739,0,6.833,3.128,7.071,7.105h-11.729ZM1767.983,767.62h9.213c-.34-3.365-2.277-6.018-4.589-6.018-2.346,0-4.284,2.618-4.624,6.018Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1798.48,781.083c0,2.073-1.666,4.726-8.602,4.726-6.426,0-8.329-2.516-8.329-4.046,0-1.054.714-1.529,1.428-1.529s1.428.476,1.428,1.325c0,2.006,1.632,3.706,5.168,3.706,5.235,0,6.323-2.618,6.323-4.352,0-1.768-1.496-3.264-3.4-3.264-3.127,0-4.759,1.326-7.853,1.326-2.346,0-3.842-1.055-3.842-2.788,0-1.598,1.632-2.584,3.399-2.788-1.359-1.292-2.176-3.127-2.176-5.167,0-3.978,3.128-7.174,7.004-7.174,1.665,0,3.229.612,4.419,1.632,0-.067.748-2.72,2.754-2.72.714,0,1.054.511,1.054,1.021s-.374,1.054-1.054,1.054c-1.768,0-2.21,1.054-2.244,1.088,1.292,1.292,2.074,3.094,2.074,5.1,0,3.943-3.128,7.139-7.003,7.139-1.564,0-2.992-.51-4.148-1.394-1.291,0-2.413.782-2.413,2.21,0,1.122.952,2.244,2.481,2.244,3.025,0,4.216-1.36,7.854-1.36,4.113,0,5.678,2.21,5.678,4.012ZM1784.473,768.267c0,3.604,2.04,6.561,4.556,6.561s4.555-2.957,4.555-6.561c0-3.638-2.039-6.664-4.555-6.664s-4.556,3.026-4.556,6.664Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1812.759,772.924c0,2.21,1.564,2.89,2.006,2.89v.544h-4.453v-1.768c-.884.544-3.128,1.733-5.779,1.733-3.23,0-6.052-1.394-6.052-3.501,0-2.448,2.89-3.128,5.405-3.979,2.074-.646,4.454-1.461,6.256-3.297-.34-2.244-1.395-3.944-4.284-3.944-1.836,0-3.501,1.326-3.501,3.706,0,.815-.646,1.224-1.292,1.224-.612,0-1.258-.408-1.258-1.224,0-1.904,2.617-4.25,6.289-4.25,3.842,0,6.663,2.108,6.663,7.378v4.487ZM1810.312,768.437c0-.748,0-1.496-.068-2.21-1.87,1.733-4.147,2.481-6.188,3.161-2.516.816-3.127,2.04-3.127,3.435,0,1.699,1.733,2.821,4.045,2.821,2.822,0,4.861-1.36,5.338-1.7v-5.507Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1820.92,762.827c.034,0,1.666-1.769,4.59-1.769,2.89,0,5.983,1.734,5.983,5.678v6.188c0,2.21,1.53,2.89,2.006,2.89v.544h-6.459v-.544c.441,0,2.006-.68,2.006-2.89v-6.731c0-3.06-1.36-4.59-3.842-4.59-2.686,0-4.25,1.904-4.284,1.938v9.383c0,2.21,1.53,2.89,2.006,2.89v.544h-6.459v-.544c.476,0,2.006-.68,2.006-2.89v-7.888c0-2.005-1.768-2.039-2.006-2.039v-.307s3.298-1.632,3.672-1.632c.781,0,.781.646.781,1.632v.137Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1733.456,816.614c.476,0,2.006-.68,2.006-2.89v-17.44c0-1.903-1.768-1.938-2.006-1.938v-.34s3.298-1.598,3.672-1.598c.815,0,.815.611.815,1.598v19.718c0,2.21,1.53,2.89,2.006,2.89v.544h-6.493v-.544Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1743.554,808.964v.544c0,3.876,2.38,6.867,5.27,6.867,2.924,0,4.658-2.176,4.658-2.176l.441.307c-1.292,1.598-3.569,2.651-5.711,2.651-3.944,0-7.14-3.434-7.14-7.649s3.195-7.649,7.14-7.649c3.739,0,6.833,3.128,7.071,7.105h-11.729ZM1743.588,808.42h9.213c-.34-3.365-2.277-6.018-4.589-6.018-2.346,0-4.284,2.618-4.624,6.018Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1770.684,813.724c0,2.21,1.564,2.89,2.006,2.89v.544h-4.453v-1.768c-.884.544-3.128,1.733-5.779,1.733-3.23,0-6.052-1.394-6.052-3.502,0-2.447,2.89-3.127,5.405-3.978,2.074-.646,4.454-1.462,6.256-3.297-.34-2.244-1.395-3.944-4.284-3.944-1.836,0-3.501,1.326-3.501,3.706,0,.815-.646,1.224-1.292,1.224-.612,0-1.258-.408-1.258-1.224,0-1.904,2.617-4.25,6.289-4.25,3.842,0,6.663,2.108,6.663,7.378v4.487ZM1768.237,809.237c0-.748,0-1.496-.068-2.21-1.87,1.733-4.147,2.481-6.188,3.161-2.516.816-3.128,2.04-3.128,3.434,0,1.7,1.734,2.822,4.046,2.822,2.822,0,4.861-1.36,5.338-1.7v-5.507Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1778.879,804.748c.272-.373,2.176-2.89,5.678-2.89,2.142,0,4.861,1.122,4.861,3.196,0,.781-.612,1.155-1.189,1.155-.646,0-1.258-.407-1.258-1.155,0-2.006-1.36-2.652-2.72-2.652-3.23,0-5.1,2.822-5.372,3.264v8.058c0,2.21,1.53,2.89,2.006,2.89v.544h-6.493v-.544c.476,0,2.006-.68,2.006-2.89v-7.955c0-1.938-1.768-1.972-2.006-1.972v-.307s3.298-1.632,3.672-1.632c.815,0,.815.646.815,1.632v1.258Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1795.573,803.627c.034,0,1.666-1.769,4.59-1.769,2.89,0,5.983,1.734,5.983,5.678v6.188c0,2.21,1.53,2.89,2.006,2.89v.544h-6.459v-.544c.441,0,2.006-.68,2.006-2.89v-6.731c0-3.06-1.36-4.59-3.842-4.59-2.686,0-4.25,1.904-4.284,1.938v9.383c0,2.21,1.53,2.89,2.006,2.89v.544h-6.459v-.544c.476,0,2.006-.68,2.006-2.89v-7.888c0-2.006-1.768-2.039-2.006-2.039v-.307s3.298-1.632,3.672-1.632c.781,0,.781.646.781,1.632v.137Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1809.853,816.614c.476,0,2.006-.68,2.006-2.89v-7.955c0-1.938-1.768-1.972-2.006-1.972v-.307s3.298-1.632,3.672-1.632c.815,0,.815.646.815,1.632v10.233c0,2.21,1.53,2.89,2.006,2.89v.544h-6.493v-.544ZM1813.082,798.969c-.815,0-1.496-.68-1.496-1.495,0-.851.681-1.53,1.496-1.53.851,0,1.53.68,1.53,1.53,0,.815-.68,1.495-1.53,1.495Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1822.5,803.627c.034,0,1.666-1.769,4.59-1.769,2.89,0,5.983,1.734,5.983,5.678v6.188c0,2.21,1.53,2.89,2.006,2.89v.544h-6.459v-.544c.441,0,2.006-.68,2.006-2.89v-6.731c0-3.06-1.36-4.59-3.842-4.59-2.686,0-4.25,1.904-4.284,1.938v9.383c0,2.21,1.53,2.89,2.006,2.89v.544h-6.459v-.544c.476,0,2.006-.68,2.006-2.89v-7.888c0-2.006-1.768-2.039-2.006-2.039v-.307s3.298-1.632,3.672-1.632c.781,0,.781.646.781,1.632v.137Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1854.458,821.883c0,2.074-1.665,4.726-8.601,4.726-6.426,0-8.329-2.516-8.329-4.046,0-1.054.714-1.529,1.428-1.529s1.428.476,1.428,1.325c0,2.006,1.632,3.706,5.168,3.706,5.235,0,6.323-2.618,6.323-4.352,0-1.768-1.496-3.264-3.399-3.264-3.128,0-4.76,1.326-7.854,1.326-2.346,0-3.842-1.055-3.842-2.788,0-1.598,1.632-2.584,3.399-2.788-1.359-1.292-2.176-3.127-2.176-5.167,0-3.978,3.128-7.174,7.004-7.174,1.665,0,3.229.612,4.419,1.632,0-.067.748-2.72,2.754-2.72.714,0,1.054.511,1.054,1.021s-.374,1.054-1.054,1.054c-1.768,0-2.21,1.054-2.244,1.088,1.292,1.292,2.074,3.094,2.074,5.1,0,3.943-3.128,7.139-7.003,7.139-1.564,0-2.992-.51-4.147-1.394-1.292,0-2.414.782-2.414,2.21,0,1.122.952,2.244,2.481,2.244,3.025,0,4.216-1.36,7.854-1.36,4.113,0,5.677,2.21,5.677,4.012ZM1840.452,809.067c0,3.604,2.039,6.561,4.556,6.561s4.556-2.957,4.556-6.561c0-3.638-2.04-6.664-4.556-6.664s-4.556,3.026-4.556,6.664Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1738.693,844.29s3.298-1.632,3.672-1.632c.781,0,.781.646.781,1.632v.17s1.734-1.802,4.59-1.802c3.943,0,7.14,3.434,7.14,7.649s-3.196,7.649-7.14,7.649c-3.025,0-4.59-1.802-4.59-1.802v7.819c0,2.21,1.564,2.89,2.04,2.89v.544h-6.493v-.544c.476,0,2.006-.68,2.006-2.89v-17.406c0-1.938-1.768-1.972-2.006-1.972v-.307ZM1743.146,855.339s1.666,2.074,4.284,2.074c3.229,0,4.963-3.264,4.963-7.14,0-3.909-1.699-7.071-4.929-7.071-2.618,0-4.318,2.074-4.318,2.074v10.062Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1760.486,845.548c.272-.374,2.176-2.89,5.678-2.89,2.142,0,4.861,1.122,4.861,3.196,0,.781-.611,1.155-1.189,1.155-.646,0-1.258-.407-1.258-1.155,0-2.006-1.36-2.652-2.72-2.652-3.23,0-5.1,2.822-5.372,3.264v8.058c0,2.21,1.53,2.89,2.006,2.89v.544h-6.493v-.544c.476,0,2.006-.68,2.006-2.89v-7.955c0-1.938-1.768-1.972-2.006-1.972v-.307s3.298-1.632,3.672-1.632c.815,0,.815.646.815,1.632v1.258Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1779.661,842.658c4.113,0,7.479,3.434,7.479,7.649s-3.365,7.649-7.479,7.649c-4.148,0-7.514-3.434-7.514-7.649s3.365-7.649,7.514-7.649ZM1779.661,843.202c-2.788,0-5.032,3.264-5.032,7.14,0,3.909,2.244,7.071,5.032,7.071,2.753,0,5.031-3.162,5.031-7.071,0-3.876-2.278-7.14-5.031-7.14Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1800.536,856.156s-1.563,1.802-4.589,1.802c-3.943,0-7.105-3.434-7.105-7.649s3.162-7.649,7.105-7.649c2.855,0,4.589,1.802,4.589,1.802v-7.309c0-1.972-1.768-2.006-2.005-2.006v-.34s3.297-1.599,3.671-1.599c.782,0,.782.612.782,1.599v19.718c0,2.21,1.53,2.89,2.006,2.89v.544h-4.454v-1.802ZM1800.536,855.339v-10.062s-1.699-2.074-4.317-2.074c-3.229,0-4.929,3.162-4.929,7.071,0,3.876,1.733,7.14,4.963,7.14,2.618,0,4.283-2.074,4.283-2.074Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1818.149,856.19c-.034,0-1.666,1.768-4.59,1.768-2.89,0-5.983-1.733-5.983-5.678v-5.644c0-2.005-1.768-2.039-2.006-2.039v-.307s3.298-1.632,3.672-1.632c.781,0,.781.646.781,1.632v8.533c0,3.06,1.36,4.59,3.842,4.59,2.686,0,4.25-1.903,4.284-1.938v-8.84c0-2.005-1.769-2.039-2.006-2.039v-.307s3.298-1.632,3.671-1.632c.782,0,.782.646.782,1.632v9.689c0,2.006,1.768,2.04,2.006,2.04v.306s-3.298,1.632-3.672,1.632c-.781,0-.781-.646-.781-1.632v-.136Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1848.747,856.36l.477.271s-1.088,1.326-3.74,1.326-4.556-1.428-4.556-4.147v-10.573h-2.006v-.544h2.006v-2.21c0-1.972-1.768-1.972-2.006-1.972-2.209,0-3.331.714-3.331,2.108,0,1.768,2.073,4.215,2.073,6.289,0,.85-.611,1.258-1.258,1.258s-1.292-.442-1.292-1.258c0-2.38-1.462-3.672-3.297-3.672-2.856,0-5.032,2.958-5.032,7.071,0,3.91,2.38,6.901,5.27,6.901,2.958,0,4.658-2.176,4.658-2.176l.476.272c-1.326,1.632-3.604,2.651-5.745,2.651-3.944,0-7.14-3.399-7.14-7.649,0-4.521,3.229-7.615,7.275-7.615,2.04,0,3.705.714,4.793,1.7-.611-1.292-1.325-2.618-1.325-3.773,0-1.225.68-2.652,3.875-2.652,0,0,3.298-1.428,3.672-1.428.782,0,.782.612.782,1.598v4.556h4.487v.544h-4.487v10.981c0,2.209,1.359,3.195,2.72,3.195,1.768,0,2.651-1.054,2.651-1.054Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1756.2,883.459c2.924,0,5.983,1.734,5.983,5.678v6.188c0,2.21,1.529,2.89,2.006,2.89v.544h-6.46v-.544c.477,0,2.006-.68,2.006-2.89v-6.731c0-3.06-1.325-4.59-3.842-4.59-2.346,0-4.011,1.564-4.827,2.517.34.747.544,1.598.544,2.617v6.188c0,2.21,1.564,2.89,2.006,2.89v.544h-6.459v-.544c.476,0,2.006-.68,2.006-2.89v-6.731c0-3.06-1.326-4.59-3.808-4.59-2.72,0-4.284,1.904-4.318,1.938v9.383c0,2.21,1.564,2.89,2.04,2.89v.544h-6.493v-.544c.476,0,2.006-.68,2.006-2.89v-7.955c0-1.938-1.768-1.972-2.006-1.972v-.307s3.298-1.632,3.672-1.632c.781,0,.781.646.781,1.632v.137c.034,0,1.666-1.769,4.59-1.769,2.006,0,4.046.816,5.168,2.584.68-.815,2.481-2.584,5.405-2.584Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1779.593,895.324c0,2.21,1.564,2.89,2.006,2.89v.544h-4.453v-1.768c-.884.544-3.128,1.733-5.779,1.733-3.23,0-6.052-1.394-6.052-3.501,0-2.448,2.89-3.128,5.405-3.979,2.074-.646,4.454-1.461,6.256-3.297-.34-2.244-1.395-3.944-4.284-3.944-1.836,0-3.501,1.326-3.501,3.706,0,.815-.646,1.224-1.292,1.224-.612,0-1.258-.408-1.258-1.224,0-1.904,2.617-4.25,6.289-4.25,3.842,0,6.663,2.108,6.663,7.378v4.487ZM1777.146,890.837c0-.748,0-1.496-.068-2.21-1.87,1.733-4.147,2.481-6.188,3.161-2.516.816-3.127,2.04-3.127,3.435,0,1.699,1.733,2.821,4.045,2.821,2.822,0,4.861-1.36,5.338-1.7v-5.507Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1787.753,885.228c.034,0,1.666-1.769,4.59-1.769,2.89,0,5.983,1.734,5.983,5.678v6.188c0,2.21,1.53,2.89,2.006,2.89v.544h-6.459v-.544c.441,0,2.006-.68,2.006-2.89v-6.731c0-3.06-1.36-4.59-3.842-4.59-2.686,0-4.25,1.904-4.284,1.938v9.383c0,2.21,1.53,2.89,2.006,2.89v.544h-6.459v-.544c.476,0,2.006-.68,2.006-2.89v-7.888c0-2.005-1.768-2.039-2.006-2.039v-.307s3.298-1.632,3.672-1.632c.781,0,.781.646.781,1.632v.137Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1815.735,895.324c0,2.21,1.564,2.89,2.006,2.89v.544h-4.453v-1.768c-.884.544-3.128,1.733-5.779,1.733-3.23,0-6.052-1.394-6.052-3.501,0-2.448,2.89-3.128,5.405-3.979,2.074-.646,4.454-1.461,6.256-3.297-.34-2.244-1.395-3.944-4.284-3.944-1.836,0-3.501,1.326-3.501,3.706,0,.815-.646,1.224-1.292,1.224-.612,0-1.258-.408-1.258-1.224,0-1.904,2.617-4.25,6.289-4.25,3.842,0,6.663,2.108,6.663,7.378v4.487ZM1813.287,890.837c0-.748,0-1.496-.068-2.21-1.87,1.733-4.147,2.481-6.188,3.161-2.516.816-3.128,2.04-3.128,3.435,0,1.699,1.734,2.821,4.046,2.821,2.822,0,4.861-1.36,5.338-1.7v-5.507Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1837.12,903.484c0,2.073-1.666,4.726-8.602,4.726-6.426,0-8.329-2.516-8.329-4.046,0-1.054.714-1.529,1.428-1.529s1.428.476,1.428,1.325c0,2.006,1.632,3.706,5.168,3.706,5.235,0,6.323-2.618,6.323-4.352,0-1.768-1.496-3.264-3.4-3.264-3.127,0-4.759,1.326-7.853,1.326-2.346,0-3.842-1.055-3.842-2.788,0-1.598,1.632-2.584,3.399-2.788-1.359-1.292-2.176-3.127-2.176-5.167,0-3.978,3.128-7.174,7.004-7.174,1.665,0,3.229.612,4.419,1.632,0-.067.748-2.72,2.754-2.72.714,0,1.054.511,1.054,1.021s-.374,1.054-1.054,1.054c-1.768,0-2.21,1.054-2.244,1.088,1.292,1.292,2.074,3.094,2.074,5.1,0,3.943-3.128,7.139-7.003,7.139-1.564,0-2.992-.51-4.148-1.394-1.291,0-2.413.782-2.413,2.21,0,1.122.952,2.244,2.481,2.244,3.025,0,4.216-1.36,7.854-1.36,4.113,0,5.678,2.21,5.678,4.012ZM1823.114,890.667c0,3.604,2.04,6.561,4.556,6.561s4.555-2.957,4.555-6.561c0-3.638-2.039-6.664-4.555-6.664s-4.556,3.026-4.556,6.664Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1839.603,890.565v.544c0,3.876,2.38,6.867,5.27,6.867,2.924,0,4.658-2.176,4.658-2.176l.441.307c-1.292,1.598-3.569,2.651-5.711,2.651-3.944,0-7.14-3.434-7.14-7.649s3.195-7.649,7.14-7.649c3.739,0,6.833,3.128,7.071,7.105h-11.729ZM1839.637,890.021h9.213c-.34-3.365-2.277-6.018-4.589-6.018-2.346,0-4.284,2.618-4.624,6.018Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1777.586,924.259c2.924,0,5.983,1.734,5.983,5.678v6.188c0,2.21,1.529,2.89,2.006,2.89v.544h-6.46v-.544c.477,0,2.006-.68,2.006-2.89v-6.731c0-3.06-1.325-4.59-3.841-4.59-2.347,0-4.012,1.564-4.828,2.516.34.748.544,1.599.544,2.618v6.188c0,2.21,1.563,2.89,2.006,2.89v.544h-6.459v-.544c.476,0,2.006-.68,2.006-2.89v-6.731c0-3.06-1.326-4.59-3.808-4.59-2.72,0-4.284,1.904-4.318,1.938v9.383c0,2.21,1.564,2.89,2.04,2.89v.544h-6.493v-.544c.476,0,2.006-.68,2.006-2.89v-7.955c0-1.938-1.768-1.972-2.006-1.972v-.307s3.298-1.632,3.672-1.632c.781,0,.781.646.781,1.632v.137c.034,0,1.666-1.769,4.59-1.769,2.006,0,4.046.816,5.168,2.584.68-.815,2.481-2.584,5.405-2.584Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1789.182,931.365v.544c0,3.876,2.38,6.867,5.27,6.867,2.924,0,4.658-2.176,4.658-2.176l.441.307c-1.292,1.598-3.569,2.651-5.711,2.651-3.944,0-7.14-3.434-7.14-7.649s3.195-7.649,7.14-7.649c3.739,0,6.833,3.128,7.071,7.105h-11.729ZM1789.216,930.821h9.213c-.34-3.365-2.277-6.018-4.589-6.018-2.346,0-4.284,2.618-4.624,6.018Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1807.065,926.028c.034,0,1.666-1.769,4.59-1.769,2.89,0,5.983,1.734,5.983,5.678v6.188c0,2.21,1.53,2.89,2.006,2.89v.544h-6.459v-.544c.441,0,2.006-.68,2.006-2.89v-6.731c0-3.06-1.36-4.59-3.842-4.59-2.686,0-4.25,1.904-4.284,1.938v9.383c0,2.21,1.53,2.89,2.006,2.89v.544h-6.459v-.544c.476,0,2.006-.68,2.006-2.89v-7.888c0-2.006-1.768-2.039-2.006-2.039v-.307s3.298-1.632,3.672-1.632c.781,0,.781.646.781,1.632v.137Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                    <path
                      d="M1821.652,935.376v-10.573h-2.006v-.544h2.006v-2.176c0-1.972-1.768-2.006-2.006-2.006v-.34s3.298-1.598,3.672-1.598c.781,0,.781.612.781,1.598v4.521h4.488v.544h-4.488v10.981c0,2.243,1.36,3.229,2.72,3.229,1.734,0,2.652-1.088,2.652-1.088l.476.271s-1.088,1.36-3.739,1.36-4.556-1.462-4.556-4.182Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                      }}
                    />
                  </g>
                </g>
                <g id="s" data-name="thickertext">
                  <g>
                    <path
                      d="M503.472,1119.104v3.746h7.976v.967h-7.976v20.303c0,3.807,2.779,4.955,3.565,4.955v.967h-11.481v-.967c.847,0,3.565-1.148,3.565-4.955v-20.303h-3.565v-.967h3.565v-3.746c0-8.641,4.773-13.052,11.541-13.052,6.526,0,10.816,4.108,10.816,7.493,0,1.51-1.148,2.235-2.236,2.235-1.147,0-2.296-.726-2.296-2.235,0-4.23-2.599-6.526-5.861-6.526-7.13,0-7.613,6.345-7.613,12.085Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M527.825,1122.85c7.312,0,13.293,6.104,13.293,13.596s-5.981,13.596-13.293,13.596c-7.372,0-13.354-6.103-13.354-13.596s5.982-13.596,13.354-13.596ZM527.825,1123.817c-4.955,0-8.943,5.801-8.943,12.689,0,6.949,3.988,12.568,8.943,12.568,4.895,0,8.942-5.619,8.942-12.568,0-6.889-4.048-12.689-8.942-12.689Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M565.472,1146.899c-.061,0-2.961,3.143-8.157,3.143-5.137,0-10.635-3.082-10.635-10.091v-10.03c0-3.565-3.143-3.626-3.565-3.626v-.544s5.861-2.9,6.526-2.9c1.39,0,1.39,1.148,1.39,2.9v15.167c0,5.438,2.417,8.157,6.827,8.157,4.774,0,7.554-3.384,7.614-3.444v-15.71c0-3.565-3.143-3.626-3.565-3.626v-.544s5.861-2.9,6.526-2.9c1.39,0,1.39,1.148,1.39,2.9v17.222c0,3.564,3.142,3.625,3.564,3.625v.544s-5.861,2.9-6.525,2.9c-1.39,0-1.39-1.148-1.39-2.9v-.242Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M584.328,1125.992c.061,0,2.961-3.143,8.157-3.143,5.136,0,10.635,3.082,10.635,10.091v10.998c0,3.927,2.719,5.136,3.564,5.136v.967h-11.48v-.967c.786,0,3.565-1.209,3.565-5.136v-11.965c0-5.438-2.417-8.157-6.828-8.157-4.773,0-7.553,3.384-7.613,3.444v16.678c0,3.927,2.719,5.136,3.564,5.136v.967h-11.48v-.967c.846,0,3.565-1.209,3.565-5.136v-14.019c0-3.565-3.143-3.626-3.565-3.626v-.544s5.861-2.9,6.525-2.9c1.391,0,1.391,1.148,1.391,2.9v.242Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M629.468,1146.839s-2.779,3.202-8.157,3.202c-7.01,0-12.629-6.103-12.629-13.596s5.619-13.596,12.629-13.596c5.075,0,8.157,3.203,8.157,3.203v-12.992c0-3.504-3.142-3.564-3.565-3.564v-.604s5.861-2.84,6.526-2.84c1.39,0,1.39,1.088,1.39,2.84v35.047c0,3.927,2.719,5.136,3.565,5.136v.967h-7.916v-3.202ZM629.468,1145.389v-17.886s-3.021-3.687-7.674-3.687c-5.74,0-8.762,5.62-8.762,12.568,0,6.889,3.082,12.689,8.822,12.689,4.652,0,7.613-3.686,7.613-3.686Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M643.792,1135.479v.967c0,6.889,4.229,12.206,9.366,12.206,5.196,0,8.277-3.867,8.277-3.867l.786.544c-2.296,2.84-6.345,4.713-10.151,4.713-7.01,0-12.689-6.103-12.689-13.596s5.68-13.596,12.689-13.596c6.646,0,12.146,5.56,12.568,12.629h-20.847ZM643.853,1134.512h16.375c-.604-5.982-4.049-10.695-8.157-10.695-4.17,0-7.613,4.653-8.218,10.695Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M688.448,1146.839s-2.779,3.202-8.157,3.202c-7.01,0-12.629-6.103-12.629-13.596s5.619-13.596,12.629-13.596c5.075,0,8.157,3.203,8.157,3.203v-12.992c0-3.504-3.143-3.564-3.565-3.564v-.604s5.861-2.84,6.526-2.84c1.39,0,1.39,1.088,1.39,2.84v35.047c0,3.927,2.719,5.136,3.565,5.136v.967h-7.916v-3.202ZM688.448,1145.389v-17.886s-3.021-3.687-7.674-3.687c-5.74,0-8.762,5.62-8.762,12.568,0,6.889,3.082,12.689,8.822,12.689,4.652,0,7.613-3.686,7.613-3.686Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M508.035,1200.927c0-12.871,9.124-21.632,20.545-21.632,10.393,0,17.281,6.586,17.281,11.964,0,1.873-1.148,2.779-2.297,2.779-1.208,0-2.416-.967-2.416-2.779,0-7.19-6.647-10.938-11.844-10.938-8.097,0-16.496,7.07-16.496,20.605,0,12.206,8.822,19.88,17.04,19.88,8.701,0,13.776-6.225,13.776-6.225l.847.484c-3.687,4.592-10.151,7.492-16.255,7.492-11.118,0-20.182-9.668-20.182-21.632Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M552.271,1207.996v.967c0,6.889,4.229,12.206,9.366,12.206,5.196,0,8.277-3.867,8.277-3.867l.786.544c-2.296,2.84-6.345,4.713-10.151,4.713-7.01,0-12.689-6.103-12.689-13.596s5.68-13.596,12.689-13.596c6.646,0,12.146,5.56,12.568,12.629h-20.847ZM552.331,1207.03h16.375c-.604-5.982-4.049-10.695-8.157-10.695-4.17,0-7.613,4.653-8.218,10.695Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M584.116,1200.504c.484-.665,3.867-5.137,10.092-5.137,3.807,0,8.641,1.994,8.641,5.68,0,1.391-1.088,2.055-2.115,2.055-1.148,0-2.235-.725-2.235-2.055,0-3.564-2.417-4.713-4.834-4.713-5.74,0-9.063,5.016-9.548,5.801v14.321c0,3.927,2.72,5.136,3.565,5.136v.967h-11.541v-.967c.846,0,3.565-1.209,3.565-5.136v-14.14c0-3.444-3.143-3.505-3.565-3.505v-.544s5.861-2.9,6.526-2.9c1.449,0,1.449,1.148,1.449,2.9v2.236Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M605.871,1221.592c.846,0,3.565-1.209,3.565-5.136v-14.14c0-3.444-3.143-3.505-3.565-3.505v-.544s5.861-2.9,6.525-2.9c1.451,0,1.451,1.148,1.451,2.9v18.188c0,3.927,2.719,5.136,3.564,5.136v.967h-11.541v-.967ZM611.612,1190.232c-1.45,0-2.658-1.209-2.658-2.659,0-1.51,1.208-2.719,2.658-2.719,1.511,0,2.719,1.209,2.719,2.719,0,1.45-1.208,2.659-2.719,2.659Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M643.518,1215.973c0,4.834-6.224,6.586-11.843,6.586-6.043,0-12.024-3.202-12.024-6.224,0-1.148,1.087-1.753,2.175-1.753s2.235.604,2.235,1.813c0,2.538,2.297,5.136,8.158,5.136,4.592,0,6.707-1.691,6.707-4.29,0-5.56-19.518-7.312-19.518-15.046,0-5.196,6.525-6.828,11.42-6.828,5.016,0,11.481,3.203,11.481,6.224,0,1.148-1.148,1.692-2.236,1.692-1.147,0-2.235-.544-2.235-1.752,0-2.538-2.417-5.197-7.734-5.197-3.746,0-6.104,1.934-6.104,4.532,0,5.378,19.518,7.433,19.518,15.106Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M650.952,1207.996v.967c0,6.889,4.229,12.206,9.366,12.206,5.196,0,8.277-3.867,8.277-3.867l.786.544c-2.296,2.84-6.345,4.713-10.151,4.713-7.01,0-12.689-6.103-12.689-13.596s5.68-13.596,12.689-13.596c6.646,0,12.146,5.56,12.568,12.629h-20.847ZM651.013,1207.03h16.375c-.604-5.982-4.049-10.695-8.157-10.695-4.17,0-7.613,4.653-8.218,10.695Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M462.771,1252.838c-4.592,0-7.976,4.23-7.976,4.23l1.39-5.197h35.59l1.39,5.197s-3.384-4.23-7.976-4.23h-8.822v36.195c0,3.867,2.719,5.075,3.565,5.075v.967h-11.904v-.967c.846,0,3.565-1.208,3.565-5.075v-36.195h-8.822Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M489.542,1280.513v.967c0,6.889,4.229,12.206,9.366,12.206,5.196,0,8.278-3.867,8.278-3.867l.785.544c-2.296,2.84-6.345,4.713-10.151,4.713-7.009,0-12.689-6.103-12.689-13.596s5.681-13.596,12.689-13.596c6.646,0,12.146,5.56,12.568,12.629h-20.847ZM489.603,1279.546h16.375c-.604-5.981-4.048-10.695-8.157-10.695-4.169,0-7.613,4.653-8.218,10.695Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M566.103,1289.033c0,3.867,2.779,5.075,3.626,5.075v.967h-11.542v-.967c.847,0,3.565-1.208,3.565-5.075v-12.025c0-5.438-2.356-8.097-6.828-8.097-4.773,0-7.553,3.384-7.613,3.384v16.738c0,3.867,2.719,5.075,3.564,5.075v.967h-11.48v-.967c.846,0,3.565-1.208,3.565-5.075v-30.938c0-3.264-2.779-3.385-3.444-3.385h-.121c-4.652,0-7.312,1.813-7.312,4.895,0,3.324,5.076,12.085,5.076,15.832,0,1.51-1.088,2.235-2.235,2.235s-2.297-.785-2.297-2.235c0-4.23-2.598-6.526-5.861-6.526-5.075,0-9.003,5.257-9.003,12.568,0,6.949,4.229,12.267,9.427,12.267s8.277-3.867,8.277-3.867l.786.483c-2.296,2.9-6.345,4.713-10.212,4.713-6.949,0-12.629-6.042-12.629-13.596,0-8.036,5.68-13.535,12.87-13.535,3.867,0,6.949,1.39,8.822,3.263-1.269-3.323-3.988-9.063-3.988-11.602,0-3.686,3.082-5.861,8.278-5.861,0,0,5.861-2.658,6.526-2.658,1.39,0,1.39,1.088,1.39,2.84v17.161c.061-.061,2.961-3.143,8.157-3.143s10.635,3.082,10.635,10.03v11.059Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M580.671,1271.027c.06,0,2.96-3.143,8.157-3.143,5.136,0,10.635,3.082,10.635,10.091v10.998c0,3.928,2.719,5.136,3.564,5.136v.967h-11.48v-.967c.785,0,3.565-1.208,3.565-5.136v-11.965c0-5.438-2.417-8.157-6.828-8.157-4.773,0-7.554,3.384-7.613,3.444v16.678c0,3.928,2.719,5.136,3.564,5.136v.967h-11.48v-.967c.846,0,3.565-1.208,3.565-5.136v-14.019c0-3.565-3.143-3.626-3.565-3.626v-.544s5.861-2.9,6.526-2.9c1.39,0,1.39,1.148,1.39,2.9v.242Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M618.379,1267.884c7.312,0,13.294,6.104,13.294,13.596s-5.982,13.596-13.294,13.596c-7.371,0-13.354-6.103-13.354-13.596s5.982-13.596,13.354-13.596ZM618.379,1268.851c-4.954,0-8.942,5.801-8.942,12.689,0,6.949,3.988,12.568,8.942,12.568,4.895,0,8.943-5.619,8.943-12.568,0-6.889-4.049-12.689-8.943-12.689Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M633.669,1294.109c.846,0,3.565-1.208,3.565-5.136v-30.998c0-3.384-3.143-3.444-3.565-3.444v-.604s5.861-2.84,6.526-2.84c1.449,0,1.449,1.088,1.449,2.84v35.047c0,3.928,2.72,5.136,3.565,5.136v.967h-11.541v-.967Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M660.56,1267.884c7.312,0,13.294,6.104,13.294,13.596s-5.982,13.596-13.294,13.596c-7.371,0-13.354-6.103-13.354-13.596s5.982-13.596,13.354-13.596ZM660.56,1268.851c-4.954,0-8.942,5.801-8.942,12.689,0,6.949,3.988,12.568,8.942,12.568,4.895,0,8.943-5.619,8.943-12.568,0-6.889-4.049-12.689-8.943-12.689Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M707.272,1303.475c0,3.686-2.961,8.398-15.287,8.398-11.421,0-14.805-4.471-14.805-7.19,0-1.873,1.27-2.719,2.538-2.719s2.538.846,2.538,2.356c0,3.564,2.9,6.586,9.185,6.586,9.306,0,11.239-4.652,11.239-7.734,0-3.142-2.659-5.801-6.043-5.801-5.559,0-8.459,2.356-13.958,2.356-4.169,0-6.828-1.873-6.828-4.954,0-2.84,2.9-4.593,6.042-4.955-2.416-2.296-3.867-5.56-3.867-9.185,0-7.069,5.56-12.75,12.448-12.75,2.961,0,5.74,1.088,7.854,2.9,0-.12,1.33-4.834,4.895-4.834,1.27,0,1.873.906,1.873,1.813s-.664,1.873-1.873,1.873c-3.142,0-3.928,1.873-3.987,1.934,2.296,2.296,3.686,5.498,3.686,9.063,0,7.009-5.56,12.689-12.447,12.689-2.78,0-5.317-.907-7.372-2.478-2.296,0-4.29,1.39-4.29,3.928,0,1.994,1.691,3.987,4.411,3.987,5.378,0,7.492-2.417,13.958-2.417,7.312,0,10.091,3.928,10.091,7.131ZM682.377,1280.695c0,6.405,3.625,11.662,8.097,11.662s8.097-5.257,8.097-11.662c0-6.466-3.625-11.844-8.097-11.844s-8.097,5.378-8.097,11.844Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M737.124,1267.884v.967c-2.537,0-4.471,5.137-4.471,5.137l-12.327,27.614s-4.29,10.271-8.58,10.271c-2.055,0-3.021-1.147-3.021-2.296s1.027-2.356,2.961-2.356c5.438,0,7.613-5.619,7.613-5.619l2.9-6.526-9.426-21.088s-1.873-5.137-4.472-5.137v-.967h11.541v.967c-.665,0-2.478,1.45-2.478,3.565,0,.483.061,1.027.303,1.571l6.948,15.589,7.855-17.522c.302-.726.423-1.33.423-1.753,0-1.269-1.088-1.45-1.691-1.45v-.967h5.921Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                  </g>
                  <g>
                    <path
                      d="M733.629,116.718c0,3.712-2.981,8.458-15.396,8.458-11.501,0-14.908-4.503-14.908-7.242,0-1.886,1.277-2.738,2.556-2.738s2.556.852,2.556,2.374c0,3.59,2.921,6.633,9.25,6.633,9.371,0,11.318-4.686,11.318-7.789,0-3.165-2.678-5.842-6.085-5.842-5.599,0-8.52,2.373-14.058,2.373-4.198,0-6.876-1.886-6.876-4.99,0-2.86,2.921-4.625,6.085-4.99-2.434-2.312-3.895-5.599-3.895-9.25,0-7.12,5.599-12.84,12.536-12.84,2.981,0,5.78,1.095,7.91,2.921,0-.122,1.339-4.868,4.93-4.868,1.277,0,1.886.913,1.886,1.826s-.669,1.886-1.886,1.886c-3.165,0-3.956,1.886-4.017,1.947,2.312,2.312,3.712,5.538,3.712,9.128,0,7.059-5.599,12.779-12.535,12.779-2.8,0-5.355-.913-7.424-2.495-2.312,0-4.321,1.4-4.321,3.956,0,2.008,1.704,4.016,4.442,4.016,5.416,0,7.546-2.434,14.057-2.434,7.363,0,10.162,3.956,10.162,7.181ZM708.559,93.776c0,6.451,3.65,11.745,8.154,11.745s8.154-5.294,8.154-11.745c0-6.511-3.651-11.927-8.154-11.927s-8.154,5.416-8.154,11.927Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M741.665,86.048c.487-.669,3.895-5.172,10.162-5.172,3.834,0,8.702,2.008,8.702,5.72,0,1.399-1.096,2.069-2.13,2.069-1.156,0-2.251-.73-2.251-2.069,0-3.59-2.435-4.747-4.868-4.747-5.781,0-9.128,5.051-9.615,5.842v14.422c0,3.956,2.738,5.172,3.591,5.172v.974h-11.623v-.974c.852,0,3.59-1.217,3.59-5.172v-14.239c0-3.469-3.164-3.53-3.59-3.53v-.547s5.902-2.921,6.572-2.921c1.46,0,1.46,1.156,1.46,2.921v2.251Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M787.125,102.113c0,3.956,2.8,5.172,3.591,5.172v.974h-7.972v-3.164c-1.582.974-5.599,3.103-10.345,3.103-5.781,0-10.832-2.495-10.832-6.268,0-4.381,5.173-5.598,9.676-7.12,3.712-1.156,7.972-2.617,11.196-5.903-.608-4.016-2.495-7.059-7.667-7.059-3.286,0-6.268,2.373-6.268,6.633,0,1.46-1.156,2.19-2.312,2.19-1.096,0-2.252-.73-2.252-2.19,0-3.408,4.686-7.606,11.258-7.606,6.876,0,11.927,3.773,11.927,13.205v8.033ZM782.744,94.08c0-1.338,0-2.677-.122-3.955-3.347,3.104-7.424,4.442-11.075,5.659-4.503,1.46-5.598,3.651-5.598,6.146,0,3.042,3.103,5.051,7.241,5.051,5.051,0,8.701-2.434,9.554-3.042v-9.858Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M812.625,105.034s-2.8,3.225-8.216,3.225c-7.059,0-12.718-6.146-12.718-13.692s5.659-13.691,12.718-13.691c5.112,0,8.216,3.225,8.216,3.225v-13.083c0-3.53-3.165-3.59-3.591-3.59v-.609s5.902-2.86,6.572-2.86c1.399,0,1.399,1.095,1.399,2.86v35.294c0,3.956,2.738,5.172,3.59,5.172v.974h-7.971v-3.225ZM812.625,103.574v-18.012s-3.043-3.712-7.729-3.712c-5.781,0-8.824,5.659-8.824,12.657,0,6.937,3.104,12.779,8.885,12.779,4.686,0,7.668-3.712,7.668-3.712Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M844.151,105.095c-.062,0-2.982,3.164-8.216,3.164-5.172,0-10.71-3.104-10.71-10.162v-10.102c0-3.59-3.164-3.651-3.59-3.651v-.547s5.902-2.921,6.572-2.921c1.399,0,1.399,1.156,1.399,2.921v15.274c0,5.477,2.434,8.215,6.876,8.215,4.808,0,7.606-3.408,7.668-3.469v-15.821c0-3.59-3.165-3.651-3.591-3.651v-.547s5.903-2.921,6.572-2.921c1.399,0,1.399,1.156,1.399,2.921v17.343c0,3.59,3.164,3.651,3.591,3.651v.548s-5.903,2.921-6.572,2.921c-1.399,0-1.399-1.156-1.399-2.921v-.243Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M880.725,102.113c0,3.956,2.8,5.172,3.591,5.172v.974h-7.972v-3.164c-1.582.974-5.599,3.103-10.345,3.103-5.781,0-10.832-2.495-10.832-6.268,0-4.381,5.173-5.598,9.676-7.12,3.712-1.156,7.972-2.617,11.196-5.903-.608-4.016-2.495-7.059-7.667-7.059-3.286,0-6.268,2.373-6.268,6.633,0,1.46-1.156,2.19-2.312,2.19-1.096,0-2.252-.73-2.252-2.19,0-3.408,4.686-7.606,11.258-7.606,6.876,0,11.927,3.773,11.927,13.205v8.033ZM876.344,94.08c0-1.338,0-2.677-.122-3.955-3.347,3.104-7.424,4.442-11.075,5.659-4.503,1.46-5.598,3.651-5.598,6.146,0,3.042,3.103,5.051,7.241,5.051,5.051,0,8.701-2.434,9.554-3.042v-9.858Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M887.969,100.774v-18.925h-3.59v-.974h3.59v-3.895c0-3.53-3.164-3.59-3.59-3.59v-.608s5.902-2.86,6.572-2.86c1.399,0,1.399,1.096,1.399,2.86v8.093h8.032v.974h-8.032v19.655c0,4.016,2.434,5.781,4.868,5.781,3.104,0,4.746-1.947,4.746-1.947l.853.487s-1.947,2.434-6.694,2.434-8.154-2.617-8.154-7.485Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M908.236,93.594v.974c0,6.938,4.26,12.292,9.432,12.292,5.233,0,8.337-3.895,8.337-3.895l.791.547c-2.312,2.86-6.39,4.747-10.223,4.747-7.06,0-12.779-6.146-12.779-13.692s5.72-13.691,12.779-13.691c6.693,0,12.23,5.598,12.657,12.718h-20.994ZM908.296,92.62h16.491c-.608-6.024-4.077-10.771-8.215-10.771-4.199,0-7.668,4.686-8.276,10.771Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M953.207,105.034s-2.799,3.225-8.215,3.225c-7.059,0-12.718-6.146-12.718-13.692s5.659-13.691,12.718-13.691c5.112,0,8.215,3.225,8.215,3.225v-13.083c0-3.53-3.164-3.59-3.59-3.59v-.609s5.902-2.86,6.572-2.86c1.399,0,1.399,1.095,1.399,2.86v35.294c0,3.956,2.738,5.172,3.59,5.172v.974h-7.972v-3.225ZM953.207,103.574v-18.012s-3.042-3.712-7.728-3.712c-5.781,0-8.823,5.659-8.823,12.657,0,6.937,3.103,12.779,8.884,12.779,4.686,0,7.667-3.712,7.667-3.712Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M984.368,77.103v3.773h8.032v.974h-8.032v20.446c0,3.833,2.799,4.99,3.59,4.99v.974h-11.562v-.974c.852,0,3.59-1.156,3.59-4.99v-20.446h-3.59v-.974h3.59v-3.773c0-8.702,4.808-13.144,11.623-13.144,6.572,0,10.893,4.138,10.893,7.545,0,1.521-1.156,2.252-2.252,2.252-1.155,0-2.312-.73-2.312-2.252,0-4.259-2.617-6.572-5.903-6.572-7.181,0-7.667,6.39-7.667,12.17Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1003.477,86.048c.487-.669,3.895-5.172,10.162-5.172,3.834,0,8.702,2.008,8.702,5.72,0,1.399-1.095,2.069-2.13,2.069-1.156,0-2.251-.73-2.251-2.069,0-3.59-2.435-4.747-4.868-4.747-5.781,0-9.128,5.051-9.615,5.842v14.422c0,3.956,2.738,5.172,3.591,5.172v.974h-11.623v-.974c.852,0,3.59-1.217,3.59-5.172v-14.239c0-3.469-3.164-3.53-3.59-3.53v-.547s5.902-2.921,6.572-2.921c1.46,0,1.46,1.156,1.46,2.921v2.251Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1037.8,80.876c7.363,0,13.388,6.146,13.388,13.691s-6.024,13.692-13.388,13.692c-7.424,0-13.448-6.146-13.448-13.692s6.024-13.691,13.448-13.691ZM1037.8,81.849c-4.99,0-9.006,5.842-9.006,12.779,0,6.998,4.016,12.657,9.006,12.657,4.929,0,9.006-5.659,9.006-12.657,0-6.937-4.077-12.779-9.006-12.779Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1088.31,80.876c5.233,0,10.71,3.104,10.71,10.162v11.075c0,3.956,2.738,5.172,3.591,5.172v.974h-11.562v-.974c.853,0,3.591-1.217,3.591-5.172v-12.049c0-5.477-2.373-8.215-6.877-8.215-4.198,0-7.18,2.799-8.641,4.503.608,1.339.974,2.86.974,4.686v11.075c0,3.956,2.8,5.172,3.591,5.172v.974h-11.562v-.974c.853,0,3.591-1.217,3.591-5.172v-12.049c0-5.477-2.374-8.215-6.815-8.215-4.868,0-7.668,3.408-7.729,3.468v16.795c0,3.956,2.799,5.172,3.651,5.172v.974h-11.623v-.974c.852,0,3.59-1.217,3.59-5.172v-14.239c0-3.469-3.164-3.53-3.59-3.53v-.547s5.902-2.921,6.572-2.921c1.399,0,1.399,1.156,1.399,2.921v.243c.061,0,2.981-3.164,8.215-3.164,3.591,0,7.241,1.46,9.25,4.625,1.217-1.46,4.441-4.625,9.675-4.625Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1117.83,86.474c0-12.961,9.188-21.785,20.689-21.785,10.467,0,17.404,6.633,17.404,12.049,0,1.887-1.156,2.799-2.312,2.799-1.217,0-2.435-.974-2.435-2.799,0-7.241-6.693-11.014-11.927-11.014-8.154,0-16.612,7.12-16.612,20.75,0,12.292,8.884,20.021,17.16,20.021,8.763,0,13.874-6.268,13.874-6.268l.852.486c-3.712,4.625-10.223,7.546-16.369,7.546-11.196,0-20.324-9.736-20.324-21.785Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1171.384,80.876c7.363,0,13.388,6.146,13.388,13.691s-6.024,13.692-13.388,13.692c-7.424,0-13.448-6.146-13.448-13.692s6.024-13.691,13.448-13.691ZM1171.384,81.849c-4.99,0-9.006,5.842-9.006,12.779,0,6.998,4.016,12.657,9.006,12.657,4.929,0,9.006-5.659,9.006-12.657,0-6.937-4.077-12.779-9.006-12.779Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1194.815,86.048c.487-.669,3.895-5.172,10.162-5.172,3.834,0,8.702,2.008,8.702,5.72,0,1.399-1.095,2.069-2.13,2.069-1.156,0-2.251-.73-2.251-2.069,0-3.59-2.435-4.747-4.868-4.747-5.781,0-9.128,5.051-9.615,5.842v14.422c0,3.956,2.738,5.172,3.591,5.172v.974h-11.623v-.974c.852,0,3.59-1.217,3.59-5.172v-14.239c0-3.469-3.164-3.53-3.59-3.53v-.547s5.902-2.921,6.572-2.921c1.46,0,1.46,1.156,1.46,2.921v2.251Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1224.697,84.04c.061,0,2.981-3.164,8.215-3.164,5.173,0,10.71,3.104,10.71,10.162v11.075c0,3.956,2.738,5.172,3.591,5.172v.974h-11.562v-.974c.791,0,3.591-1.217,3.591-5.172v-12.049c0-5.477-2.435-8.215-6.877-8.215-4.807,0-7.606,3.408-7.667,3.468v16.795c0,3.956,2.738,5.172,3.59,5.172v.974h-11.562v-.974c.852,0,3.59-1.217,3.59-5.172v-14.118c0-3.59-3.164-3.651-3.59-3.651v-.547s5.902-2.921,6.572-2.921c1.399,0,1.399,1.156,1.399,2.921v.243Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1253.665,93.594v.974c0,6.938,4.26,12.292,9.432,12.292,5.233,0,8.337-3.895,8.337-3.895l.791.547c-2.312,2.86-6.39,4.747-10.223,4.747-7.06,0-12.779-6.146-12.779-13.692s5.72-13.691,12.779-13.691c6.693,0,12.23,5.598,12.657,12.718h-20.994ZM1253.726,92.62h16.491c-.608-6.024-4.077-10.771-8.215-10.771-4.199,0-7.668,4.686-8.276,10.771Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1284.641,102.174c0,3.895,2.738,5.111,3.59,5.111v.974h-11.562v-.974c.791,0,3.59-1.217,3.59-5.111v-28.723c0-3.529-3.164-3.651-3.59-3.651v-.548s5.902-2.86,6.572-2.86c1.399,0,1.399,1.095,1.399,2.86v32.921ZM1298.454,102.174c0,3.895,2.738,5.111,3.59,5.111v.974h-11.622v-.974c.852,0,3.65-1.217,3.65-5.111v-31.156c0-3.53-3.225-3.59-3.65-3.59v-.609s5.963-2.86,6.571-2.86c1.461,0,1.461,1.095,1.461,2.86v35.355Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1320.851,70.835c0-3.895-2.738-5.112-3.59-5.112v-.974h11.988v.974c-.853,0-3.591,1.217-3.591,5.112v23.306c0,11.379,6.268,13.144,10.893,13.144,6.572,0,13.022-3.895,13.022-12.9v-23.55c0-3.895-2.738-5.112-3.591-5.112v-.974h8.154v.974c-.852,0-3.59,1.217-3.59,5.112v23.55c0,9.614-7.059,13.874-13.996,13.874-7.424,0-15.7-3.529-15.7-15.091v-22.333Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1363.148,84.04c.061,0,2.981-3.164,8.215-3.164,5.173,0,10.71,3.104,10.71,10.162v11.075c0,3.956,2.738,5.172,3.591,5.172v.974h-11.562v-.974c.791,0,3.591-1.217,3.591-5.172v-12.049c0-5.477-2.435-8.215-6.877-8.215-4.807,0-7.606,3.408-7.667,3.468v16.795c0,3.956,2.738,5.172,3.59,5.172v.974h-11.562v-.974c.852,0,3.59-1.217,3.59-5.172v-14.118c0-3.59-3.164-3.651-3.59-3.651v-.547s5.902-2.921,6.572-2.921c1.399,0,1.399,1.156,1.399,2.921v.243Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1388.709,107.285c.852,0,3.59-1.217,3.59-5.172v-14.239c0-3.469-3.164-3.53-3.59-3.53v-.547s5.902-2.921,6.572-2.921c1.46,0,1.46,1.156,1.46,2.921v18.316c0,3.956,2.738,5.172,3.591,5.172v.974h-11.623v-.974ZM1394.491,75.703c-1.461,0-2.678-1.217-2.678-2.678,0-1.521,1.217-2.738,2.678-2.738,1.521,0,2.738,1.217,2.738,2.738,0,1.46-1.217,2.678-2.738,2.678Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1431.367,81.849c-2.556,0-4.503,5.172-4.503,5.172l-9.493,21.237h-1.034l-9.493-21.237s-1.947-5.172-4.503-5.172v-.974h11.623v.974c-.67,0-2.556,1.46-2.556,3.59,0,.487.121,1.034.364,1.582l6.998,15.7,7.911-17.647c.305-.73.426-1.339.426-1.765,0-1.278-1.095-1.46-1.704-1.46v-.974h5.964v.974Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1435.814,93.594v.974c0,6.938,4.26,12.292,9.432,12.292,5.233,0,8.337-3.895,8.337-3.895l.791.547c-2.312,2.86-6.39,4.747-10.223,4.747-7.06,0-12.779-6.146-12.779-13.692s5.72-13.691,12.779-13.691c6.693,0,12.23,5.598,12.657,12.718h-20.994ZM1435.874,92.62h16.491c-.608-6.024-4.077-10.771-8.215-10.771-4.199,0-7.668,4.686-8.276,10.771Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1467.884,86.048c.487-.669,3.895-5.172,10.162-5.172,3.834,0,8.702,2.008,8.702,5.72,0,1.399-1.096,2.069-2.13,2.069-1.156,0-2.251-.73-2.251-2.069,0-3.59-2.435-4.747-4.868-4.747-5.781,0-9.128,5.051-9.615,5.842v14.422c0,3.956,2.738,5.172,3.591,5.172v.974h-11.623v-.974c.852,0,3.59-1.217,3.59-5.172v-14.239c0-3.469-3.164-3.53-3.59-3.53v-.547s5.902-2.921,6.572-2.921c1.46,0,1.46,1.156,1.46,2.921v2.251Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1514.075,101.626c0,4.868-6.268,6.633-11.928,6.633-6.085,0-12.109-3.225-12.109-6.268,0-1.156,1.096-1.765,2.191-1.765s2.251.608,2.251,1.825c0,2.556,2.312,5.172,8.215,5.172,4.625,0,6.755-1.704,6.755-4.32,0-5.598-19.655-7.363-19.655-15.152,0-5.233,6.572-6.876,11.501-6.876,5.051,0,11.562,3.225,11.562,6.268,0,1.156-1.155,1.704-2.251,1.704-1.156,0-2.252-.548-2.252-1.765,0-2.556-2.434-5.233-7.789-5.233-3.772,0-6.146,1.947-6.146,4.564,0,5.416,19.655,7.484,19.655,15.213Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1516.084,107.285c.852,0,3.59-1.217,3.59-5.172v-14.239c0-3.469-3.164-3.53-3.59-3.53v-.547s5.902-2.921,6.572-2.921c1.46,0,1.46,1.156,1.46,2.921v18.316c0,3.956,2.738,5.172,3.591,5.172v.974h-11.623v-.974ZM1521.866,75.703c-1.461,0-2.678-1.217-2.678-2.678,0-1.521,1.217-2.738,2.678-2.738,1.521,0,2.738,1.217,2.738,2.738,0,1.46-1.217,2.678-2.738,2.678Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1532.333,100.774v-18.925h-3.59v-.974h3.59v-3.895c0-3.53-3.164-3.59-3.59-3.59v-.608s5.902-2.86,6.572-2.86c1.399,0,1.399,1.096,1.399,2.86v8.093h8.032v.974h-8.032v19.655c0,4.016,2.434,5.781,4.868,5.781,3.104,0,4.746-1.947,4.746-1.947l.853.487s-1.947,2.434-6.694,2.434-8.154-2.617-8.154-7.485Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1577.245,80.876v.974c-2.556,0-4.503,5.172-4.503,5.172l-12.414,27.81s-4.32,10.345-8.641,10.345c-2.069,0-3.043-1.156-3.043-2.312s1.035-2.373,2.982-2.373c5.477,0,7.667-5.659,7.667-5.659l2.921-6.572-9.493-21.237s-1.887-5.172-4.503-5.172v-.974h11.623v.974c-.67,0-2.495,1.46-2.495,3.59,0,.487.061,1.034.304,1.582l6.998,15.7,7.911-17.647c.305-.73.426-1.339.426-1.765,0-1.278-1.095-1.46-1.704-1.46v-.974h5.964Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M859.063,159.504c0-12.961,9.188-21.785,20.689-21.785,10.467,0,17.404,6.633,17.404,12.049,0,1.887-1.156,2.799-2.312,2.799-1.217,0-2.435-.974-2.435-2.799,0-7.241-6.693-11.014-11.927-11.014-8.154,0-16.612,7.12-16.612,20.75,0,12.292,8.884,20.021,17.16,20.021,8.763,0,13.874-6.268,13.874-6.268l.852.486c-3.712,4.625-10.223,7.546-16.369,7.546-11.196,0-20.324-9.736-20.324-21.785Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M912.617,153.906c7.363,0,13.388,6.146,13.388,13.691s-6.024,13.692-13.388,13.692c-7.424,0-13.448-6.146-13.448-13.692s6.024-13.691,13.448-13.691ZM912.617,154.88c-4.99,0-9.006,5.842-9.006,12.779,0,6.998,4.016,12.657,9.006,12.657,4.929,0,9.006-5.659,9.006-12.657,0-6.937-4.077-12.779-9.006-12.779Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M935.987,175.204c0,3.895,2.738,5.111,3.59,5.111v.974h-11.562v-.974c.791,0,3.59-1.217,3.59-5.111v-28.723c0-3.529-3.164-3.651-3.59-3.651v-.548s5.902-2.86,6.572-2.86c1.399,0,1.399,1.095,1.399,2.86v32.921ZM949.8,175.204c0,3.895,2.738,5.111,3.59,5.111v.974h-11.622v-.974c.852,0,3.65-1.217,3.65-5.111v-31.156c0-3.53-3.225-3.59-3.65-3.59v-.609s5.963-2.86,6.571-2.86c1.461,0,1.461,1.095,1.461,2.86v35.355Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M959.844,166.624v.974c0,6.938,4.26,12.292,9.432,12.292,5.233,0,8.337-3.895,8.337-3.895l.791.547c-2.312,2.86-6.39,4.747-10.223,4.747-7.06,0-12.779-6.146-12.779-13.692s5.72-13.691,12.779-13.691c6.693,0,12.23,5.598,12.657,12.718h-20.994ZM959.905,165.65h16.491c-.608-6.024-4.077-10.771-8.215-10.771-4.199,0-7.668,4.686-8.276,10.771Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1014.491,189.748c0,3.712-2.981,8.458-15.396,8.458-11.501,0-14.908-4.503-14.908-7.242,0-1.886,1.277-2.738,2.556-2.738s2.556.852,2.556,2.374c0,3.59,2.921,6.633,9.25,6.633,9.371,0,11.318-4.686,11.318-7.789,0-3.165-2.678-5.842-6.085-5.842-5.599,0-8.52,2.373-14.058,2.373-4.198,0-6.876-1.886-6.876-4.99,0-2.86,2.921-4.625,6.085-4.99-2.434-2.312-3.895-5.599-3.895-9.25,0-7.12,5.599-12.84,12.536-12.84,2.981,0,5.78,1.095,7.91,2.921,0-.122,1.339-4.868,4.93-4.868,1.277,0,1.886.913,1.886,1.826s-.669,1.886-1.886,1.886c-3.165,0-3.956,1.886-4.017,1.947,2.312,2.312,3.712,5.538,3.712,9.128,0,7.059-5.599,12.779-12.535,12.779-2.8,0-5.355-.913-7.424-2.495-2.312,0-4.321,1.4-4.321,3.956,0,2.008,1.704,4.016,4.442,4.016,5.416,0,7.546-2.434,14.057-2.434,7.363,0,10.162,3.956,10.162,7.181ZM989.42,166.806c0,6.451,3.65,11.745,8.154,11.745s8.154-5.294,8.154-11.745c0-6.511-3.651-11.927-8.154-11.927s-8.154,5.416-8.154,11.927Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1018.937,166.624v.974c0,6.938,4.26,12.292,9.432,12.292,5.233,0,8.337-3.895,8.337-3.895l.791.547c-2.312,2.86-6.39,4.747-10.223,4.747-7.06,0-12.779-6.146-12.779-13.692s5.72-13.691,12.779-13.691c6.693,0,12.23,5.598,12.657,12.718h-20.994ZM1018.997,165.65h16.491c-.608-6.024-4.077-10.771-8.215-10.771-4.199,0-7.668,4.686-8.276,10.771Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1068.596,153.906c7.363,0,13.388,6.146,13.388,13.691s-6.024,13.692-13.388,13.692c-7.424,0-13.448-6.146-13.448-13.692s6.024-13.691,13.448-13.691ZM1068.596,154.88c-4.99,0-9.006,5.842-9.006,12.779,0,6.998,4.016,12.657,9.006,12.657,4.929,0,9.006-5.659,9.006-12.657,0-6.937-4.077-12.779-9.006-12.779Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1093,150.133v3.773h8.032v.974h-8.032v20.446c0,3.833,2.799,4.99,3.59,4.99v.974h-11.562v-.974c.852,0,3.59-1.156,3.59-4.99v-20.446h-3.59v-.974h3.59v-3.773c0-8.702,4.808-13.144,11.623-13.144,6.572,0,10.893,4.138,10.893,7.545,0,1.521-1.156,2.252-2.251,2.252-1.156,0-2.312-.73-2.312-2.252,0-4.259-2.617-6.572-5.903-6.572-7.181,0-7.667,6.39-7.667,12.17Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1116.248,180.316c.852,0,3.59-1.217,3.59-5.111v-31.339c0-3.895-2.738-5.112-3.59-5.112v-.974h31.217l1.461,5.233s-3.469-4.26-8.032-4.26h-16.248v20.325h12.536c3.955,0,5.172-2.799,5.172-3.651h.974v8.215h-.974c0-.852-1.217-3.59-5.172-3.59h-12.536v20.264h17.1c4.625,0,8.458-5.781,8.458-5.781l-1.825,6.754h-32.13v-.974Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1161.221,157.07c.061,0,2.981-3.164,8.215-3.164,5.173,0,10.71,3.104,10.71,10.162v11.075c0,3.956,2.738,5.172,3.591,5.172v.974h-11.562v-.974c.791,0,3.591-1.217,3.591-5.172v-12.049c0-5.477-2.435-8.215-6.877-8.215-4.807,0-7.606,3.408-7.667,3.468v16.795c0,3.956,2.738,5.172,3.59,5.172v.974h-11.562v-.974c.852,0,3.59-1.217,3.59-5.172v-14.118c0-3.59-3.164-3.651-3.59-3.651v-.547s5.902-2.921,6.572-2.921c1.399,0,1.399,1.156,1.399,2.921v.243Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1218.426,189.748c0,3.712-2.982,8.458-15.396,8.458-11.501,0-14.908-4.503-14.908-7.242,0-1.886,1.277-2.738,2.556-2.738s2.556.852,2.556,2.374c0,3.59,2.921,6.633,9.25,6.633,9.371,0,11.318-4.686,11.318-7.789,0-3.165-2.678-5.842-6.086-5.842-5.598,0-8.519,2.373-14.057,2.373-4.198,0-6.876-1.886-6.876-4.99,0-2.86,2.921-4.625,6.085-4.99-2.434-2.312-3.895-5.599-3.895-9.25,0-7.12,5.599-12.84,12.536-12.84,2.981,0,5.78,1.095,7.91,2.921,0-.122,1.339-4.868,4.93-4.868,1.277,0,1.886.913,1.886,1.826s-.669,1.886-1.886,1.886c-3.165,0-3.956,1.886-4.017,1.947,2.312,2.312,3.712,5.538,3.712,9.128,0,7.059-5.599,12.779-12.535,12.779-2.8,0-5.355-.913-7.425-2.495-2.312,0-4.32,1.4-4.32,3.956,0,2.008,1.704,4.016,4.442,4.016,5.416,0,7.546-2.434,14.057-2.434,7.363,0,10.163,3.956,10.163,7.181ZM1193.355,166.806c0,6.451,3.651,11.745,8.154,11.745s8.153-5.294,8.153-11.745c0-6.511-3.65-11.927-8.153-11.927s-8.154,5.416-8.154,11.927Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1218.429,180.316c.852,0,3.59-1.217,3.59-5.172v-14.239c0-3.469-3.164-3.53-3.59-3.53v-.547s5.902-2.921,6.572-2.921c1.46,0,1.46,1.156,1.46,2.921v18.316c0,3.956,2.738,5.172,3.591,5.172v.974h-11.623v-.974ZM1224.21,148.734c-1.461,0-2.678-1.217-2.678-2.678,0-1.521,1.217-2.738,2.678-2.738,1.521,0,2.738,1.217,2.738,2.738,0,1.46-1.218,2.678-2.738,2.678Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1241.068,157.07c.061,0,2.981-3.164,8.215-3.164,5.173,0,10.71,3.104,10.71,10.162v11.075c0,3.956,2.738,5.172,3.591,5.172v.974h-11.562v-.974c.791,0,3.591-1.217,3.591-5.172v-12.049c0-5.477-2.435-8.215-6.877-8.215-4.807,0-7.606,3.408-7.667,3.468v16.795c0,3.956,2.738,5.172,3.59,5.172v.974h-11.562v-.974c.852,0,3.59-1.217,3.59-5.172v-14.118c0-3.59-3.164-3.651-3.59-3.651v-.547s5.902-2.921,6.572-2.921c1.399,0,1.399,1.156,1.399,2.921v.243Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1270.036,166.624v.974c0,6.938,4.26,12.292,9.432,12.292,5.233,0,8.337-3.895,8.337-3.895l.791.547c-2.312,2.86-6.39,4.747-10.223,4.747-7.06,0-12.779-6.146-12.779-13.692s5.72-13.691,12.779-13.691c6.693,0,12.23,5.598,12.657,12.718h-20.994ZM1270.097,165.65h16.491c-.608-6.024-4.077-10.771-8.215-10.771-4.199,0-7.668,4.686-8.276,10.771Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1298.517,166.624v.974c0,6.938,4.26,12.292,9.432,12.292,5.233,0,8.337-3.895,8.337-3.895l.791.547c-2.312,2.86-6.39,4.747-10.223,4.747-7.06,0-12.779-6.146-12.779-13.692s5.72-13.691,12.779-13.691c6.693,0,12.23,5.598,12.657,12.718h-20.994ZM1298.577,165.65h16.491c-.608-6.024-4.077-10.771-8.215-10.771-4.199,0-7.668,4.686-8.276,10.771Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1330.588,159.078c.487-.669,3.895-5.172,10.162-5.172,3.834,0,8.702,2.008,8.702,5.72,0,1.399-1.095,2.069-2.13,2.069-1.156,0-2.251-.73-2.251-2.069,0-3.59-2.435-4.747-4.868-4.747-5.781,0-9.128,5.051-9.615,5.842v14.422c0,3.956,2.738,5.172,3.591,5.172v.974h-11.623v-.974c.852,0,3.59-1.217,3.59-5.172v-14.239c0-3.469-3.164-3.53-3.59-3.53v-.547s5.902-2.921,6.572-2.921c1.46,0,1.46,1.156,1.46,2.921v2.251Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1352.497,180.316c.852,0,3.59-1.217,3.59-5.172v-14.239c0-3.469-3.164-3.53-3.59-3.53v-.547s5.902-2.921,6.572-2.921c1.46,0,1.46,1.156,1.46,2.921v18.316c0,3.956,2.738,5.172,3.591,5.172v.974h-11.623v-.974ZM1358.279,148.734c-1.461,0-2.678-1.217-2.678-2.678,0-1.521,1.217-2.738,2.678-2.738,1.521,0,2.738,1.217,2.738,2.738,0,1.46-1.218,2.678-2.738,2.678Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1375.136,157.07c.061,0,2.981-3.164,8.215-3.164,5.173,0,10.71,3.104,10.71,10.162v11.075c0,3.956,2.738,5.172,3.591,5.172v.974h-11.562v-.974c.791,0,3.591-1.217,3.591-5.172v-12.049c0-5.477-2.435-8.215-6.877-8.215-4.807,0-7.606,3.408-7.667,3.468v16.795c0,3.956,2.738,5.172,3.59,5.172v.974h-11.562v-.974c.852,0,3.59-1.217,3.59-5.172v-14.118c0-3.59-3.164-3.651-3.59-3.651v-.547s5.902-2.921,6.572-2.921c1.399,0,1.399,1.156,1.399,2.921v.243Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1432.34,189.748c0,3.712-2.981,8.458-15.396,8.458-11.501,0-14.908-4.503-14.908-7.242,0-1.886,1.277-2.738,2.556-2.738s2.556.852,2.556,2.374c0,3.59,2.921,6.633,9.25,6.633,9.371,0,11.318-4.686,11.318-7.789,0-3.165-2.678-5.842-6.085-5.842-5.599,0-8.52,2.373-14.058,2.373-4.198,0-6.876-1.886-6.876-4.99,0-2.86,2.921-4.625,6.085-4.99-2.434-2.312-3.895-5.599-3.895-9.25,0-7.12,5.599-12.84,12.536-12.84,2.981,0,5.78,1.095,7.91,2.921,0-.122,1.339-4.868,4.93-4.868,1.277,0,1.886.913,1.886,1.826s-.669,1.886-1.886,1.886c-3.165,0-3.956,1.886-4.017,1.947,2.312,2.312,3.712,5.538,3.712,9.128,0,7.059-5.599,12.779-12.535,12.779-2.8,0-5.355-.913-7.424-2.495-2.312,0-4.321,1.4-4.321,3.956,0,2.008,1.704,4.016,4.442,4.016,5.416,0,7.546-2.434,14.057-2.434,7.363,0,10.162,3.956,10.162,7.181ZM1407.27,166.806c0,6.451,3.65,11.745,8.154,11.745s8.154-5.294,8.154-11.745c0-6.511-3.651-11.927-8.154-11.927s-8.154,5.416-8.154,11.927Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                  </g>
                  <g>
                    <path
                      d="M609.27,413.63c0,4.868-6.269,6.633-11.928,6.633-6.085,0-12.109-3.225-12.109-6.268,0-1.156,1.096-1.765,2.19-1.765s2.252.608,2.252,1.825c0,2.556,2.312,5.172,8.215,5.172,4.625,0,6.755-1.704,6.755-4.32,0-5.598-19.655-7.363-19.655-15.152,0-5.233,6.572-6.876,11.501-6.876,5.051,0,11.562,3.225,11.562,6.268,0,1.156-1.156,1.704-2.251,1.704-1.156,0-2.252-.548-2.252-1.765,0-2.556-2.434-5.233-7.789-5.233-3.772,0-6.146,1.947-6.146,4.564,0,5.416,19.655,7.484,19.655,15.213Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M625.763,392.88c7.363,0,13.388,6.146,13.388,13.691s-6.024,13.692-13.388,13.692c-7.424,0-13.448-6.146-13.448-13.692s6.024-13.691,13.448-13.691ZM625.763,393.853c-4.99,0-9.006,5.842-9.006,12.779,0,6.998,4.016,12.657,9.006,12.657,4.929,0,9.006-5.659,9.006-12.657,0-6.937-4.077-12.779-9.006-12.779Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M678.706,417.89s-1.947,2.373-6.693,2.373-8.154-2.556-8.154-7.424v-18.925h-3.59v-.974h3.59v-9.371c0-4.26-2.678-6.633-5.963-6.633-7.242,0-7.668,6.45-7.668,12.231v3.772h8.033v.974h-8.033v20.386c0,3.833,2.738,5.051,3.591,5.051v.913h-11.623v-.913c.852,0,3.651-1.217,3.651-5.051v-20.386h-3.651v-.974h3.651v-3.772c0-8.702,4.746-13.205,11.562-13.205,6.572,0,10.893,4.199,10.893,7.606v9.371h8.032v.974h-8.032v19.655c0,3.956,2.373,5.72,4.868,5.72,3.104,0,4.746-1.886,4.746-1.886l.791.487Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M691.368,393.853c-.67,0-2.556,1.46-2.556,3.59,0,.487.121,1.034.364,1.582l6.998,15.7,6.451-14.361-.609-1.339s-1.947-5.172-4.503-5.172v-.974h11.623v.974c-.669,0-2.556,1.46-2.556,3.59,0,.487.121,1.034.365,1.582l6.998,15.7,7.91-17.647c.305-.73.426-1.339.426-1.765,0-1.278-1.095-1.46-1.703-1.46v-.974h5.963v.974c-2.556,0-4.503,5.172-4.503,5.172l-9.493,21.237h-1.034l-8.337-18.682-8.397,18.682h-1.034l-9.493-21.237s-1.887-5.172-4.503-5.172v-.974h11.623v.974Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M752.104,414.117c0,3.956,2.8,5.172,3.591,5.172v.974h-7.972v-3.164c-1.582.974-5.599,3.103-10.345,3.103-5.781,0-10.832-2.495-10.832-6.268,0-4.381,5.173-5.598,9.676-7.12,3.712-1.156,7.972-2.617,11.196-5.903-.608-4.016-2.495-7.059-7.667-7.059-3.286,0-6.268,2.373-6.268,6.633,0,1.46-1.156,2.19-2.312,2.19-1.096,0-2.252-.73-2.252-2.19,0-3.408,4.686-7.606,11.258-7.606,6.876,0,11.927,3.773,11.927,13.205v8.033ZM747.723,406.084c0-1.338,0-2.677-.122-3.955-3.347,3.104-7.424,4.442-11.075,5.659-4.503,1.46-5.599,3.651-5.599,6.146,0,3.042,3.104,5.051,7.242,5.051,5.051,0,8.701-2.434,9.554-3.042v-9.858Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M766.772,398.052c.487-.669,3.895-5.172,10.162-5.172,3.834,0,8.702,2.008,8.702,5.72,0,1.399-1.096,2.069-2.13,2.069-1.156,0-2.251-.73-2.251-2.069,0-3.59-2.435-4.747-4.868-4.747-5.781,0-9.128,5.051-9.615,5.842v14.422c0,3.956,2.738,5.172,3.591,5.172v.974h-11.623v-.974c.852,0,3.59-1.217,3.59-5.172v-14.239c0-3.469-3.164-3.53-3.59-3.53v-.547s5.902-2.921,6.572-2.921c1.46,0,1.46,1.156,1.46,2.921v2.251Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M793.123,405.597v.974c0,6.938,4.26,12.292,9.432,12.292,5.233,0,8.337-3.895,8.337-3.895l.791.547c-2.312,2.86-6.39,4.747-10.223,4.747-7.06,0-12.779-6.146-12.779-13.692s5.72-13.691,12.779-13.691c6.693,0,12.23,5.598,12.657,12.718h-20.994ZM793.184,404.624h16.491c-.608-6.024-4.077-10.771-8.215-10.771-4.199,0-7.668,4.686-8.276,10.771Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M850.267,417.038s-2.799,3.225-8.215,3.225c-7.059,0-12.718-6.146-12.718-13.692s5.659-13.691,12.718-13.691c5.112,0,8.215,3.225,8.215,3.225v-13.083c0-3.53-3.164-3.59-3.59-3.59v-.609s5.902-2.86,6.572-2.86c1.399,0,1.399,1.095,1.399,2.86v35.294c0,3.956,2.738,5.172,3.59,5.172v.974h-7.972v-3.225ZM850.267,415.577v-18.012s-3.042-3.712-7.728-3.712c-5.781,0-8.823,5.659-8.823,12.657,0,6.937,3.103,12.779,8.884,12.779,4.686,0,7.667-3.712,7.667-3.712Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M864.692,405.597v.974c0,6.938,4.26,12.292,9.432,12.292,5.233,0,8.337-3.895,8.337-3.895l.791.547c-2.312,2.86-6.39,4.747-10.223,4.747-7.06,0-12.779-6.146-12.779-13.692s5.72-13.691,12.779-13.691c6.693,0,12.23,5.598,12.657,12.718h-20.994ZM864.752,404.624h16.491c-.608-6.024-4.077-10.771-8.215-10.771-4.199,0-7.668,4.686-8.276,10.771Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M914.715,393.853c-2.556,0-4.503,5.172-4.503,5.172l-9.493,21.237h-1.034l-9.493-21.237s-1.947-5.172-4.503-5.172v-.974h11.623v.974c-.67,0-2.556,1.46-2.556,3.59,0,.487.121,1.034.364,1.582l6.998,15.7,7.911-17.647c.305-.73.426-1.339.426-1.765,0-1.278-1.095-1.46-1.704-1.46v-.974h5.964v.974Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M919.16,405.597v.974c0,6.938,4.26,12.292,9.432,12.292,5.233,0,8.337-3.895,8.337-3.895l.791.547c-2.312,2.86-6.39,4.747-10.223,4.747-7.06,0-12.779-6.146-12.779-13.692s5.72-13.691,12.779-13.691c6.693,0,12.23,5.598,12.657,12.718h-20.994ZM919.221,404.624h16.491c-.608-6.024-4.077-10.771-8.215-10.771-4.199,0-7.668,4.686-8.276,10.771Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M942.165,419.289c.852,0,3.59-1.217,3.59-5.172v-31.217c0-3.408-3.164-3.468-3.59-3.468v-.609s5.902-2.86,6.572-2.86c1.46,0,1.46,1.095,1.46,2.86v35.294c0,3.956,2.738,5.172,3.591,5.172v.974h-11.623v-.974Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M969.245,392.88c7.363,0,13.388,6.146,13.388,13.691s-6.024,13.692-13.388,13.692c-7.424,0-13.448-6.146-13.448-13.692s6.024-13.691,13.448-13.691ZM969.245,393.853c-4.99,0-9.006,5.842-9.006,12.779,0,6.998,4.016,12.657,9.006,12.657,4.929,0,9.006-5.659,9.006-12.657,0-6.937-4.077-12.779-9.006-12.779Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M984.644,395.801s5.902-2.921,6.572-2.921c1.399,0,1.399,1.156,1.399,2.921v.304s3.104-3.225,8.215-3.225c7.059,0,12.779,6.146,12.779,13.691s-5.721,13.692-12.779,13.692c-5.416,0-8.215-3.225-8.215-3.225v13.996c0,3.955,2.799,5.172,3.651,5.172v.974h-11.623v-.974c.852,0,3.59-1.217,3.59-5.172v-31.156c0-3.469-3.164-3.53-3.59-3.53v-.547ZM992.616,415.577s2.981,3.712,7.667,3.712c5.781,0,8.885-5.842,8.885-12.779,0-6.998-3.043-12.657-8.823-12.657-4.686,0-7.729,3.712-7.729,3.712v18.012Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1021.097,405.597v.974c0,6.938,4.26,12.292,9.432,12.292,5.233,0,8.337-3.895,8.337-3.895l.791.547c-2.312,2.86-6.39,4.747-10.223,4.747-7.06,0-12.779-6.146-12.779-13.692s5.72-13.691,12.779-13.691c6.693,0,12.23,5.598,12.657,12.718h-20.994ZM1021.158,404.624h16.491c-.608-6.024-4.077-10.771-8.215-10.771-4.199,0-7.668,4.686-8.276,10.771Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1053.168,398.052c.487-.669,3.895-5.172,10.162-5.172,3.834,0,8.702,2.008,8.702,5.72,0,1.399-1.096,2.069-2.13,2.069-1.156,0-2.251-.73-2.251-2.069,0-3.59-2.435-4.747-4.868-4.747-5.781,0-9.128,5.051-9.615,5.842v14.422c0,3.956,2.738,5.172,3.591,5.172v.974h-11.623v-.974c.852,0,3.59-1.217,3.59-5.172v-14.239c0-3.469-3.164-3.53-3.59-3.53v-.547s5.902-2.921,6.572-2.921c1.46,0,1.46,1.156,1.46,2.921v2.251Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1112.807,414.117c0,3.956,2.8,5.172,3.591,5.172v.974h-7.972v-3.164c-1.582.974-5.599,3.103-10.345,3.103-5.781,0-10.832-2.495-10.832-6.268,0-4.381,5.173-5.598,9.676-7.12,3.712-1.156,7.972-2.617,11.196-5.903-.608-4.016-2.495-7.059-7.667-7.059-3.286,0-6.268,2.373-6.268,6.633,0,1.46-1.156,2.19-2.312,2.19-1.096,0-2.252-.73-2.252-2.19,0-3.408,4.686-7.606,11.258-7.606,6.876,0,11.927,3.773,11.927,13.205v8.033ZM1108.426,406.084c0-1.338,0-2.677-.122-3.955-3.347,3.104-7.424,4.442-11.075,5.659-4.503,1.46-5.599,3.651-5.599,6.146,0,3.042,3.104,5.051,7.242,5.051,5.051,0,8.701-2.434,9.554-3.042v-9.858Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1120.051,412.778v-18.925h-3.59v-.974h3.59v-3.895c0-3.53-3.164-3.59-3.59-3.59v-.608s5.902-2.86,6.572-2.86c1.399,0,1.399,1.096,1.399,2.86v8.093h8.032v.974h-8.032v19.655c0,4.016,2.434,5.781,4.868,5.781,3.104,0,4.746-1.947,4.746-1.947l.853.487s-1.947,2.434-6.694,2.434-8.154-2.617-8.154-7.485Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M570.565,471.508c0-12.961,9.128-21.785,20.689-21.785,10.467,0,17.404,6.633,17.404,12.049,0,1.887-1.156,2.799-2.312,2.799-1.218,0-2.435-.974-2.435-2.799,0-7.241-6.693-11.014-11.927-11.014-8.154,0-16.612,7.12-16.612,20.75,0,12.292,8.884,20.021,17.16,20.021,5.111,0,9.006-2.13,11.379-3.956v-9.432c0-3.895-2.799-5.111-3.59-5.111v-.974h11.927v.974c-.791,0-3.59,1.217-3.59,5.111v15.152s-.487-4.564-2.921-4.564c-2.738,0-6.572,4.564-14.849,4.564-11.257,0-20.324-9.736-20.324-21.785Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M628.743,465.91c7.363,0,13.388,6.146,13.388,13.691s-6.024,13.692-13.388,13.692c-7.424,0-13.448-6.146-13.448-13.692s6.024-13.691,13.448-13.691ZM628.743,466.884c-4.99,0-9.006,5.842-9.006,12.779,0,6.998,4.016,12.657,9.006,12.657,4.929,0,9.006-5.659,9.006-12.657,0-6.937-4.077-12.779-9.006-12.779Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M644.142,492.32c.852,0,3.59-1.217,3.59-5.172v-31.217c0-3.408-3.164-3.468-3.59-3.468v-.609s5.902-2.86,6.572-2.86c1.46,0,1.46,1.095,1.46,2.86v35.294c0,3.956,2.738,5.172,3.591,5.172v.974h-11.623v-.974Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M678.706,490.068s-2.799,3.225-8.215,3.225c-7.059,0-12.718-6.146-12.718-13.692s5.659-13.691,12.718-13.691c5.112,0,8.215,3.225,8.215,3.225v-13.083c0-3.53-3.164-3.59-3.59-3.59v-.609s5.902-2.86,6.572-2.86c1.399,0,1.399,1.095,1.399,2.86v35.294c0,3.956,2.738,5.172,3.59,5.172v.974h-7.972v-3.225ZM678.706,488.608v-18.012s-3.042-3.712-7.728-3.712c-5.781,0-8.823,5.659-8.823,12.657,0,6.937,3.103,12.779,8.884,12.779,4.686,0,7.667-3.712,7.667-3.712Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M724.836,465.91c5.233,0,10.71,3.104,10.71,10.162v11.075c0,3.956,2.738,5.172,3.591,5.172v.974h-11.562v-.974c.853,0,3.591-1.217,3.591-5.172v-12.049c0-5.477-2.373-8.215-6.877-8.215-4.198,0-7.18,2.799-8.641,4.503.608,1.339.974,2.86.974,4.686v11.075c0,3.956,2.8,5.172,3.591,5.172v.974h-11.562v-.974c.853,0,3.591-1.217,3.591-5.172v-12.049c0-5.477-2.374-8.215-6.815-8.215-4.868,0-7.668,3.408-7.729,3.468v16.795c0,3.956,2.799,5.172,3.651,5.172v.974h-11.623v-.974c.852,0,3.59-1.217,3.59-5.172v-14.239c0-3.469-3.164-3.53-3.59-3.53v-.547s5.902-2.921,6.572-2.921c1.399,0,1.399,1.156,1.399,2.921v.243c.061,0,2.981-3.164,8.215-3.164,3.591,0,7.241,1.46,9.25,4.625,1.217-1.46,4.441-4.625,9.675-4.625Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M766.707,487.147c0,3.956,2.8,5.172,3.591,5.172v.974h-7.972v-3.164c-1.582.974-5.599,3.103-10.345,3.103-5.781,0-10.832-2.495-10.832-6.268,0-4.381,5.173-5.598,9.676-7.12,3.712-1.156,7.972-2.617,11.196-5.903-.608-4.016-2.495-7.059-7.667-7.059-3.286,0-6.268,2.373-6.268,6.633,0,1.46-1.156,2.19-2.312,2.19-1.096,0-2.252-.73-2.252-2.19,0-3.408,4.686-7.606,11.258-7.606,6.876,0,11.927,3.773,11.927,13.205v8.033ZM762.326,479.115c0-1.338,0-2.677-.122-3.955-3.347,3.104-7.424,4.442-11.075,5.659-4.503,1.46-5.599,3.651-5.599,6.146,0,3.042,3.104,5.051,7.242,5.051,5.051,0,8.701-2.434,9.554-3.042v-9.858Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M781.316,469.074c.061,0,2.981-3.164,8.215-3.164,5.173,0,10.71,3.104,10.71,10.162v11.075c0,3.956,2.738,5.172,3.591,5.172v.974h-11.562v-.974c.791,0,3.591-1.217,3.591-5.172v-12.049c0-5.477-2.435-8.215-6.877-8.215-4.807,0-7.606,3.408-7.667,3.468v16.795c0,3.956,2.738,5.172,3.59,5.172v.974h-11.562v-.974c.852,0,3.59-1.217,3.59-5.172v-14.118c0-3.59-3.164-3.651-3.59-3.651v-.547s5.902-2.921,6.572-2.921c1.399,0,1.399,1.156,1.399,2.921v.243Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M819.048,460.677c0-8.337,8.945-10.893,15.639-10.893,6.877,0,15.761,5.051,15.761,9.919,0,1.826-1.582,2.799-3.104,2.799s-3.042-.913-3.042-2.86c0-4.077-3.348-8.884-10.527-8.884-5.173,0-9.433,2.982-9.433,7.85,0,11.075,27.749,12.292,27.749,24.219,0,7.667-8.52,10.467-16.248,10.467-8.275,0-16.43-5.051-16.43-9.919,0-1.886,1.521-2.799,2.981-2.799,1.582,0,3.104.974,3.104,2.86,0,4.077,3.104,8.884,11.075,8.884,6.268,0,10.223-2.556,10.223-7.485,0-10.771-27.748-11.927-27.748-24.158Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M880.694,487.147c0,3.956,2.8,5.172,3.591,5.172v.974h-7.972v-3.164c-1.582.974-5.599,3.103-10.345,3.103-5.781,0-10.832-2.495-10.832-6.268,0-4.381,5.173-5.598,9.676-7.12,3.712-1.156,7.972-2.617,11.196-5.903-.608-4.016-2.495-7.059-7.667-7.059-3.286,0-6.268,2.373-6.268,6.633,0,1.46-1.156,2.19-2.312,2.19-1.096,0-2.252-.73-2.252-2.19,0-3.408,4.686-7.606,11.258-7.606,6.876,0,11.927,3.773,11.927,13.205v8.033ZM876.313,479.115c0-1.338,0-2.677-.122-3.955-3.347,3.104-7.424,4.442-11.075,5.659-4.503,1.46-5.599,3.651-5.599,6.146,0,3.042,3.104,5.051,7.242,5.051,5.051,0,8.701-2.434,9.554-3.042v-9.858Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M938.324,487.208c0,3.895,2.8,5.111,3.651,5.111v.974h-11.622v-.974c.852,0,3.59-1.217,3.59-5.111v-12.11c0-5.477-2.373-8.154-6.876-8.154-4.808,0-7.606,3.408-7.668,3.408v16.856c0,3.895,2.738,5.111,3.591,5.111v.974h-11.562v-.974c.853,0,3.591-1.217,3.591-5.111v-31.156c0-3.286-2.8-3.408-3.469-3.408h-.122c-4.686,0-7.362,1.826-7.362,4.929,0,3.347,5.111,12.17,5.111,15.943,0,1.521-1.096,2.251-2.252,2.251s-2.312-.791-2.312-2.251c0-4.26-2.616-6.572-5.902-6.572-5.111,0-9.067,5.294-9.067,12.657,0,6.998,4.26,12.353,9.493,12.353s8.337-3.895,8.337-3.895l.791.487c-2.312,2.921-6.39,4.747-10.284,4.747-6.998,0-12.718-6.085-12.718-13.692,0-8.093,5.72-13.631,12.962-13.631,3.895,0,6.997,1.4,8.884,3.286-1.277-3.347-4.016-9.128-4.016-11.684,0-3.712,3.104-5.903,8.336-5.903,0,0,5.903-2.677,6.572-2.677,1.399,0,1.399,1.095,1.399,2.86v17.282c.062-.061,2.982-3.165,8.216-3.165s10.709,3.104,10.709,10.102v11.136Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M968.27,486.66c0,4.868-6.269,6.633-11.928,6.633-6.085,0-12.109-3.225-12.109-6.268,0-1.156,1.096-1.765,2.19-1.765s2.252.608,2.252,1.825c0,2.556,2.312,5.172,8.215,5.172,4.625,0,6.755-1.704,6.755-4.32,0-5.598-19.655-7.363-19.655-15.152,0-5.233,6.572-6.876,11.501-6.876,5.051,0,11.562,3.225,11.562,6.268,0,1.156-1.156,1.704-2.251,1.704-1.156,0-2.252-.548-2.252-1.765,0-2.556-2.434-5.233-7.789-5.233-3.772,0-6.146,1.947-6.146,4.564,0,5.416,19.655,7.484,19.655,15.213Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M983.486,492.32c.852,0,3.59-1.217,3.59-5.172v-14.239c0-3.469-3.164-3.53-3.59-3.53v-.547s5.902-2.921,6.572-2.921c1.46,0,1.46,1.156,1.46,2.921v18.316c0,3.956,2.738,5.172,3.591,5.172v.974h-11.623v-.974ZM989.267,460.738c-1.461,0-2.678-1.217-2.678-2.678,0-1.521,1.217-2.738,2.678-2.738,1.521,0,2.738,1.217,2.738,2.738,0,1.46-1.217,2.678-2.738,2.678Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1006.124,469.074c.061,0,2.981-3.164,8.215-3.164,5.173,0,10.71,3.104,10.71,10.162v11.075c0,3.956,2.738,5.172,3.591,5.172v.974h-11.562v-.974c.791,0,3.591-1.217,3.591-5.172v-12.049c0-5.477-2.435-8.215-6.877-8.215-4.807,0-7.606,3.408-7.667,3.468v16.795c0,3.956,2.738,5.172,3.59,5.172v.974h-11.562v-.974c.852,0,3.59-1.217,3.59-5.172v-14.118c0-3.59-3.164-3.651-3.59-3.651v-.547s5.902-2.921,6.572-2.921c1.399,0,1.399,1.156,1.399,2.921v.243Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1052.011,493.293h-8.154v-.974c.852,0,3.59-1.217,3.59-5.111v-31.339c0-3.895-2.738-5.112-3.59-5.112v-.974h6.633l24.706,35.233v-29.148c0-3.895-2.738-5.112-3.591-5.112v-.974h8.216v.974c-.853,0-3.591,1.217-3.591,5.112v37.424h-1.034l-26.775-38.093v32.008c0,3.895,2.739,5.111,3.591,5.111v.974Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1088.404,455.869s-3.529-5.112-5.537-5.112v-.974h12.962v.974c-.609,0-2.13,1.339-2.13,3.104,0,.608.183,1.339.669,2.008l11.136,14.848,12.536-16.795c.669-.913.852-1.521.852-2.008,0-.913-.974-1.156-1.521-1.156v-.974h6.024v.974c-2.008,0-5.537,5.112-5.537,5.112l-11.745,15.7v15.639c0,3.895,2.738,5.111,3.591,5.111v.974h-11.988v-.974c.853,0,3.591-1.217,3.591-5.111v-14.057l-12.901-17.282Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1123.399,471.508c0-12.961,9.188-21.785,20.689-21.785,10.467,0,17.404,6.633,17.404,12.049,0,1.887-1.156,2.799-2.312,2.799-1.218,0-2.435-.974-2.435-2.799,0-7.241-6.693-11.014-11.927-11.014-8.154,0-16.612,7.12-16.612,20.75,0,12.292,8.884,20.021,17.16,20.021,8.763,0,13.874-6.268,13.874-6.268l.852.486c-3.712,4.625-10.223,7.546-16.369,7.546-11.196,0-20.324-9.736-20.324-21.785Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                  </g>
                  <g>
                    <path
                      d="M959.306,716.358c5.969,0,9.893,3.813,9.893,6.908,0,1.326-1.05,1.989-2.045,1.989-1.05,0-2.1-.663-2.1-1.989,0-3.869-2.377-6.024-5.361-6.024-4.643,0-8.18,4.809-8.18,11.551,0,6.301,3.869,11.164,8.566,11.164,4.753,0,7.572-3.537,7.572-3.537l.773.498c-2.155,2.598-5.858,4.311-9.34,4.311-6.411,0-11.606-5.582-11.606-12.436,0-7.351,5.25-12.435,11.827-12.435Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M984.174,716.358c6.688,0,12.159,5.582,12.159,12.435s-5.472,12.436-12.159,12.436c-6.742,0-12.214-5.582-12.214-12.436s5.472-12.435,12.214-12.435ZM984.174,717.241c-4.531,0-8.18,5.306-8.18,11.606,0,6.355,3.648,11.496,8.18,11.496,4.477,0,8.18-5.141,8.18-11.496,0-6.301-3.703-11.606-8.18-11.606Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M999.094,720.668h14.093v.885h-14.093v-.885Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1023.189,712.931v3.427h7.295v.884h-7.295v18.57c0,3.481,2.542,4.532,3.261,4.532v.884h-10.501v-.884c.773,0,3.261-1.051,3.261-4.532v-18.57h-3.261v-.884h3.261v-3.427c0-7.903,4.366-11.938,10.556-11.938,5.97,0,9.894,3.758,9.894,6.853,0,1.382-1.051,2.045-2.045,2.045-1.051,0-2.101-.663-2.101-2.045,0-3.868-2.376-5.969-5.36-5.969-6.521,0-6.964,5.804-6.964,11.054Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1045.458,716.358c6.688,0,12.159,5.582,12.159,12.435s-5.472,12.436-12.159,12.436c-6.742,0-12.214-5.582-12.214-12.436s5.472-12.435,12.214-12.435ZM1045.458,717.241c-4.531,0-8.18,5.306-8.18,11.606,0,6.355,3.648,11.496,8.18,11.496,4.477,0,8.18-5.141,8.18-11.496,0-6.301-3.703-11.606-8.18-11.606Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1079.889,738.354c-.056,0-2.708,2.874-7.461,2.874-4.698,0-9.728-2.818-9.728-9.229v-9.175c0-3.261-2.874-3.315-3.261-3.315v-.498s5.361-2.652,5.969-2.652c1.271,0,1.271,1.05,1.271,2.652v13.872c0,4.975,2.211,7.462,6.245,7.462,4.366,0,6.908-3.096,6.964-3.15v-14.37c0-3.261-2.874-3.315-3.261-3.315v-.498s5.36-2.652,5.969-2.652c1.271,0,1.271,1.05,1.271,2.652v15.752c0,3.261,2.874,3.315,3.261,3.315v.497s-5.36,2.653-5.969,2.653c-1.271,0-1.271-1.05-1.271-2.653v-.221Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1097.129,719.232c.055,0,2.708-2.874,7.461-2.874,4.697,0,9.727,2.818,9.727,9.229v10.059c0,3.593,2.487,4.698,3.261,4.698v.884h-10.5v-.884c.718,0,3.261-1.105,3.261-4.698v-10.943c0-4.974-2.211-7.461-6.245-7.461-4.366,0-6.909,3.096-6.964,3.15v15.254c0,3.593,2.486,4.698,3.261,4.698v.884h-10.501v-.884c.773,0,3.261-1.105,3.261-4.698v-12.822c0-3.261-2.874-3.315-3.261-3.315v-.498s5.361-2.652,5.969-2.652c1.271,0,1.271,1.05,1.271,2.652v.222Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1138.41,738.299s-2.542,2.929-7.461,2.929c-6.411,0-11.551-5.582-11.551-12.436s5.14-12.435,11.551-12.435c4.643,0,7.461,2.929,7.461,2.929v-11.882c0-3.206-2.874-3.261-3.261-3.261v-.553s5.361-2.598,5.969-2.598c1.271,0,1.271.994,1.271,2.598v32.055c0,3.593,2.487,4.698,3.261,4.698v.884h-7.24v-2.929ZM1138.41,736.972v-16.358s-2.763-3.372-7.019-3.372c-5.251,0-8.014,5.141-8.014,11.496,0,6.3,2.818,11.606,8.068,11.606,4.256,0,6.964-3.372,6.964-3.372Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1151.506,727.908v.884c0,6.301,3.869,11.164,8.566,11.164,4.753,0,7.572-3.537,7.572-3.537l.718.498c-2.1,2.598-5.803,4.311-9.284,4.311-6.411,0-11.606-5.582-11.606-12.436s5.195-12.435,11.606-12.435c6.079,0,11.108,5.084,11.495,11.551h-19.067ZM1151.562,727.024h14.978c-.553-5.471-3.703-9.782-7.461-9.782-3.813,0-6.964,4.256-7.517,9.782Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1192.346,738.299s-2.542,2.929-7.461,2.929c-6.411,0-11.551-5.582-11.551-12.436s5.14-12.435,11.551-12.435c4.643,0,7.461,2.929,7.461,2.929v-11.882c0-3.206-2.874-3.261-3.261-3.261v-.553s5.361-2.598,5.969-2.598c1.271,0,1.271.994,1.271,2.598v32.055c0,3.593,2.487,4.698,3.261,4.698v.884h-7.24v-2.929ZM1192.346,736.972v-16.358s-2.763-3.372-7.019-3.372c-5.251,0-8.014,5.141-8.014,11.496,0,6.3,2.818,11.606,8.068,11.606,4.256,0,6.964-3.372,6.964-3.372Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1213.4,740.344c.773,0,3.261-1.105,3.261-4.643v-28.463c0-3.537-2.487-4.643-3.261-4.643v-.885h12.822c11.164,0,20.614,8.843,20.614,19.786,0,10.888-8.953,19.73-20.614,19.73h-12.822v-.884ZM1221.027,740.344h4.421c10.557,0,17.022-8.456,17.022-18.847,0-10.445-6.853-18.901-17.022-18.901h-4.421v37.748Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1271.868,735.646c0,3.593,2.543,4.698,3.261,4.698v.884h-7.239v-2.874c-1.438.885-5.085,2.818-9.396,2.818-5.251,0-9.838-2.266-9.838-5.692,0-3.979,4.697-5.084,8.787-6.466,3.372-1.051,7.24-2.377,10.17-5.361-.553-3.647-2.267-6.411-6.964-6.411-2.984,0-5.692,2.155-5.692,6.024,0,1.326-1.051,1.989-2.101,1.989-.995,0-2.045-.663-2.045-1.989,0-3.095,4.256-6.908,10.225-6.908,6.245,0,10.832,3.427,10.832,11.993v7.295ZM1267.89,728.351c0-1.216,0-2.432-.111-3.593-3.039,2.818-6.742,4.034-10.059,5.14-4.09,1.327-5.084,3.316-5.084,5.582,0,2.764,2.818,4.588,6.576,4.588,4.588,0,7.903-2.211,8.678-2.764v-8.953Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1278.444,734.43v-17.188h-3.261v-.884h3.261v-3.537c0-3.206-2.874-3.261-3.261-3.261v-.553s5.361-2.598,5.969-2.598c1.271,0,1.271.995,1.271,2.598v7.351h7.295v.884h-7.295v17.852c0,3.647,2.211,5.251,4.421,5.251,2.819,0,4.312-1.77,4.312-1.77l.773.442s-1.769,2.211-6.079,2.211-7.406-2.377-7.406-6.798Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1296.846,727.908v.884c0,6.301,3.869,11.164,8.566,11.164,4.753,0,7.572-3.537,7.572-3.537l.718.498c-2.1,2.598-5.803,4.311-9.284,4.311-6.411,0-11.606-5.582-11.606-12.436s5.195-12.435,11.606-12.435c6.079,0,11.108,5.084,11.495,11.551h-19.067ZM1296.902,727.024h14.978c-.553-5.471-3.703-9.782-7.461-9.782-3.813,0-6.964,4.256-7.517,9.782Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1337.686,738.299s-2.542,2.929-7.461,2.929c-6.411,0-11.551-5.582-11.551-12.436s5.14-12.435,11.551-12.435c4.643,0,7.461,2.929,7.461,2.929v-11.882c0-3.206-2.874-3.261-3.261-3.261v-.553s5.361-2.598,5.969-2.598c1.271,0,1.271.994,1.271,2.598v32.055c0,3.593,2.487,4.698,3.261,4.698v.884h-7.24v-2.929ZM1337.686,736.972v-16.358s-2.763-3.372-7.019-3.372c-5.251,0-8.014,5.141-8.014,11.496,0,6.3,2.818,11.606,8.068,11.606,4.256,0,6.964-3.372,6.964-3.372Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                  </g>
                  <g>
                    <path
                      d="M934.227,888.936s5.361-2.652,5.969-2.652c1.271,0,1.271,1.05,1.271,2.652v.276s2.818-2.929,7.461-2.929c6.411,0,11.606,5.582,11.606,12.435s-5.195,12.436-11.606,12.436c-4.919,0-7.461-2.929-7.461-2.929v12.711c0,3.593,2.542,4.698,3.315,4.698v.884h-10.556v-.884c.773,0,3.261-1.105,3.261-4.698v-28.297c0-3.15-2.874-3.205-3.261-3.205v-.498ZM941.467,906.898s2.708,3.372,6.964,3.372c5.25,0,8.068-5.307,8.068-11.606,0-6.355-2.763-11.496-8.014-11.496-4.256,0-7.019,3.372-7.019,3.372v16.358Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M969.65,890.981c.442-.607,3.537-4.697,9.23-4.697,3.481,0,7.902,1.823,7.902,5.195,0,1.271-.994,1.879-1.934,1.879-1.051,0-2.045-.663-2.045-1.879,0-3.261-2.211-4.312-4.422-4.312-5.25,0-8.29,4.588-8.732,5.306v13.099c0,3.593,2.487,4.698,3.261,4.698v.884h-10.556v-.884c.773,0,3.261-1.105,3.261-4.698v-12.933c0-3.15-2.874-3.205-3.261-3.205v-.498s5.361-2.652,5.969-2.652c1.326,0,1.326,1.05,1.326,2.652v2.045Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1000.818,886.283c6.688,0,12.159,5.582,12.159,12.435s-5.472,12.436-12.159,12.436c-6.742,0-12.214-5.582-12.214-12.436s5.472-12.435,12.214-12.435ZM1000.818,887.167c-4.531,0-8.18,5.306-8.18,11.606,0,6.355,3.648,11.496,8.18,11.496,4.477,0,8.18-5.141,8.18-11.496,0-6.301-3.703-11.606-8.18-11.606Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1034.749,908.225s-2.542,2.929-7.461,2.929c-6.411,0-11.551-5.582-11.551-12.436s5.14-12.435,11.551-12.435c4.643,0,7.461,2.929,7.461,2.929v-11.882c0-3.206-2.874-3.261-3.261-3.261v-.553s5.361-2.598,5.969-2.598c1.271,0,1.271.994,1.271,2.598v32.055c0,3.593,2.487,4.698,3.261,4.698v.884h-7.24v-2.929ZM1034.749,906.898v-16.358s-2.763-3.372-7.019-3.372c-5.251,0-8.014,5.141-8.014,11.496,0,6.3,2.818,11.606,8.068,11.606,4.256,0,6.964-3.372,6.964-3.372Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1063.376,908.28c-.056,0-2.708,2.874-7.461,2.874-4.698,0-9.728-2.818-9.728-9.229v-9.175c0-3.261-2.874-3.315-3.261-3.315v-.498s5.361-2.652,5.969-2.652c1.271,0,1.271,1.05,1.271,2.652v13.872c0,4.975,2.211,7.462,6.245,7.462,4.366,0,6.908-3.096,6.964-3.15v-14.37c0-3.261-2.874-3.315-3.261-3.315v-.498s5.36-2.652,5.969-2.652c1.271,0,1.271,1.05,1.271,2.652v15.752c0,3.261,2.874,3.315,3.261,3.315v.497s-5.36,2.653-5.969,2.653c-1.271,0-1.271-1.05-1.271-2.653v-.221Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1113.115,908.556l.773.442s-1.769,2.155-6.079,2.155-7.406-2.321-7.406-6.742v-17.188h-3.261v-.885h3.261v-3.592c0-3.206-2.874-3.206-3.261-3.206-3.592,0-5.416,1.161-5.416,3.427,0,2.874,3.371,6.854,3.371,10.225,0,1.382-.994,2.045-2.045,2.045s-2.1-.719-2.1-2.045c0-3.869-2.377-5.969-5.361-5.969-4.643,0-8.18,4.809-8.18,11.495,0,6.356,3.869,11.22,8.566,11.22,4.809,0,7.572-3.537,7.572-3.537l.773.442c-2.155,2.652-5.858,4.311-9.34,4.311-6.411,0-11.606-5.526-11.606-12.436,0-7.351,5.25-12.38,11.827-12.38,3.316,0,6.024,1.161,7.793,2.764-.995-2.1-2.155-4.256-2.155-6.135,0-1.989,1.105-4.311,6.3-4.311,0,0,5.361-2.321,5.969-2.321,1.271,0,1.271.995,1.271,2.598v7.405h7.295v.885h-7.295v17.852c0,3.592,2.211,5.195,4.422,5.195,2.873,0,4.311-1.714,4.311-1.714Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1159.589,886.283c4.753,0,9.727,2.818,9.727,9.229v10.059c0,3.593,2.487,4.698,3.261,4.698v.884h-10.501v-.884c.774,0,3.261-1.105,3.261-4.698v-10.943c0-4.974-2.155-7.461-6.245-7.461-3.813,0-6.521,2.543-7.848,4.09.553,1.216.884,2.598.884,4.256v10.059c0,3.593,2.543,4.698,3.261,4.698v.884h-10.5v-.884c.773,0,3.261-1.105,3.261-4.698v-10.943c0-4.974-2.155-7.461-6.19-7.461-4.421,0-6.964,3.096-7.019,3.15v15.254c0,3.593,2.542,4.698,3.315,4.698v.884h-10.556v-.884c.773,0,3.261-1.105,3.261-4.698v-12.933c0-3.15-2.874-3.205-3.261-3.205v-.498s5.361-2.652,5.969-2.652c1.271,0,1.271,1.05,1.271,2.652v.222c.055,0,2.708-2.874,7.461-2.874,3.261,0,6.577,1.326,8.4,4.2,1.105-1.326,4.035-4.2,8.788-4.2Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1197.609,905.572c0,3.593,2.543,4.698,3.261,4.698v.884h-7.239v-2.874c-1.438.885-5.085,2.818-9.396,2.818-5.251,0-9.838-2.266-9.838-5.692,0-3.979,4.697-5.084,8.787-6.466,3.372-1.051,7.24-2.377,10.17-5.361-.553-3.647-2.267-6.411-6.964-6.411-2.984,0-5.692,2.155-5.692,6.024,0,1.326-1.051,1.989-2.101,1.989-.995,0-2.045-.663-2.045-1.989,0-3.095,4.256-6.908,10.225-6.908,6.245,0,10.832,3.427,10.832,11.993v7.295ZM1193.63,898.277c0-1.216,0-2.432-.111-3.593-3.039,2.818-6.742,4.034-10.059,5.14-4.09,1.327-5.084,3.316-5.084,5.582,0,2.764,2.818,4.588,6.576,4.588,4.588,0,7.903-2.211,8.678-2.764v-8.953Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1210.87,889.158c.055,0,2.708-2.874,7.461-2.874,4.697,0,9.727,2.818,9.727,9.229v10.059c0,3.593,2.487,4.698,3.261,4.698v.884h-10.5v-.884c.718,0,3.261-1.105,3.261-4.698v-10.943c0-4.974-2.211-7.461-6.245-7.461-4.366,0-6.909,3.096-6.964,3.15v15.254c0,3.593,2.487,4.698,3.261,4.698v.884h-10.501v-.884c.773,0,3.261-1.105,3.261-4.698v-12.822c0-3.261-2.874-3.315-3.261-3.315v-.498s5.361-2.652,5.969-2.652c1.271,0,1.271,1.05,1.271,2.652v.222Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1256.352,905.572c0,3.593,2.543,4.698,3.261,4.698v.884h-7.239v-2.874c-1.438.885-5.085,2.818-9.396,2.818-5.251,0-9.838-2.266-9.838-5.692,0-3.979,4.697-5.084,8.787-6.466,3.372-1.051,7.24-2.377,10.17-5.361-.553-3.647-2.267-6.411-6.964-6.411-2.984,0-5.692,2.155-5.692,6.024,0,1.326-1.051,1.989-2.101,1.989-.995,0-2.045-.663-2.045-1.989,0-3.095,4.256-6.908,10.225-6.908,6.245,0,10.832,3.427,10.832,11.993v7.295ZM1252.373,898.277c0-1.216,0-2.432-.111-3.593-3.039,2.818-6.742,4.034-10.059,5.14-4.09,1.327-5.084,3.316-5.084,5.582,0,2.764,2.818,4.588,6.576,4.588,4.588,0,7.903-2.211,8.678-2.764v-8.953Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1291.113,918.836c0,3.371-2.708,7.682-13.982,7.682-10.446,0-13.541-4.09-13.541-6.577,0-1.713,1.161-2.486,2.321-2.486s2.321.773,2.321,2.155c0,3.261,2.652,6.024,8.4,6.024,8.512,0,10.28-4.256,10.28-7.074,0-2.874-2.432-5.306-5.527-5.306-5.084,0-7.737,2.155-12.767,2.155-3.813,0-6.245-1.714-6.245-4.532,0-2.598,2.652-4.2,5.526-4.532-2.21-2.1-3.537-5.084-3.537-8.4,0-6.466,5.085-11.661,11.386-11.661,2.708,0,5.25.994,7.185,2.652,0-.11,1.216-4.421,4.477-4.421,1.16,0,1.713.829,1.713,1.658s-.607,1.713-1.713,1.713c-2.874,0-3.593,1.713-3.647,1.769,2.1,2.101,3.371,5.029,3.371,8.29,0,6.411-5.085,11.606-11.385,11.606-2.543,0-4.864-.829-6.743-2.267-2.1,0-3.924,1.271-3.924,3.593,0,1.824,1.548,3.647,4.034,3.647,4.919,0,6.854-2.211,12.768-2.211,6.687,0,9.229,3.593,9.229,6.522ZM1268.342,898c0,5.858,3.316,10.666,7.406,10.666s7.405-4.808,7.405-10.666c0-5.914-3.315-10.833-7.405-10.833s-7.406,4.919-7.406,10.833Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1295.145,897.834v.884c0,6.301,3.869,11.164,8.566,11.164,4.753,0,7.572-3.537,7.572-3.537l.718.498c-2.1,2.598-5.803,4.311-9.284,4.311-6.411,0-11.606-5.582-11.606-12.436s5.195-12.435,11.606-12.435c6.079,0,11.108,5.084,11.495,11.551h-19.067ZM1295.2,896.949h14.978c-.553-5.471-3.703-9.782-7.461-9.782-3.813,0-6.964,4.256-7.517,9.782Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1324.268,890.981c.442-.607,3.537-4.697,9.23-4.697,3.481,0,7.902,1.823,7.902,5.195,0,1.271-.994,1.879-1.934,1.879-1.051,0-2.045-.663-2.045-1.879,0-3.261-2.211-4.312-4.422-4.312-5.25,0-8.29,4.588-8.732,5.306v13.099c0,3.593,2.487,4.698,3.261,4.698v.884h-10.556v-.884c.773,0,3.261-1.105,3.261-4.698v-12.933c0-3.15-2.874-3.205-3.261-3.205v-.498s5.361-2.652,5.969-2.652c1.326,0,1.326,1.05,1.326,2.652v2.045Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M957.439,971.887c0,3.593,2.543,4.697,3.261,4.697v.885h-7.239v-2.874c-1.438.885-5.085,2.818-9.396,2.818-5.251,0-9.838-2.266-9.838-5.692,0-3.979,4.697-5.084,8.787-6.466,3.372-1.05,7.24-2.377,10.17-5.361-.553-3.647-2.267-6.411-6.964-6.411-2.984,0-5.692,2.155-5.692,6.024,0,1.326-1.051,1.989-2.101,1.989-.995,0-2.045-.663-2.045-1.989,0-3.095,4.256-6.908,10.225-6.908,6.245,0,10.832,3.427,10.832,11.993v7.295ZM953.46,964.592c0-1.216,0-2.432-.111-3.593-3.039,2.818-6.742,4.034-10.059,5.14-4.09,1.327-5.084,3.316-5.084,5.582,0,2.764,2.818,4.588,6.576,4.588,4.588,0,7.903-2.211,8.678-2.764v-8.953Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M964.013,970.671v-17.188h-3.261v-.884h3.261v-3.537c0-3.206-2.874-3.261-3.261-3.261v-.553s5.361-2.598,5.969-2.598c1.271,0,1.271.995,1.271,2.598v7.351h7.295v.884h-7.295v17.852c0,3.647,2.211,5.25,4.421,5.25,2.819,0,4.312-1.768,4.312-1.768l.773.441s-1.769,2.211-6.079,2.211-7.406-2.377-7.406-6.798Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M991.313,976.584c.773,0,3.261-1.104,3.261-4.642v-28.463c0-3.537-2.487-4.643-3.261-4.643v-.885l14.093-.055c9.009,0,13.651,3.924,13.651,8.732,0,3.647-2.652,6.742-7.682,8.068,7.295.939,11.605,5.638,11.605,11.33,0,6.301-5.029,11.44-14.369,11.44h-17.299v-.885ZM1004.688,938.782h-5.748v15.752h5.748c6.688,0,10.003-3.537,10.003-7.903,0-4.312-3.315-7.849-10.003-7.849ZM998.94,955.417v21.167l7.019.056c8.346,0,12.767-4.753,12.767-10.611s-4.2-10.611-12.767-10.611h-7.019Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1022.978,976.584c.773,0,3.261-1.104,3.261-4.697v-28.353c0-3.095-2.874-3.149-3.261-3.149v-.553s5.361-2.598,5.969-2.598c1.326,0,1.326.994,1.326,2.598v32.055c0,3.593,2.487,4.697,3.261,4.697v.885h-10.556v-.885Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1047.571,952.599c6.688,0,12.159,5.582,12.159,12.435s-5.472,12.436-12.159,12.436c-6.742,0-12.214-5.582-12.214-12.436s5.472-12.435,12.214-12.435ZM1047.571,953.483c-4.531,0-8.18,5.306-8.18,11.606,0,6.355,3.648,11.495,8.18,11.495,4.477,0,8.18-5.14,8.18-11.495,0-6.301-3.703-11.606-8.18-11.606Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1074.704,952.599c6.688,0,12.159,5.582,12.159,12.435s-5.472,12.436-12.159,12.436c-6.742,0-12.214-5.582-12.214-12.436s5.472-12.435,12.214-12.435ZM1074.704,953.483c-4.531,0-8.18,5.306-8.18,11.606,0,6.355,3.648,11.495,8.18,11.495,4.477,0,8.18-5.14,8.18-11.495,0-6.301-3.703-11.606-8.18-11.606Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1120.575,952.599c4.753,0,9.727,2.818,9.727,9.229v10.059c0,3.593,2.487,4.697,3.261,4.697v.885h-10.501v-.885c.774,0,3.261-1.104,3.261-4.697v-10.943c0-4.974-2.155-7.461-6.245-7.461-3.813,0-6.521,2.543-7.848,4.09.553,1.216.884,2.598.884,4.256v10.059c0,3.593,2.543,4.697,3.261,4.697v.885h-10.5v-.885c.773,0,3.261-1.104,3.261-4.697v-10.943c0-4.974-2.155-7.461-6.19-7.461-4.421,0-6.964,3.096-7.019,3.15v15.254c0,3.593,2.542,4.697,3.315,4.697v.885h-10.556v-.885c.773,0,3.261-1.104,3.261-4.697v-12.933c0-3.15-2.874-3.205-3.261-3.205v-.498s5.361-2.652,5.969-2.652c1.271,0,1.271,1.05,1.271,2.652v.222c.055,0,2.708-2.874,7.461-2.874,3.261,0,6.577,1.326,8.4,4.2,1.105-1.326,4.035-4.2,8.788-4.2Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1160.695,965.033c0,6.854-5.195,12.436-11.551,12.436-4.809,0-6.301-2.432-8.346-2.432-1.547,0-3.095,2.432-3.095,2.432v-33.823c0-3.206-2.874-3.261-3.261-3.261v-.553s5.361-2.598,5.969-2.598c1.271,0,1.271.994,1.271,2.598v15.695s2.874-2.929,7.461-2.929c6.355,0,11.551,5.582,11.551,12.435ZM1156.715,964.979c0-6.355-2.818-11.496-8.068-11.496-4.201,0-6.964,3.372-6.964,3.372v16.358s2.652,3.371,6.964,3.371c5.194,0,8.068-5.305,8.068-11.605Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1167.49,964.15v.884c0,6.301,3.869,11.164,8.566,11.164,4.753,0,7.572-3.537,7.572-3.537l.718.498c-2.1,2.598-5.803,4.311-9.284,4.311-6.411,0-11.606-5.582-11.606-12.436s5.195-12.435,11.606-12.435c6.079,0,11.108,5.084,11.495,11.551h-19.067ZM1167.545,963.265h14.978c-.553-5.471-3.703-9.782-7.461-9.782-3.813,0-6.964,4.256-7.517,9.782Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1196.613,957.296c.442-.607,3.537-4.697,9.23-4.697,3.481,0,7.902,1.823,7.902,5.195,0,1.271-.994,1.879-1.934,1.879-1.051,0-2.045-.663-2.045-1.879,0-3.261-2.211-4.312-4.422-4.312-5.25,0-8.29,4.588-8.732,5.306v13.099c0,3.593,2.487,4.697,3.261,4.697v.885h-10.556v-.885c.773,0,3.261-1.104,3.261-4.697v-12.933c0-3.15-2.874-3.205-3.261-3.205v-.498s5.361-2.652,5.969-2.652c1.326,0,1.326,1.05,1.326,2.652v2.045Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1243.422,985.152c0,3.371-2.708,7.682-13.982,7.682-10.446,0-13.541-4.09-13.541-6.577,0-1.713,1.161-2.486,2.321-2.486s2.321.773,2.321,2.155c0,3.261,2.652,6.024,8.4,6.024,8.512,0,10.28-4.256,10.28-7.074,0-2.874-2.433-5.306-5.527-5.306-5.084,0-7.737,2.155-12.767,2.155-3.813,0-6.245-1.714-6.245-4.532,0-2.598,2.653-4.2,5.526-4.532-2.21-2.1-3.537-5.084-3.537-8.4,0-6.466,5.085-11.661,11.386-11.661,2.708,0,5.25.995,7.185,2.652,0-.11,1.216-4.421,4.477-4.421,1.16,0,1.713.829,1.713,1.658s-.607,1.713-1.713,1.713c-2.874,0-3.593,1.713-3.647,1.769,2.1,2.101,3.371,5.029,3.371,8.29,0,6.411-5.085,11.606-11.385,11.606-2.543,0-4.864-.829-6.743-2.267-2.1,0-3.924,1.271-3.924,3.593,0,1.824,1.548,3.647,4.034,3.647,4.919,0,6.854-2.211,12.768-2.211,6.687,0,9.229,3.593,9.229,6.522ZM1220.652,964.316c0,5.858,3.316,10.666,7.406,10.666s7.405-4.808,7.405-10.666c0-5.914-3.315-10.833-7.405-10.833s-7.406,4.919-7.406,10.833Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                  </g>
                  <g>
                    <path
                      d="M1004.237,1316.114c5.939,0,9.844,3.795,9.844,6.874,0,1.32-1.045,1.98-2.035,1.98-1.045,0-2.09-.66-2.09-1.98,0-3.849-2.364-5.994-5.334-5.994-4.619,0-8.139,4.785-8.139,11.494,0,6.269,3.85,11.108,8.523,11.108,4.729,0,7.534-3.52,7.534-3.52l.77.495c-2.145,2.584-5.829,4.289-9.293,4.289-6.38,0-11.549-5.555-11.549-12.373,0-7.314,5.225-12.374,11.769-12.374Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1028.986,1316.114c6.654,0,12.099,5.555,12.099,12.374s-5.444,12.373-12.099,12.373c-6.709,0-12.153-5.555-12.153-12.373s5.444-12.374,12.153-12.374ZM1028.986,1316.993c-4.51,0-8.139,5.279-8.139,11.549,0,6.324,3.629,11.438,8.139,11.438,4.454,0,8.139-5.114,8.139-11.438,0-6.27-3.685-11.549-8.139-11.549Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1043.837,1320.404h14.023v.88h-14.023v-.88Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M935.489,1378.704v3.409h7.259v.88h-7.259v18.478c0,3.465,2.529,4.51,3.244,4.51v.88h-10.448v-.88c.77,0,3.244-1.045,3.244-4.51v-18.478h-3.244v-.88h3.244v-3.409c0-7.864,4.345-11.879,10.504-11.879,5.939,0,9.844,3.74,9.844,6.819,0,1.375-1.045,2.035-2.035,2.035-1.044,0-2.089-.66-2.089-2.035,0-3.85-2.365-5.938-5.335-5.938-6.489,0-6.929,5.773-6.929,10.998Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M957.653,1382.114c6.654,0,12.099,5.555,12.099,12.374s-5.444,12.373-12.099,12.373c-6.709,0-12.153-5.555-12.153-12.373s5.444-12.374,12.153-12.374ZM957.653,1382.993c-4.51,0-8.139,5.279-8.139,11.549,0,6.324,3.629,11.438,8.139,11.438,4.454,0,8.139-5.114,8.139-11.438,0-6.27-3.685-11.549-8.139-11.549Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M991.916,1404.001c-.056,0-2.695,2.859-7.425,2.859-4.674,0-9.679-2.805-9.679-9.184v-9.129c0-3.244-2.859-3.3-3.244-3.3v-.495s5.334-2.64,5.939-2.64c1.265,0,1.265,1.045,1.265,2.64v13.804c0,4.949,2.199,7.424,6.214,7.424,4.345,0,6.874-3.079,6.93-3.135v-14.298c0-3.244-2.86-3.3-3.245-3.3v-.495s5.334-2.64,5.939-2.64c1.265,0,1.265,1.045,1.265,2.64v15.673c0,3.245,2.859,3.3,3.245,3.3v.495s-5.335,2.64-5.939,2.64c-1.265,0-1.265-1.045-1.265-2.64v-.22Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1009.077,1384.974c.055,0,2.694-2.86,7.424-2.86,4.675,0,9.679,2.805,9.679,9.185v10.008c0,3.575,2.475,4.675,3.245,4.675v.88h-10.449v-.88c.715,0,3.245-1.1,3.245-4.675v-10.888c0-4.95-2.2-7.425-6.215-7.425-4.344,0-6.874,3.08-6.929,3.135v15.178c0,3.575,2.475,4.675,3.244,4.675v.88h-10.448v-.88c.77,0,3.244-1.1,3.244-4.675v-12.758c0-3.244-2.859-3.3-3.244-3.3v-.495s5.334-2.64,5.939-2.64c1.265,0,1.265,1.045,1.265,2.64v.221Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1050.161,1403.946s-2.53,2.915-7.425,2.915c-6.379,0-11.493-5.555-11.493-12.373s5.114-12.374,11.493-12.374c4.62,0,7.425,2.915,7.425,2.915v-11.823c0-3.189-2.86-3.245-3.245-3.245v-.55s5.334-2.585,5.939-2.585c1.265,0,1.265.99,1.265,2.585v31.896c0,3.575,2.475,4.675,3.244,4.675v.88h-7.203v-2.915ZM1050.161,1402.626v-16.278s-2.75-3.354-6.984-3.354c-5.225,0-7.975,5.114-7.975,11.438,0,6.27,2.805,11.549,8.029,11.549,4.234,0,6.93-3.354,6.93-3.354Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1063.198,1393.608v.88c0,6.269,3.85,11.108,8.523,11.108,4.729,0,7.534-3.52,7.534-3.52l.715.495c-2.09,2.584-5.774,4.289-9.238,4.289-6.38,0-11.549-5.555-11.549-12.373s5.169-12.374,11.549-12.374c6.049,0,11.053,5.06,11.438,11.494h-18.973ZM1063.252,1392.728h14.903c-.55-5.444-3.685-9.734-7.424-9.734-3.795,0-6.93,4.234-7.479,9.734Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1103.839,1403.946s-2.529,2.915-7.424,2.915c-6.379,0-11.493-5.555-11.493-12.373s5.114-12.374,11.493-12.374c4.62,0,7.424,2.915,7.424,2.915v-11.823c0-3.189-2.859-3.245-3.244-3.245v-.55s5.334-2.585,5.939-2.585c1.265,0,1.265.99,1.265,2.585v31.896c0,3.575,2.475,4.675,3.244,4.675v.88h-7.204v-2.915ZM1103.839,1402.626v-16.278s-2.749-3.354-6.983-3.354c-5.225,0-7.974,5.114-7.974,11.438,0,6.27,2.804,11.549,8.028,11.549,4.234,0,6.929-3.354,6.929-3.354Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M947.337,1480.504c0,3.354-2.694,7.645-13.913,7.645-10.394,0-13.473-4.069-13.473-6.544,0-1.705,1.154-2.475,2.31-2.475s2.31.77,2.31,2.145c0,3.244,2.64,5.994,8.359,5.994,8.469,0,10.229-4.234,10.229-7.039,0-2.859-2.42-5.279-5.499-5.279-5.06,0-7.699,2.145-12.704,2.145-3.794,0-6.214-1.705-6.214-4.51,0-2.584,2.64-4.179,5.499-4.509-2.199-2.09-3.52-5.06-3.52-8.359,0-6.434,5.06-11.604,11.329-11.604,2.694,0,5.224.99,7.148,2.64,0-.109,1.21-4.399,4.455-4.399,1.154,0,1.704.825,1.704,1.65s-.604,1.704-1.704,1.704c-2.86,0-3.575,1.705-3.63,1.76,2.09,2.09,3.354,5.005,3.354,8.249,0,6.38-5.06,11.549-11.328,11.549-2.53,0-4.84-.825-6.709-2.255-2.09,0-3.905,1.265-3.905,3.574,0,1.815,1.54,3.63,4.015,3.63,4.895,0,6.819-2.199,12.703-2.199,6.654,0,9.184,3.574,9.184,6.488ZM924.681,1459.773c0,5.829,3.299,10.613,7.369,10.613s7.369-4.784,7.369-10.613c0-5.885-3.3-10.779-7.369-10.779s-7.369,4.895-7.369,10.779Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M959.495,1448.114c6.654,0,12.099,5.555,12.099,12.374s-5.444,12.373-12.099,12.373c-6.709,0-12.153-5.555-12.153-12.373s5.444-12.374,12.153-12.374ZM959.495,1448.993c-4.51,0-8.139,5.279-8.139,11.549,0,6.324,3.629,11.438,8.139,11.438,4.454,0,8.139-5.114,8.139-11.438,0-6.27-3.685-11.549-8.139-11.549Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M986.5,1448.114c6.654,0,12.099,5.555,12.099,12.374s-5.444,12.373-12.099,12.373c-6.709,0-12.153-5.555-12.153-12.373s5.444-12.374,12.153-12.374ZM986.5,1448.993c-4.51,0-8.139,5.279-8.139,11.549,0,6.324,3.629,11.438,8.139,11.438,4.454,0,8.139-5.114,8.139-11.438,0-6.27-3.685-11.549-8.139-11.549Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1020.269,1469.946s-2.529,2.915-7.424,2.915c-6.379,0-11.493-5.555-11.493-12.373s5.114-12.374,11.493-12.374c4.62,0,7.424,2.915,7.424,2.915v-11.823c0-3.189-2.859-3.245-3.244-3.245v-.55s5.334-2.585,5.939-2.585c1.265,0,1.265.99,1.265,2.585v31.896c0,3.575,2.475,4.675,3.244,4.675v.88h-7.204v-2.915ZM1020.269,1468.626v-16.278s-2.749-3.354-6.983-3.354c-5.225,0-7.974,5.114-7.974,11.438,0,6.27,2.804,11.549,8.028,11.549,4.234,0,6.929-3.354,6.929-3.354Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1051.234,1466.866c0,4.399-5.664,5.994-10.779,5.994-5.499,0-10.943-2.915-10.943-5.664,0-1.045.99-1.595,1.98-1.595s2.034.55,2.034,1.649c0,2.31,2.09,4.675,7.424,4.675,4.18,0,6.104-1.54,6.104-3.905,0-5.059-17.763-6.653-17.763-13.692,0-4.729,5.939-6.215,10.394-6.215,4.564,0,10.448,2.915,10.448,5.665,0,1.045-1.044,1.539-2.034,1.539-1.045,0-2.035-.494-2.035-1.595,0-2.31-2.199-4.729-7.039-4.729-3.409,0-5.554,1.76-5.554,4.125,0,4.895,17.763,6.764,17.763,13.748Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1058,1459.608v.88c0,6.269,3.85,11.108,8.523,11.108,4.729,0,7.534-3.52,7.534-3.52l.715.495c-2.09,2.584-5.774,4.289-9.238,4.289-6.38,0-11.549-5.555-11.549-12.373s5.169-12.374,11.549-12.374c6.049,0,11.053,5.06,11.438,11.494h-18.973ZM1058.055,1458.728h14.903c-.55-5.444-3.685-9.734-7.424-9.734-3.795,0-6.93,4.234-7.479,9.734Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1083.74,1459.608v.88c0,6.269,3.85,11.108,8.523,11.108,4.729,0,7.534-3.52,7.534-3.52l.715.495c-2.09,2.584-5.774,4.289-9.238,4.289-6.38,0-11.549-5.555-11.549-12.373s5.169-12.374,11.549-12.374c6.049,0,11.053,5.06,11.438,11.494h-18.973ZM1083.794,1458.728h14.903c-.55-5.444-3.685-9.734-7.424-9.734-3.795,0-6.93,4.234-7.479,9.734Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1124.382,1469.946s-2.53,2.915-7.425,2.915c-6.379,0-11.493-5.555-11.493-12.373s5.114-12.374,11.493-12.374c4.62,0,7.425,2.915,7.425,2.915v-11.823c0-3.189-2.86-3.245-3.245-3.245v-.55s5.334-2.585,5.939-2.585c1.265,0,1.265.99,1.265,2.585v31.896c0,3.575,2.475,4.675,3.244,4.675v.88h-7.203v-2.915ZM1124.382,1468.626v-16.278s-2.75-3.354-6.984-3.354c-5.225,0-7.975,5.114-7.975,11.438,0,6.27,2.805,11.549,8.029,11.549,4.234,0,6.93-3.354,6.93-3.354Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M943.464,1516.753s5.334-2.64,5.939-2.64c1.265,0,1.265,1.045,1.265,2.64v.275s2.805-2.915,7.424-2.915c6.379,0,11.549,5.555,11.549,12.374s-5.17,12.373-11.549,12.373c-4.895,0-7.424-2.915-7.424-2.915v12.648c0,3.575,2.529,4.675,3.3,4.675v.88h-10.504v-.88c.77,0,3.244-1.1,3.244-4.675v-28.156c0-3.134-2.859-3.189-3.244-3.189v-.495ZM950.668,1534.626s2.694,3.354,6.929,3.354c5.225,0,8.029-5.279,8.029-11.549,0-6.324-2.75-11.438-7.974-11.438-4.234,0-6.984,3.354-6.984,3.354v16.278Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M978.717,1518.788c.44-.605,3.52-4.675,9.184-4.675,3.465,0,7.864,1.815,7.864,5.17,0,1.265-.99,1.869-1.925,1.869-1.045,0-2.034-.659-2.034-1.869,0-3.245-2.2-4.29-4.399-4.29-5.225,0-8.249,4.564-8.689,5.279v13.033c0,3.575,2.475,4.675,3.245,4.675v.88h-10.504v-.88c.77,0,3.244-1.1,3.244-4.675v-12.868c0-3.134-2.859-3.189-3.244-3.189v-.495s5.334-2.64,5.939-2.64c1.319,0,1.319,1.045,1.319,2.64v2.035Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1009.737,1514.114c6.654,0,12.099,5.555,12.099,12.374s-5.444,12.373-12.099,12.373c-6.709,0-12.153-5.555-12.153-12.373s5.444-12.374,12.153-12.374ZM1009.737,1514.993c-4.51,0-8.139,5.279-8.139,11.549,0,6.324,3.629,11.438,8.139,11.438,4.454,0,8.139-5.114,8.139-11.438,0-6.27-3.685-11.549-8.139-11.549Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1026.018,1532.097v-17.104h-3.244v-.88h3.244v-3.52c0-3.189-2.859-3.244-3.244-3.244v-.55s5.334-2.585,5.939-2.585c1.265,0,1.265.99,1.265,2.585v7.313h7.259v.88h-7.259v17.763c0,3.63,2.199,5.225,4.399,5.225,2.805,0,4.289-1.76,4.289-1.76l.771.44s-1.76,2.199-6.05,2.199-7.369-2.364-7.369-6.764Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1044.333,1525.608v.88c0,6.269,3.85,11.108,8.523,11.108,4.729,0,7.534-3.52,7.534-3.52l.715.495c-2.09,2.584-5.774,4.289-9.238,4.289-6.38,0-11.549-5.555-11.549-12.373s5.169-12.374,11.549-12.374c6.049,0,11.053,5.06,11.438,11.494h-18.973ZM1044.388,1524.728h14.903c-.55-5.444-3.685-9.734-7.424-9.734-3.795,0-6.93,4.234-7.479,9.734Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1066.058,1537.981c.77,0,3.244-1.1,3.244-4.675v-12.868c0-3.134-2.859-3.189-3.244-3.189v-.495s5.334-2.64,5.939-2.64c1.319,0,1.319,1.045,1.319,2.64v16.553c0,3.575,2.475,4.675,3.245,4.675v.88h-10.504v-.88ZM1071.283,1509.44c-1.32,0-2.42-1.1-2.42-2.42,0-1.375,1.1-2.475,2.42-2.475,1.375,0,2.475,1.1,2.475,2.475,0,1.32-1.1,2.42-2.475,2.42Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1086.517,1516.974c.055,0,2.694-2.86,7.424-2.86,4.675,0,9.679,2.805,9.679,9.185v10.008c0,3.575,2.475,4.675,3.245,4.675v.88h-10.449v-.88c.715,0,3.245-1.1,3.245-4.675v-10.888c0-4.95-2.2-7.425-6.215-7.425-4.344,0-6.874,3.08-6.929,3.135v15.178c0,3.575,2.475,4.675,3.244,4.675v.88h-10.448v-.88c.77,0,3.244-1.1,3.244-4.675v-12.758c0-3.244-2.859-3.3-3.244-3.3v-.495s5.334-2.64,5.939-2.64c1.265,0,1.265,1.045,1.265,2.64v.221Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                  </g>
                  <g>
                    <path
                      d="M1380.908,1411.586c5.939,0,9.844,3.795,9.844,6.874,0,1.32-1.045,1.98-2.035,1.98-1.045,0-2.09-.66-2.09-1.98,0-3.849-2.364-5.994-5.334-5.994-4.619,0-8.139,4.785-8.139,11.494,0,6.269,3.85,11.108,8.523,11.108,4.729,0,7.534-3.52,7.534-3.52l.77.495c-2.145,2.584-5.829,4.289-9.293,4.289-6.38,0-11.549-5.555-11.549-12.373,0-7.314,5.225-12.374,11.769-12.374Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1405.658,1411.586c6.654,0,12.099,5.555,12.099,12.374s-5.444,12.373-12.099,12.373c-6.709,0-12.153-5.555-12.153-12.373s5.444-12.374,12.153-12.374ZM1405.658,1412.466c-4.51,0-8.139,5.279-8.139,11.549,0,6.324,3.629,11.438,8.139,11.438,4.454,0,8.139-5.114,8.139-11.438,0-6.27-3.685-11.549-8.139-11.549Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1420.509,1415.876h14.023v.88h-14.023v-.88Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1444.489,1408.177v3.409h7.259v.88h-7.259v18.478c0,3.465,2.529,4.51,3.244,4.51v.88h-10.448v-.88c.77,0,3.244-1.045,3.244-4.51v-18.478h-3.244v-.88h3.244v-3.409c0-7.864,4.345-11.879,10.504-11.879,5.939,0,9.844,3.74,9.844,6.819,0,1.375-1.045,2.035-2.035,2.035-1.044,0-2.089-.66-2.089-2.035,0-3.85-2.365-5.938-5.335-5.938-6.489,0-6.929,5.773-6.929,10.998Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1466.652,1411.586c6.654,0,12.099,5.555,12.099,12.374s-5.444,12.373-12.099,12.373c-6.709,0-12.153-5.555-12.153-12.373s5.444-12.374,12.153-12.374ZM1466.652,1412.466c-4.51,0-8.139,5.279-8.139,11.549,0,6.324,3.629,11.438,8.139,11.438,4.454,0,8.139-5.114,8.139-11.438,0-6.27-3.685-11.549-8.139-11.549Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1500.916,1433.474c-.056,0-2.695,2.859-7.425,2.859-4.674,0-9.679-2.805-9.679-9.184v-9.129c0-3.244-2.859-3.3-3.244-3.3v-.495s5.334-2.64,5.939-2.64c1.265,0,1.265,1.045,1.265,2.64v13.804c0,4.949,2.199,7.424,6.214,7.424,4.345,0,6.874-3.079,6.93-3.135v-14.298c0-3.244-2.86-3.3-3.245-3.3v-.495s5.334-2.64,5.939-2.64c1.265,0,1.265,1.045,1.265,2.64v15.673c0,3.245,2.859,3.3,3.245,3.3v.495s-5.335,2.64-5.939,2.64c-1.265,0-1.265-1.045-1.265-2.64v-.22Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1518.077,1414.447c.055,0,2.694-2.86,7.424-2.86,4.675,0,9.679,2.805,9.679,9.185v10.008c0,3.575,2.475,4.675,3.245,4.675v.88h-10.449v-.88c.715,0,3.245-1.1,3.245-4.675v-10.888c0-4.95-2.2-7.425-6.215-7.425-4.344,0-6.874,3.08-6.929,3.135v15.178c0,3.575,2.475,4.675,3.244,4.675v.88h-10.448v-.88c.77,0,3.244-1.1,3.244-4.675v-12.758c0-3.244-2.859-3.3-3.244-3.3v-.495s5.334-2.64,5.939-2.64c1.265,0,1.265,1.045,1.265,2.64v.221Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1559.159,1433.418s-2.529,2.915-7.424,2.915c-6.379,0-11.493-5.555-11.493-12.373s5.114-12.374,11.493-12.374c4.62,0,7.424,2.915,7.424,2.915v-11.823c0-3.189-2.859-3.245-3.244-3.245v-.55s5.334-2.585,5.939-2.585c1.265,0,1.265.99,1.265,2.585v31.896c0,3.575,2.475,4.675,3.244,4.675v.88h-7.204v-2.915ZM1559.159,1432.099v-16.278s-2.749-3.354-6.983-3.354c-5.225,0-7.974,5.114-7.974,11.438,0,6.27,2.804,11.549,8.028,11.549,4.234,0,6.929-3.354,6.929-3.354Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1572.198,1423.08v.88c0,6.269,3.85,11.108,8.523,11.108,4.729,0,7.534-3.52,7.534-3.52l.715.495c-2.09,2.584-5.774,4.289-9.238,4.289-6.38,0-11.549-5.555-11.549-12.373s5.169-12.374,11.549-12.374c6.049,0,11.053,5.06,11.438,11.494h-18.973ZM1572.252,1422.2h14.903c-.55-5.444-3.685-9.734-7.424-9.734-3.795,0-6.93,4.234-7.479,9.734Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1612.839,1433.418s-2.529,2.915-7.424,2.915c-6.379,0-11.493-5.555-11.493-12.373s5.114-12.374,11.493-12.374c4.62,0,7.424,2.915,7.424,2.915v-11.823c0-3.189-2.859-3.245-3.244-3.245v-.55s5.334-2.585,5.939-2.585c1.265,0,1.265.99,1.265,2.585v31.896c0,3.575,2.475,4.675,3.244,4.675v.88h-7.204v-2.915ZM1612.839,1432.099v-16.278s-2.749-3.354-6.983-3.354c-5.225,0-7.974,5.114-7.974,11.438,0,6.27,2.804,11.549,8.028,11.549,4.234,0,6.929-3.354,6.929-3.354Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1633.796,1435.453c.715,0,3.244-1.1,3.244-4.619v-28.321c0-3.52-2.529-4.619-3.244-4.619v-.88h10.834v.88c-.771,0-3.3,1.1-3.3,4.619v28.321c0,3.52,2.529,4.619,3.3,4.619v.88h-10.834v-.88Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1654.585,1414.447c.055,0,2.694-2.86,7.424-2.86,4.675,0,9.679,2.805,9.679,9.185v10.008c0,3.575,2.475,4.675,3.245,4.675v.88h-10.449v-.88c.715,0,3.245-1.1,3.245-4.675v-10.888c0-4.95-2.2-7.425-6.215-7.425-4.344,0-6.874,3.08-6.929,3.135v15.178c0,3.575,2.475,4.675,3.244,4.675v.88h-10.448v-.88c.77,0,3.244-1.1,3.244-4.675v-12.758c0-3.244-2.859-3.3-3.244-3.3v-.495s5.334-2.64,5.939-2.64c1.265,0,1.265,1.045,1.265,2.64v.221Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1717.941,1434.189s-1.76,2.145-6.05,2.145-7.368-2.31-7.368-6.709v-17.103h-3.245v-.88h3.245v-3.685c0-3.08-2.86-3.135-3.245-3.135-5.004,0-5.444,2.585-5.444,3.354,0,2.529,2.75,4.675,2.75,9.129,0,1.045-.99,1.595-2.035,1.595-.989,0-2.034-.55-2.034-1.649,0-2.365-2.255-4.729-7.039-4.729-3.465,0-5.609,1.704-5.609,4.124,0,4.895,17.817,6.765,17.817,13.748,0,4.345-5.664,5.939-10.834,5.939-5.499,0-10.943-2.859-10.943-5.609,0-1.045.99-1.595,2.035-1.595.989,0,2.034.55,2.034,1.595,0,2.365,2.035,4.729,7.369,4.729,4.18,0,6.104-1.54,6.104-3.96,0-5.004-17.763-6.599-17.763-13.692,0-4.729,5.939-6.159,10.394-6.159,3.354,0,7.424,1.539,9.349,3.409-.66-2.805-2.42-4.399-2.42-6.874,0-2.035,1.65-4.234,6.27-4.234,0,0,5.334-2.255,5.939-2.255,1.319,0,1.319.99,1.319,2.585v7.369h7.26v.88h-7.26v17.763c0,3.574,2.145,5.169,4.399,5.169,2.805,0,4.29-1.705,4.29-1.705l.715.44Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1743.792,1430.779c0,3.575,2.53,4.675,3.245,4.675v.88h-7.204v-2.859c-1.43.88-5.06,2.805-9.349,2.805-5.225,0-9.789-2.255-9.789-5.664,0-3.96,4.675-5.06,8.744-6.435,3.354-1.045,7.204-2.364,10.118-5.334-.55-3.63-2.255-6.38-6.929-6.38-2.97,0-5.664,2.146-5.664,5.994,0,1.32-1.045,1.98-2.09,1.98-.99,0-2.035-.66-2.035-1.98,0-3.079,4.234-6.874,10.174-6.874,6.214,0,10.778,3.41,10.778,11.934v7.259ZM1739.833,1423.52c0-1.21,0-2.42-.11-3.574-3.024,2.805-6.709,4.015-10.009,5.114-4.069,1.319-5.059,3.3-5.059,5.555,0,2.749,2.804,4.563,6.544,4.563,4.564,0,7.863-2.199,8.634-2.749v-8.909Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1756.994,1414.447c.055,0,2.694-2.86,7.424-2.86,4.675,0,9.679,2.805,9.679,9.185v10.008c0,3.575,2.475,4.675,3.245,4.675v.88h-10.449v-.88c.715,0,3.245-1.1,3.245-4.675v-10.888c0-4.95-2.2-7.425-6.215-7.425-4.344,0-6.874,3.08-6.929,3.135v15.178c0,3.575,2.475,4.675,3.244,4.675v.88h-10.448v-.88c.77,0,3.244-1.1,3.244-4.675v-12.758c0-3.244-2.859-3.3-3.244-3.3v-.495s5.334-2.64,5.939-2.64c1.265,0,1.265,1.045,1.265,2.64v.221Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1780.59,1429.57v-17.104h-3.244v-.88h3.244v-3.52c0-3.189-2.859-3.244-3.244-3.244v-.55s5.334-2.585,5.939-2.585c1.265,0,1.265.99,1.265,2.585v7.313h7.259v.88h-7.259v17.763c0,3.63,2.199,5.225,4.399,5.225,2.805,0,4.289-1.76,4.289-1.76l.771.44s-1.76,2.199-6.05,2.199-7.369-2.364-7.369-6.764Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1416.163,1502.333h-7.369v-.88c.77,0,3.244-1.1,3.244-4.619v-28.321c0-3.52-2.475-4.619-3.244-4.619v-.88h5.994l22.327,31.841v-26.342c0-3.52-2.475-4.619-3.245-4.619v-.88h7.425v.88c-.771,0-3.245,1.1-3.245,4.619v33.82h-.935l-24.197-34.426v28.927c0,3.52,2.476,4.619,3.245,4.619v.88Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1454.388,1477.586c6.654,0,12.099,5.555,12.099,12.374s-5.444,12.373-12.099,12.373c-6.709,0-12.153-5.555-12.153-12.373s5.444-12.374,12.153-12.374ZM1454.388,1478.466c-4.51,0-8.139,5.279-8.139,11.549,0,6.324,3.629,11.438,8.139,11.438,4.454,0,8.139-5.114,8.139-11.438,0-6.27-3.685-11.549-8.139-11.549Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1481.393,1477.586c6.654,0,12.099,5.555,12.099,12.374s-5.444,12.373-12.099,12.373c-6.709,0-12.153-5.555-12.153-12.373s5.444-12.374,12.153-12.374ZM1481.393,1478.466c-4.51,0-8.139,5.279-8.139,11.549,0,6.324,3.629,11.438,8.139,11.438,4.454,0,8.139-5.114,8.139-11.438,0-6.27-3.685-11.549-8.139-11.549Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1515.161,1499.418s-2.529,2.915-7.424,2.915c-6.379,0-11.493-5.555-11.493-12.373s5.114-12.374,11.493-12.374c4.62,0,7.424,2.915,7.424,2.915v-11.823c0-3.189-2.859-3.245-3.244-3.245v-.55s5.334-2.585,5.939-2.585c1.265,0,1.265.99,1.265,2.585v31.896c0,3.575,2.475,4.675,3.244,4.675v.88h-7.204v-2.915ZM1515.161,1498.099v-16.278s-2.749-3.354-6.983-3.354c-5.225,0-7.974,5.114-7.974,11.438,0,6.27,2.804,11.549,8.028,11.549,4.234,0,6.929-3.354,6.929-3.354Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1525.118,1501.453c.77,0,3.244-1.1,3.244-4.675v-28.211c0-3.079-2.859-3.135-3.244-3.135v-.55s5.334-2.585,5.939-2.585c1.319,0,1.319.99,1.319,2.585v31.896c0,3.575,2.475,4.675,3.245,4.675v.88h-10.504v-.88Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1541.453,1489.08v.88c0,6.269,3.85,11.108,8.523,11.108,4.729,0,7.534-3.52,7.534-3.52l.715.495c-2.09,2.584-5.774,4.289-9.238,4.289-6.38,0-11.549-5.555-11.549-12.373s5.169-12.374,11.549-12.374c6.049,0,11.053,5.06,11.438,11.494h-18.973ZM1541.508,1488.2h14.903c-.55-5.444-3.685-9.734-7.424-9.734-3.795,0-6.93,4.234-7.479,9.734Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1574.178,1501.453c.77,0,3.244-1.1,3.244-4.619v-28.321c0-3.52-2.475-4.619-3.244-4.619v-.88h28.211l1.32,4.729s-3.08-3.849-7.259-3.849h-14.684v18.367h11.384c3.52,0,4.619-2.529,4.619-3.3h.88v7.424h-.88c0-.77-1.1-3.244-4.619-3.244h-11.384v13.693c0,3.52,2.475,4.619,3.245,4.619v.88h-10.834v-.88Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1624.06,1496.779c0,3.575,2.53,4.675,3.245,4.675v.88h-7.204v-2.859c-1.43.88-5.06,2.805-9.349,2.805-5.225,0-9.789-2.255-9.789-5.664,0-3.96,4.675-5.06,8.744-6.435,3.354-1.045,7.204-2.364,10.118-5.334-.55-3.63-2.255-6.38-6.929-6.38-2.97,0-5.664,2.146-5.664,5.994,0,1.32-1.045,1.98-2.09,1.98-.99,0-2.035-.66-2.035-1.98,0-3.079,4.234-6.874,10.174-6.874,6.214,0,10.778,3.41,10.778,11.934v7.259ZM1620.101,1489.52c0-1.21,0-2.42-.11-3.574-3.024,2.805-6.709,4.015-10.009,5.114-4.069,1.319-5.059,3.3-5.059,5.555,0,2.749,2.804,4.563,6.544,4.563,4.564,0,7.863-2.199,8.634-2.749v-8.909Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1667.728,1499.748l.77.44s-1.76,2.145-6.049,2.145-7.369-2.31-7.369-6.709v-17.103h-3.245v-.88h3.245v-3.575c0-3.189-2.859-3.189-3.245-3.189-3.574,0-5.389,1.155-5.389,3.41,0,2.859,3.354,6.818,3.354,10.173,0,1.375-.99,2.035-2.035,2.035s-2.09-.715-2.09-2.035c0-3.849-2.364-5.938-5.334-5.938-4.619,0-8.139,4.784-8.139,11.438,0,6.324,3.85,11.163,8.523,11.163,4.784,0,7.534-3.52,7.534-3.52l.77.44c-2.145,2.64-5.829,4.289-9.293,4.289-6.38,0-11.549-5.499-11.549-12.373,0-7.314,5.225-12.318,11.769-12.318,3.3,0,5.994,1.154,7.754,2.749-.99-2.09-2.145-4.234-2.145-6.104,0-1.98,1.1-4.29,6.269-4.29,0,0,5.335-2.31,5.939-2.31,1.265,0,1.265.99,1.265,2.585v7.369h7.26v.88h-7.26v17.763c0,3.574,2.2,5.169,4.399,5.169,2.86,0,4.29-1.705,4.29-1.705Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1681.591,1477.586c6.654,0,12.099,5.555,12.099,12.374s-5.444,12.373-12.099,12.373c-6.709,0-12.153-5.555-12.153-12.373s5.444-12.374,12.153-12.374ZM1681.591,1478.466c-4.51,0-8.139,5.279-8.139,11.549,0,6.324,3.629,11.438,8.139,11.438,4.454,0,8.139-5.114,8.139-11.438,0-6.27-3.685-11.549-8.139-11.549Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1702.766,1482.261c.44-.605,3.52-4.675,9.184-4.675,3.465,0,7.864,1.815,7.864,5.17,0,1.265-.99,1.869-1.925,1.869-1.045,0-2.034-.659-2.034-1.869,0-3.245-2.2-4.29-4.399-4.29-5.225,0-8.249,4.564-8.689,5.279v13.033c0,3.575,2.475,4.675,3.245,4.675v.88h-10.504v-.88c.77,0,3.244-1.1,3.244-4.675v-12.868c0-3.134-2.859-3.189-3.244-3.189v-.495s5.334-2.64,5.939-2.64c1.319,0,1.319,1.045,1.319,2.64v2.035Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1747.864,1477.586v.88c-2.31,0-4.069,4.675-4.069,4.675l-11.219,25.132s-3.904,9.349-7.809,9.349c-1.87,0-2.75-1.045-2.75-2.09s.936-2.145,2.695-2.145c4.949,0,6.929-5.114,6.929-5.114l2.64-5.939-8.579-19.192s-1.705-4.675-4.069-4.675v-.88h10.504v.88c-.605,0-2.255,1.32-2.255,3.245,0,.439.055.935.274,1.43l6.324,14.188,7.149-15.948c.275-.66.385-1.21.385-1.595,0-1.155-.989-1.32-1.54-1.32v-.88h5.39Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1754.356,1500.463c0,1.431-1.484,3.3-1.76,3.3,0,0-.055,0-.055-.055,0-.165.22-.715.22-1.045,0-.165-.055-.33-.274-.33-.99,0-1.87-.825-1.87-1.87s.88-1.869,1.87-1.869c1.045,0,1.869.824,1.869,1.869Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1408.656,1568.333h-7.369v-.88c.77,0,3.244-1.1,3.244-4.619v-28.321c0-3.52-2.475-4.619-3.244-4.619v-.88h5.994l22.327,31.841v-26.342c0-3.52-2.475-4.619-3.245-4.619v-.88h7.425v.88c-.771,0-3.245,1.1-3.245,4.619v33.82h-.935l-24.197-34.426v28.927c0,3.52,2.476,4.619,3.245,4.619v.88Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1446.879,1543.586c6.654,0,12.099,5.555,12.099,12.374s-5.444,12.373-12.099,12.373c-6.709,0-12.153-5.555-12.153-12.373s5.444-12.374,12.153-12.374ZM1446.879,1544.466c-4.51,0-8.139,5.279-8.139,11.549,0,6.324,3.629,11.438,8.139,11.438,4.454,0,8.139-5.114,8.139-11.438,0-6.27-3.685-11.549-8.139-11.549Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1473.884,1543.586c6.654,0,12.099,5.555,12.099,12.374s-5.444,12.373-12.099,12.373c-6.709,0-12.153-5.555-12.153-12.373s5.444-12.374,12.153-12.374ZM1473.884,1544.466c-4.51,0-8.139,5.279-8.139,11.549,0,6.324,3.629,11.438,8.139,11.438,4.454,0,8.139-5.114,8.139-11.438,0-6.27-3.685-11.549-8.139-11.549Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1507.654,1565.418s-2.53,2.915-7.425,2.915c-6.379,0-11.493-5.555-11.493-12.373s5.114-12.374,11.493-12.374c4.62,0,7.425,2.915,7.425,2.915v-11.823c0-3.189-2.86-3.245-3.245-3.245v-.55s5.334-2.585,5.939-2.585c1.265,0,1.265.99,1.265,2.585v31.896c0,3.575,2.475,4.675,3.244,4.675v.88h-7.203v-2.915ZM1507.654,1564.099v-16.278s-2.75-3.354-6.984-3.354c-5.225,0-7.975,5.114-7.975,11.438,0,6.27,2.805,11.549,8.029,11.549,4.234,0,6.93-3.354,6.93-3.354Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1517.611,1567.453c.77,0,3.244-1.1,3.244-4.675v-28.211c0-3.079-2.859-3.135-3.244-3.135v-.55s5.334-2.585,5.939-2.585c1.319,0,1.319.99,1.319,2.585v31.896c0,3.575,2.475,4.675,3.245,4.675v.88h-10.504v-.88Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1533.946,1555.08v.88c0,6.269,3.85,11.108,8.523,11.108,4.729,0,7.534-3.52,7.534-3.52l.715.495c-2.09,2.584-5.774,4.289-9.238,4.289-6.38,0-11.549-5.555-11.549-12.373s5.169-12.374,11.549-12.374c6.049,0,11.053,5.06,11.438,11.494h-18.973ZM1534,1554.2h14.903c-.55-5.444-3.685-9.734-7.424-9.734-3.795,0-6.93,4.234-7.479,9.734Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1566.67,1548.646c0-11.713,8.304-19.688,18.697-19.688,9.459,0,15.729,5.994,15.729,10.889,0,1.705-1.045,2.529-2.09,2.529-1.101,0-2.2-.879-2.2-2.529,0-6.544-6.049-9.953-10.778-9.953-7.369,0-15.013,6.434-15.013,18.752,0,11.108,8.028,18.093,15.508,18.093,7.919,0,12.538-5.664,12.538-5.664l.77.439c-3.354,4.18-9.238,6.819-14.793,6.819-10.118,0-18.367-8.799-18.367-19.688Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1602.035,1567.453c.77,0,3.244-1.1,3.244-4.675v-28.211c0-3.079-2.859-3.135-3.244-3.135v-.55s5.334-2.585,5.939-2.585c1.319,0,1.319.99,1.319,2.585v31.896c0,3.575,2.475,4.675,3.245,4.675v.88h-10.504v-.88Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1633.824,1565.474c-.056,0-2.695,2.859-7.425,2.859-4.674,0-9.679-2.805-9.679-9.184v-9.129c0-3.244-2.859-3.3-3.244-3.3v-.495s5.334-2.64,5.939-2.64c1.265,0,1.265,1.045,1.265,2.64v13.804c0,4.949,2.199,7.424,6.214,7.424,4.345,0,6.874-3.079,6.93-3.135v-14.298c0-3.244-2.86-3.3-3.245-3.3v-.495s5.335-2.64,5.939-2.64c1.265,0,1.265,1.045,1.265,2.64v15.673c0,3.245,2.859,3.3,3.245,3.3v.495s-5.335,2.64-5.939,2.64c-1.265,0-1.265-1.045-1.265-2.64v-.22Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1668.032,1555.96c0,6.818-5.169,12.373-11.493,12.373-4.784,0-6.27-2.42-8.304-2.42-1.54,0-3.08,2.42-3.08,2.42v-33.655c0-3.189-2.859-3.245-3.244-3.245v-.55s5.334-2.585,5.939-2.585c1.265,0,1.265.99,1.265,2.585v15.618s2.859-2.915,7.424-2.915c6.324,0,11.493,5.555,11.493,12.374ZM1664.073,1555.905c0-6.324-2.805-11.438-8.029-11.438-4.179,0-6.929,3.354-6.929,3.354v16.278s2.64,3.354,6.929,3.354c5.17,0,8.029-5.279,8.029-11.549Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1674.525,1566.463c0,1.431-1.484,3.3-1.76,3.3,0,0-.055,0-.055-.055,0-.165.22-.715.22-1.045,0-.165-.055-.33-.274-.33-.99,0-1.87-.825-1.87-1.87s.88-1.869,1.87-1.869c1.045,0,1.869.824,1.869,1.869Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1711.371,1562.779c0,3.575,2.53,4.675,3.245,4.675v.88h-7.204v-2.859c-1.43.88-5.06,2.805-9.349,2.805-5.225,0-9.789-2.255-9.789-5.664,0-3.96,4.675-5.06,8.744-6.435,3.354-1.045,7.204-2.364,10.118-5.334-.55-3.63-2.255-6.38-6.929-6.38-2.97,0-5.664,2.146-5.664,5.994,0,1.32-1.045,1.98-2.09,1.98-.99,0-2.035-.66-2.035-1.98,0-3.079,4.234-6.874,10.174-6.874,6.214,0,10.778,3.41,10.778,11.934v7.259ZM1707.412,1555.52c0-1.21,0-2.42-.11-3.574-3.024,2.805-6.709,4.015-10.009,5.114-4.069,1.319-5.06,3.3-5.06,5.555,0,2.749,2.805,4.563,6.545,4.563,4.564,0,7.863-2.199,8.634-2.749v-8.909Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1724.574,1546.447c.055,0,2.694-2.86,7.424-2.86,4.675,0,9.679,2.805,9.679,9.185v10.008c0,3.575,2.475,4.675,3.245,4.675v.88h-10.449v-.88c.715,0,3.245-1.1,3.245-4.675v-10.888c0-4.95-2.2-7.425-6.215-7.425-4.344,0-6.874,3.08-6.929,3.135v15.178c0,3.575,2.475,4.675,3.244,4.675v.88h-10.448v-.88c.77,0,3.244-1.1,3.244-4.675v-12.758c0-3.244-2.859-3.3-3.244-3.3v-.495s5.334-2.64,5.939-2.64c1.265,0,1.265,1.045,1.265,2.64v.221Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1765.658,1565.418s-2.53,2.915-7.425,2.915c-6.379,0-11.493-5.555-11.493-12.373s5.114-12.374,11.493-12.374c4.62,0,7.425,2.915,7.425,2.915v-11.823c0-3.189-2.86-3.245-3.245-3.245v-.55s5.334-2.585,5.939-2.585c1.265,0,1.265.99,1.265,2.585v31.896c0,3.575,2.475,4.675,3.244,4.675v.88h-7.203v-2.915ZM1765.658,1564.099v-16.278s-2.75-3.354-6.984-3.354c-5.225,0-7.975,5.114-7.975,11.438,0,6.27,2.805,11.549,8.029,11.549,4.234,0,6.93-3.354,6.93-3.354Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1451.117,1634.333h-7.369v-.88c.77,0,3.244-1.1,3.244-4.619v-28.321c0-3.52-2.475-4.619-3.244-4.619v-.88h5.994l22.327,31.841v-26.342c0-3.52-2.475-4.619-3.245-4.619v-.88h7.425v.88c-.771,0-3.245,1.1-3.245,4.619v33.82h-.935l-24.197-34.426v28.927c0,3.52,2.476,4.619,3.245,4.619v.88Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1489.341,1609.586c6.654,0,12.099,5.555,12.099,12.374s-5.444,12.373-12.099,12.373c-6.709,0-12.153-5.555-12.153-12.373s5.444-12.374,12.153-12.374ZM1489.341,1610.466c-4.51,0-8.139,5.279-8.139,11.549,0,6.324,3.629,11.438,8.139,11.438,4.454,0,8.139-5.114,8.139-11.438,0-6.27-3.685-11.549-8.139-11.549Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1516.346,1609.586c6.654,0,12.099,5.555,12.099,12.374s-5.444,12.373-12.099,12.373c-6.709,0-12.153-5.555-12.153-12.373s5.444-12.374,12.153-12.374ZM1516.346,1610.466c-4.51,0-8.139,5.279-8.139,11.549,0,6.324,3.629,11.438,8.139,11.438,4.454,0,8.139-5.114,8.139-11.438,0-6.27-3.685-11.549-8.139-11.549Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1550.115,1631.418s-2.529,2.915-7.424,2.915c-6.379,0-11.493-5.555-11.493-12.373s5.114-12.374,11.493-12.374c4.62,0,7.424,2.915,7.424,2.915v-11.823c0-3.189-2.859-3.245-3.244-3.245v-.55s5.334-2.585,5.939-2.585c1.265,0,1.265.99,1.265,2.585v31.896c0,3.575,2.475,4.675,3.244,4.675v.88h-7.204v-2.915ZM1550.115,1630.099v-16.278s-2.749-3.354-6.983-3.354c-5.225,0-7.974,5.114-7.974,11.438,0,6.27,2.804,11.549,8.028,11.549,4.234,0,6.929-3.354,6.929-3.354Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1560.072,1633.453c.77,0,3.244-1.1,3.244-4.675v-28.211c0-3.079-2.859-3.135-3.244-3.135v-.55s5.334-2.585,5.939-2.585c1.319,0,1.319.99,1.319,2.585v31.896c0,3.575,2.475,4.675,3.245,4.675v.88h-10.504v-.88Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1576.407,1621.08v.88c0,6.269,3.85,11.108,8.523,11.108,4.729,0,7.534-3.52,7.534-3.52l.715.495c-2.09,2.584-5.774,4.289-9.238,4.289-6.38,0-11.549-5.555-11.549-12.373s5.169-12.374,11.549-12.374c6.049,0,11.053,5.06,11.438,11.494h-18.973ZM1576.461,1620.2h14.903c-.55-5.444-3.685-9.734-7.424-9.734-3.795,0-6.93,4.234-7.479,9.734Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1597.197,1612.226s5.334-2.64,5.939-2.64c1.265,0,1.265,1.045,1.265,2.64v.275s2.805-2.915,7.424-2.915c6.379,0,11.549,5.555,11.549,12.374s-5.17,12.373-11.549,12.373c-4.895,0-7.424-2.915-7.424-2.915v12.648c0,3.575,2.529,4.675,3.3,4.675v.88h-10.504v-.88c.77,0,3.244-1.1,3.244-4.675v-28.156c0-3.134-2.859-3.189-3.244-3.189v-.495ZM1604.401,1630.099s2.694,3.354,6.929,3.354c5.225,0,8.029-5.279,8.029-11.549,0-6.324-2.75-11.438-7.974-11.438-4.234,0-6.984,3.354-6.984,3.354v16.278Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1630.14,1621.08v.88c0,6.269,3.85,11.108,8.523,11.108,4.729,0,7.534-3.52,7.534-3.52l.715.495c-2.09,2.584-5.774,4.289-9.238,4.289-6.38,0-11.549-5.555-11.549-12.373s5.169-12.374,11.549-12.374c6.049,0,11.053,5.06,11.438,11.494h-18.973ZM1630.195,1620.2h14.903c-.55-5.444-3.685-9.734-7.424-9.734-3.795,0-6.93,4.234-7.479,9.734Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1670.783,1631.418s-2.53,2.915-7.425,2.915c-6.379,0-11.493-5.555-11.493-12.373s5.114-12.374,11.493-12.374c4.62,0,7.425,2.915,7.425,2.915v-11.823c0-3.189-2.86-3.245-3.245-3.245v-.55s5.334-2.585,5.939-2.585c1.265,0,1.265.99,1.265,2.585v31.896c0,3.575,2.475,4.675,3.244,4.675v.88h-7.203v-2.915ZM1670.783,1630.099v-16.278s-2.75-3.354-6.984-3.354c-5.225,0-7.975,5.114-7.975,11.438,0,6.27,2.805,11.549,8.029,11.549,4.234,0,6.93-3.354,6.93-3.354Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1680.74,1633.453c.77,0,3.244-1.1,3.244-4.675v-12.868c0-3.134-2.859-3.189-3.244-3.189v-.495s5.334-2.64,5.939-2.64c1.319,0,1.319,1.045,1.319,2.64v16.553c0,3.575,2.475,4.675,3.245,4.675v.88h-10.504v-.88ZM1685.964,1604.912c-1.32,0-2.42-1.1-2.42-2.42,0-1.375,1.1-2.475,2.42-2.475,1.375,0,2.475,1.1,2.475,2.475,0,1.32-1.1,2.42-2.475,2.42Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1716.156,1628.779c0,3.575,2.53,4.675,3.245,4.675v.88h-7.204v-2.859c-1.43.88-5.06,2.805-9.349,2.805-5.225,0-9.789-2.255-9.789-5.664,0-3.96,4.675-5.06,8.744-6.435,3.354-1.045,7.204-2.364,10.118-5.334-.55-3.63-2.255-6.38-6.929-6.38-2.97,0-5.664,2.146-5.664,5.994,0,1.32-1.045,1.98-2.09,1.98-.99,0-2.035-.66-2.035-1.98,0-3.079,4.234-6.874,10.174-6.874,6.214,0,10.778,3.41,10.778,11.934v7.259ZM1712.197,1621.52c0-1.21,0-2.42-.11-3.574-3.024,2.805-6.709,4.015-10.009,5.114-4.069,1.319-5.059,3.3-5.059,5.555,0,2.749,2.804,4.563,6.544,4.563,4.564,0,7.863-2.199,8.634-2.749v-8.909Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                  </g>
                  <g>
                    <path
                      d="M1323.76,1145.33c.77,0,3.244-1.1,3.244-4.675v-28.211c0-3.079-2.859-3.135-3.244-3.135v-.55s5.334-2.585,5.939-2.585c1.319,0,1.319.99,1.319,2.585v31.896c0,3.575,2.475,4.675,3.245,4.675v.88h-10.504v-.88Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1359.177,1140.656c0,3.575,2.53,4.675,3.245,4.675v.88h-7.204v-2.859c-1.43.88-5.06,2.805-9.349,2.805-5.225,0-9.789-2.255-9.789-5.664,0-3.96,4.675-5.06,8.744-6.435,3.354-1.045,7.204-2.364,10.118-5.334-.55-3.63-2.255-6.38-6.929-6.38-2.97,0-5.664,2.146-5.664,5.994,0,1.32-1.045,1.98-2.09,1.98-.99,0-2.035-.66-2.035-1.98,0-3.079,4.234-6.874,10.174-6.874,6.214,0,10.778,3.41,10.778,11.934v7.259ZM1355.218,1133.397c0-1.21,0-2.42-.11-3.574-3.024,2.805-6.709,4.015-10.009,5.114-4.069,1.319-5.059,3.3-5.059,5.555,0,2.749,2.804,4.563,6.544,4.563,4.564,0,7.863-2.199,8.634-2.749v-8.909Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1382.773,1143.351c-.056,0-2.695,2.859-7.425,2.859-4.674,0-9.679-2.805-9.679-9.184v-9.129c0-3.244-2.859-3.3-3.244-3.3v-.495s5.334-2.64,5.939-2.64c1.265,0,1.265,1.045,1.265,2.64v13.804c0,4.949,2.199,7.424,6.214,7.424,4.345,0,6.874-3.079,6.93-3.135v-14.298c0-3.244-2.86-3.3-3.245-3.3v-.495s5.335-2.64,5.939-2.64c1.265,0,1.265,1.045,1.265,2.64v15.673c0,3.245,2.859,3.3,3.245,3.3v.495s-5.335,2.64-5.939,2.64c-1.265,0-1.265-1.045-1.265-2.64v-.22Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1399.934,1124.324c.055,0,2.694-2.86,7.424-2.86,4.675,0,9.679,2.805,9.679,9.185v10.008c0,3.575,2.475,4.675,3.245,4.675v.88h-10.449v-.88c.715,0,3.245-1.1,3.245-4.675v-10.888c0-4.95-2.2-7.425-6.215-7.425-4.344,0-6.874,3.08-6.929,3.135v15.178c0,3.575,2.475,4.675,3.244,4.675v.88h-10.448v-.88c.77,0,3.244-1.1,3.244-4.675v-12.758c0-3.244-2.859-3.3-3.244-3.3v-.495s5.334-2.64,5.939-2.64c1.265,0,1.265,1.045,1.265,2.64v.221Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1470.054,1140.711c0,3.52,2.529,4.619,3.299,4.619v.88h-10.503v-.88c.77,0,3.244-1.1,3.244-4.619v-10.943c0-4.95-2.145-7.369-6.214-7.369-4.345,0-6.874,3.079-6.93,3.079v15.233c0,3.52,2.475,4.619,3.245,4.619v.88h-10.449v-.88c.771,0,3.245-1.1,3.245-4.619v-28.156c0-2.97-2.53-3.08-3.135-3.08h-.11c-4.234,0-6.653,1.65-6.653,4.455,0,3.024,4.619,10.998,4.619,14.407,0,1.375-.99,2.035-2.035,2.035s-2.09-.715-2.09-2.035c0-3.849-2.364-5.938-5.334-5.938-4.619,0-8.193,4.784-8.193,11.438,0,6.324,3.849,11.163,8.578,11.163s7.534-3.52,7.534-3.52l.715.44c-2.09,2.64-5.774,4.289-9.294,4.289-6.324,0-11.493-5.499-11.493-12.373,0-7.314,5.169-12.318,11.714-12.318,3.52,0,6.324,1.265,8.028,2.97-1.154-3.025-3.629-8.249-3.629-10.559,0-3.354,2.804-5.335,7.533-5.335,0,0,5.335-2.42,5.939-2.42,1.265,0,1.265.99,1.265,2.585v15.618c.056-.055,2.695-2.859,7.424-2.859s9.68,2.805,9.68,9.129v10.063Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1479.189,1132.957v.88c0,6.269,3.85,11.108,8.523,11.108,4.729,0,7.534-3.52,7.534-3.52l.715.495c-2.09,2.584-5.774,4.289-9.238,4.289-6.38,0-11.549-5.555-11.549-12.373s5.169-12.374,11.549-12.374c6.049,0,11.053,5.06,11.438,11.494h-18.973ZM1479.243,1132.077h14.903c-.55-5.444-3.685-9.734-7.424-9.734-3.795,0-6.93,4.234-7.479,9.734Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1519.831,1143.295s-2.53,2.915-7.425,2.915c-6.379,0-11.493-5.555-11.493-12.373s5.114-12.374,11.493-12.374c4.62,0,7.425,2.915,7.425,2.915v-11.823c0-3.189-2.86-3.245-3.245-3.245v-.55s5.334-2.585,5.939-2.585c1.265,0,1.265.99,1.265,2.585v31.896c0,3.575,2.475,4.675,3.244,4.675v.88h-7.203v-2.915ZM1519.831,1141.976v-16.278s-2.75-3.354-6.984-3.354c-5.225,0-7.975,5.114-7.975,11.438,0,6.27,2.805,11.549,8.029,11.549,4.234,0,6.93-3.354,6.93-3.354Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1544.803,1132.957v.88c0,6.269,3.85,11.108,8.523,11.108,4.729,0,7.534-3.52,7.534-3.52l.715.495c-2.09,2.584-5.774,4.289-9.238,4.289-6.38,0-11.549-5.555-11.549-12.373s5.169-12.374,11.549-12.374c6.049,0,11.053,5.06,11.438,11.494h-18.973ZM1544.858,1132.077h14.903c-.55-5.444-3.685-9.734-7.424-9.734-3.795,0-6.93,4.234-7.479,9.734Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1598.258,1121.463c4.729,0,9.679,2.805,9.679,9.185v10.008c0,3.575,2.475,4.675,3.245,4.675v.88h-10.449v-.88c.771,0,3.245-1.1,3.245-4.675v-10.888c0-4.95-2.145-7.425-6.215-7.425-3.794,0-6.488,2.53-7.809,4.07.55,1.209.88,2.584.88,4.234v10.008c0,3.575,2.53,4.675,3.245,4.675v.88h-10.449v-.88c.771,0,3.245-1.1,3.245-4.675v-10.888c0-4.95-2.146-7.425-6.159-7.425-4.399,0-6.93,3.08-6.984,3.135v15.178c0,3.575,2.529,4.675,3.3,4.675v.88h-10.504v-.88c.77,0,3.244-1.1,3.244-4.675v-12.868c0-3.134-2.859-3.189-3.244-3.189v-.495s5.334-2.64,5.939-2.64c1.265,0,1.265,1.045,1.265,2.64v.221c.055,0,2.694-2.86,7.424-2.86,3.245,0,6.544,1.32,8.359,4.18,1.1-1.319,4.014-4.18,8.743-4.18Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1638.189,1133.837c0,6.818-5.169,12.373-11.493,12.373-4.784,0-6.269-2.42-8.304-2.42-1.54,0-3.08,2.42-3.08,2.42v-33.655c0-3.189-2.859-3.245-3.244-3.245v-.55s5.334-2.585,5.939-2.585c1.265,0,1.265.99,1.265,2.585v15.618s2.859-2.915,7.424-2.915c6.324,0,11.493,5.555,11.493,12.374ZM1634.23,1133.782c0-6.324-2.805-11.438-8.029-11.438-4.179,0-6.929,3.354-6.929,3.354v16.278s2.64,3.354,6.929,3.354c5.17,0,8.029-5.279,8.029-11.549Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1640.007,1145.33c.77,0,3.244-1.1,3.244-4.675v-28.211c0-3.079-2.859-3.135-3.244-3.135v-.55s5.334-2.585,5.939-2.585c1.319,0,1.319.99,1.319,2.585v31.896c0,3.575,2.475,4.675,3.245,4.675v.88h-10.504v-.88Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1656.342,1132.957v.88c0,6.269,3.85,11.108,8.523,11.108,4.729,0,7.534-3.52,7.534-3.52l.715.495c-2.09,2.584-5.774,4.289-9.238,4.289-6.38,0-11.549-5.555-11.549-12.373s5.169-12.374,11.549-12.374c6.049,0,11.053,5.06,11.438,11.494h-18.973ZM1656.397,1132.077h14.903c-.55-5.444-3.685-9.734-7.424-9.734-3.795,0-6.93,4.234-7.479,9.734Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1709.797,1121.463c4.729,0,9.679,2.805,9.679,9.185v10.008c0,3.575,2.475,4.675,3.245,4.675v.88h-10.449v-.88c.771,0,3.245-1.1,3.245-4.675v-10.888c0-4.95-2.145-7.425-6.215-7.425-3.794,0-6.488,2.53-7.809,4.07.55,1.209.88,2.584.88,4.234v10.008c0,3.575,2.53,4.675,3.245,4.675v.88h-10.449v-.88c.771,0,3.245-1.1,3.245-4.675v-10.888c0-4.95-2.146-7.425-6.159-7.425-4.399,0-6.93,3.08-6.984,3.135v15.178c0,3.575,2.529,4.675,3.3,4.675v.88h-10.504v-.88c.77,0,3.244-1.1,3.244-4.675v-12.868c0-3.134-2.859-3.189-3.244-3.189v-.495s5.334-2.64,5.939-2.64c1.265,0,1.265,1.045,1.265,2.64v.221c.055,0,2.694-2.86,7.424-2.86,3.245,0,6.544,1.32,8.359,4.18,1.1-1.319,4.014-4.18,8.743-4.18Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1747.639,1140.656c0,3.575,2.53,4.675,3.245,4.675v.88h-7.204v-2.859c-1.43.88-5.06,2.805-9.349,2.805-5.225,0-9.789-2.255-9.789-5.664,0-3.96,4.675-5.06,8.744-6.435,3.354-1.045,7.204-2.364,10.118-5.334-.55-3.63-2.255-6.38-6.929-6.38-2.97,0-5.664,2.146-5.664,5.994,0,1.32-1.045,1.98-2.09,1.98-.99,0-2.035-.66-2.035-1.98,0-3.079,4.234-6.874,10.174-6.874,6.214,0,10.778,3.41,10.778,11.934v7.259ZM1743.68,1133.397c0-1.21,0-2.42-.11-3.574-3.024,2.805-6.709,4.015-10.009,5.114-4.069,1.319-5.06,3.3-5.06,5.555,0,2.749,2.805,4.563,6.545,4.563,4.564,0,7.863-2.199,8.634-2.749v-8.909Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1754.186,1139.447v-17.104h-3.244v-.88h3.244v-3.52c0-3.189-2.859-3.244-3.244-3.244v-.55s5.334-2.585,5.939-2.585c1.265,0,1.265.99,1.265,2.585v7.313h7.259v.88h-7.259v17.763c0,3.63,2.199,5.225,4.399,5.225,2.805,0,4.289-1.76,4.289-1.76l.771.44s-1.76,2.199-6.05,2.199-7.369-2.364-7.369-6.764Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1769.421,1145.33c.77,0,3.244-1.1,3.244-4.675v-12.868c0-3.134-2.859-3.189-3.244-3.189v-.495s5.334-2.64,5.939-2.64c1.319,0,1.319,1.045,1.319,2.64v16.553c0,3.575,2.475,4.675,3.245,4.675v.88h-10.504v-.88ZM1774.646,1116.789c-1.32,0-2.42-1.1-2.42-2.42,0-1.375,1.1-2.475,2.42-2.475,1.375,0,2.475,1.1,2.475,2.475,0,1.32-1.1,2.42-2.475,2.42Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                    <path
                      d="M1793.51,1121.463c5.939,0,9.844,3.795,9.844,6.874,0,1.32-1.045,1.98-2.035,1.98-1.045,0-2.09-.66-2.09-1.98,0-3.849-2.364-5.994-5.334-5.994-4.619,0-8.139,4.785-8.139,11.494,0,6.269,3.85,11.108,8.523,11.108,4.729,0,7.534-3.52,7.534-3.52l.77.495c-2.145,2.584-5.829,4.289-9.293,4.289-6.38,0-11.549-5.555-11.549-12.373,0-7.314,5.225-12.374,11.769-12.374Z"
                      style={{
                        fill: "#efefef",
                        stroke: "#efefef",
                        strokeMiterlimit: "10",
                        strokeWidth: "2px",
                      }}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="t" data-name="colortags">
              <g>
                <rect
                  x="1787.628"
                  y="4.473"
                  width="58.492"
                  height="99.436"
                  style={{ fill: "#c32376" }}
                />
                <rect
                  x="1856.209"
                  y="4.953"
                  width="58.492"
                  height="99.436"
                  style={{ fill: "#df46b7" }}
                />
              </g>
              <rect
                x="1288.658"
                y="269.326"
                width="58.492"
                height="99.436"
                style={{ fill: "#c32376" }}
              />
              <rect
                x="1572.154"
                y="268.84"
                width="58.492"
                height="99.436"
                style={{ fill: "#ffc6d3" }}
              />
              <rect
                x="1857.794"
                y="269.844"
                width="58.492"
                height="99.436"
                style={{ fill: "#c32376" }}
              />
              <rect
                x="1436.919"
                y="637.101"
                width="58.492"
                height="99.436"
                style={{ fill: "#ffc6d3" }}
              />
              <rect
                x="1138.401"
                y="1049.152"
                width="58.492"
                height="99.436"
                style={{ fill: "#ffc6d3" }}
              />
              <rect
                x="1717.95"
                y="1244.961"
                width="58.492"
                height="99.436"
                style={{ fill: "#ffc6d3" }}
              />
              <rect
                x="1857.918"
                y="1051.902"
                width="58.492"
                height="99.436"
                style={{ fill: "#ffc6d3" }}
              />
              <rect
                x="1069.728"
                y="1049.098"
                width="58.492"
                height="99.436"
                style={{ fill: "#df46b7" }}
              />
              <rect
                x="762.253"
                y="635.227"
                width="58.492"
                height="99.436"
                style={{ fill: "#df46b7" }}
              />
              <rect
                x="693.672"
                y="634.747"
                width="58.492"
                height="99.436"
                style={{ fill: "#c32376" }}
              />
              <rect
                x="1789.584"
                y="1245.645"
                width="58.492"
                height="99.436"
                style={{ fill: "#c32376" }}
              />
              <rect
                x="1860.534"
                y="1245.645"
                width="58.492"
                height="99.436"
                style={{ fill: "#df46b7" }}
              />
              <rect
                x="1858.028"
                y="637.84"
                width="58.492"
                height="99.436"
                style={{ fill: "#df46b7" }}
              />
              <rect
                x="1574.336"
                y="636.902"
                width="58.492"
                height="99.436"
                style={{ fill: "#df46b7" }}
              />
              <rect
                x="1505.755"
                y="636.423"
                width="58.492"
                height="99.436"
                style={{ fill: "#c32376" }}
              />
              <rect
                x="1572.369"
                y="829.046"
                width="58.492"
                height="99.436"
                style={{ fill: "#df46b7" }}
              />
              <rect
                x="1503.788"
                y="828.566"
                width="58.492"
                height="99.436"
                style={{ fill: "#c32376" }}
              />
            </g>
          </svg>
        </div>
      )}
    </div>
  );
}

export default CareerVisualizerBubbles;

const blogList = [
  {
    title: "noodle cooking assembly line design",
    mainImg: "",
    imgDescription: "",
    paragraphs: [
        "in dev - more coming soon!",
  ],
  },
  {
    title: "closing our restaurants!",
    mainImg: "",
    imgDescription: "",
    paragraphs: [
        "in dev - more coming soon!",
  ],
  },
  {
    title: "my favorite INF designs",
    mainImg: "",
    imgDescription: "",
    paragraphs: [
        "in dev - more coming soon!",
  ],
  },
  {
    title: "style guidelines for css code",
    mainImg: "https://live.staticflickr.com/65535/54279332186_7225d4aa1b_o.png",
    imgDescription: "plata, the greek goddess of css. image form generated with adobe firefly image 3, edited by me",
    paragraphs: [
        "in dev - more coming soon!",
  ],
  },
  {
    title: "getting started with typography",
    mainImg: "https://live.staticflickr.com/65535/54279698528_83e3bb7a10_o.png",
    imgDescription: "three different fonts, upper case and lower case",
    paragraphs: [
        "in dev - more coming soon!",
  ],
  },
];

export default blogList;

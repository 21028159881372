import './App.css';
import React, { Component } from "react";
// import Footer from './pages/Footer.js'
import PrivacyFooter from './pages/PrivacyFooter.js'
import Header from './pages/Header.js'
import Projects from './pages/Projects.js'
import blurMobile from './assets/blurMobile.png'
import blurDesktop from './assets/blurDesktop.png'
import SplashScreen from './assets/components/SplashScreen.js'

class App extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
      loading: true,
    };
  }

  async componentDidMount() {
    setTimeout(() => {
        this.setState({ loading : false })
      }, 6000);
}

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const width = this.state.width;
    const isMobile = width <= 650;
    const mobileClass = isMobile ? "AppMobile" : "AppDesktop";
    const blur = isMobile ? blurMobile : blurDesktop;
    const blurClass = isMobile ? "blurImageMobile" : "blurImageDesktop";
    return (
        <div className={mobileClass}>
          {this.state.loading &&
                    <SplashScreen/>
          }
          <img className={blurClass} src={blur} alt="hot pink gradient"></img>
          <Header isMobile = {isMobile}/>
          <Projects isMobile= {isMobile}/>
          <PrivacyFooter />
          {/* <Footer isMobile= {isMobile}/> */}
        </div>
      );
  }
}

export default App;
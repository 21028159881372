import "./ActualPages.css";
import { Link } from "react-router-dom";
import CareerVisualizerBubbles from './components/CareerVisualizerBubbles.js'
import React from "react";


function ProjectVisualizer(props) {

  return (
    <div className="projectVisualizer">
        <Link className="link" to="/" >
            <p className="backButton"> back to home </p>
        </Link>
        <p className="headerText"> career progression  </p>
        <p className="headerSubText"> the traditional career path is linear - one job or project at a time, focusing and progressing in one specialty. this isn't the only option, and you're free to create your own path. mine looks a bit like a puzzle, with overlapping elements and leaps from corporate to startup. each item below is clickable for more details!</p>
                    <CareerVisualizerBubbles />
            </div>
  );
}

export default ProjectVisualizer;
